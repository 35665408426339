import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { ConfigContext } from '../Config'
import ListGroupPlaceholder from '../Package/ListGroupPlaceholder'
import Pagination from '../Pagination'
import { MaintainerRecord, Page } from '../types'

const MaintainerComponent: FunctionComponent = () => {
  const configCtx = useContext(ConfigContext)
  const [loading, setLoading] = useState(true)
  const [packages, setPackages] = useState<Page<string>>()

  const [params] = useSearchParams()
  const name = params.get('name')
  const page = params.get('page')

  const fetchPackages = async (
    name: string | null,
    page: string | null,
    api: string
  ) => {
    const search = new URLSearchParams({
      maintainer: name ?? '',
      page: page ?? '',
    })
    const data = await fetch(`${api}/api/packages?${search.toString()}`)
    const result = await data.json()
    setPackages(result)
    setLoading(false)
  }

  useEffect(() => {
    fetchPackages(name, page, configCtx.cache)
  }, [name, page, configCtx.cache])

  return (
    <React.Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: '/maintainers', text: 'Maintainers' },
          { href: null, text: name ?? '' },
        ]}
      />

      <div className="px-2 px-md-4 py-4">
        <Maintainer name={name} />

        <div className="card mt-3">
          <div className="card-header">List of packages</div>
          <ul className="list-group list-group-flush placeholder-glow">
            <li
              className="list-group-item d-flex align-items-center"
              style={{
                borderBottom: '2px solid #dee2e6',
              }}
            >
              <strong className="text-truncate">Name</strong>
            </li>

            {loading ? (
              <ListGroupPlaceholder
                cols={[3, 2, 4, 3, 2, 2, 4, 2, 1, 3]}
                includeUl={false}
              />
            ) : (
              packages?.content.map((p, index) => {
                return (
                  <li
                    key={p}
                    className="list-group-item d-flex align-items-center"
                  >
                    <small className="text-muted text-small">
                      #{packages.number * packages.size + index + 1}
                    </small>
                    <Link
                      to={`/packages?name=${p}`}
                      className="text-decoration-none me-auto ms-4"
                    >
                      {p}
                    </Link>
                  </li>
                )
              })
            )}
          </ul>
        </div>
        <Pagination loading={loading} page={packages} />
      </div>
    </React.Fragment>
  )
}

interface Props {
  name: string | null
}

function Maintainer(props: Props) {
  const configCtx = useContext(ConfigContext)
  const [loading, setLoading] = useState(true)
  const [maintainer, setMaintainer] = useState<MaintainerRecord>()

  const fetchMaintainer = async (name: string | null, api: string) => {
    const data = await fetch(`${api}/api/maintainers?name=${name}`)
    const result = await data.json()
    setMaintainer(result)
    setLoading(false)
  }

  useEffect(() => {
    fetchMaintainer(props.name, configCtx.cache)
  }, [props.name, configCtx.cache])

  return (
    <div className="card">
      <div className="card-header">Maintainer information</div>
      <div className="card-body placeholder-glow">
        {loading ? (
          <React.Fragment>
            <p className="card-text">
              <span className="placeholder col-1"></span>
            </p>
            <p className="card-text">
              <span className="placeholder col-1"></span>
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="card-text">
              {maintainer?.name || <span>&nbsp;</span>}
            </p>
            <p className="card-text">
              {maintainer?.email || <span>&nbsp;</span>}
            </p>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default MaintainerComponent
