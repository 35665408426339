import {
  Box,
  CardText,
  Diagram3,
  FileEarmarkText,
  HandThumbsUp,
  List,
  PersonBadge,
  Shield,
} from '../Icons'

const Numbers: React.FunctionComponent = function Examples() {
  return (
    <div className="container px-4 py-5">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
        <div className="col d-flex align-items-start">
          <Box
            width="1.75rem"
            height="1.75rem"
            className="text-muted flex-shrink-0 me-3"
          />
          <div>
            <h4 className="fw-bold mb-0">Packages</h4>
            <p>
              1,803,534 packages analyzed. Self-healing algorithms ensure latest
              information.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <List
            width="1.75rem"
            height="1.75rem"
            className="text-muted flex-shrink-0 me-3"
          />
          <div>
            <h4 className="fw-bold mb-0">Versions</h4>
            <p>
              21,394,371 versions in our database. Stay up-to-date with new
              releases.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <Diagram3
            width="1.75rem"
            height="1.75rem"
            className="text-muted flex-shrink-0 me-3"
          />
          <div>
            <h4 className="fw-bold mb-0">Dependencies</h4>
            <p>
              127,177,889 dependencies crawled. A chain is only as strong as its
              weakest link.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <PersonBadge
            width="1.75rem"
            height="1.75rem"
            className="text-muted flex-shrink-0 me-3"
          />

          <div>
            <h4 className="fw-bold mb-0">Maintainers</h4>
            <p>
              584,077 maintainers indexed. Use allowlists and denylists for
              filtering maintainers.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <HandThumbsUp
            width="1.75rem"
            height="1.75rem"
            className="text-muted flex-shrink-0 me-3"
          />
          <div>
            <h4 className="fw-bold mb-0">Criticality Score</h4>
            <p>
              77,271 scores calculated and counting. The criticality score
              defines the influence and importance of a project.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <Shield
            width="1.75rem"
            height="1.75rem"
            className="text-muted flex-shrink-0 me-3"
          />
          <div>
            <h4 className="fw-bold mb-0">Vulnerabilities</h4>
            <p>
              6,585 vulnerabilities inspected. Keep an eye on malicious
              packages.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <FileEarmarkText
            width="1.75rem"
            height="1.75rem"
            className="text-muted flex-shrink-0 me-3"
          />
          <div>
            <h4 className="fw-bold mb-0">Licenses</h4>
            <p>
              458 licenses studied. Build software according to Law and
              Compliance.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <CardText
            width="1.75rem"
            height="1.75rem"
            className="text-muted flex-shrink-0 me-3"
          />
          <div>
            <h4 className="fw-bold mb-0">Keywords</h4>
            <p>
              414,743 keywords inspected. Find relationship between packages.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Numbers
