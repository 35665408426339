import { Outlet, Route, Routes } from 'react-router-dom'
import HomeComponent from './Home/Home'
import LicenseComponent from './Licenses/LicenseComponent'
import LicenseListComponent from './Licenses/LicenseListComponent'
import Roadmap from './Footer/Roadmap'
import Footer from './Footer/Footer'
import Navigation from './Navigation'
import PackageWrapper from './Package/PackageWrapper'
import Landing from './Landing/Landing'
import MaintainerWrapper from './Maintainer/MaintainerWrapper'
import KeywordWrapper from './Keyword/KeywordWrapper'
import Deps from './Deps'
import PackageLockJson from './PackageLockJson'
import CriticalityScore from './Footer/CriticalityScoreExplanation'
import SecurityScorecards from './Footer/SecurityScorecards'
import CvssCalculator from './CvssCalculator'
import CweListComponent from './CWE/CweListComponent'
import CweComponent from './CWE/CweComponent'
import Privacy from './Privacy'
import Terms from './Terms'

const Layout: React.FunctionComponent = function Layout() {
  return (
    <div className="sbomx-app">
      <Navigation />
      <main className="sbomx-main">
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

const Router: React.FunctionComponent = function Router() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route element={<Layout />}>
        <Route path="demo/*" element={<HomeComponent />} />
        <Route path="licenses" element={<LicenseListComponent />} />
        <Route path="deps/package.json/:hash" element={<Deps />} />
        <Route
          path="deps/package-lock.json/:hash"
          element={<PackageLockJson type={'package-lock.json'} />}
        />
        <Route
          path="deps/yarn.lock/:hash"
          element={<PackageLockJson type={'yarn.lock'} />}
        />
        <Route path="maintainers" element={<MaintainerWrapper />} />
        <Route path="cwes" element={<CweListComponent />} />
        <Route path="cwes/:cweId" element={<CweComponent />} />
        <Route path="licenses/:licenseId" element={<LicenseComponent />} />
        <Route path="packages/*" element={<PackageWrapper />} />
        <Route path="criticality-score" element={<CriticalityScore />} />
        <Route path="security-scorecards" element={<SecurityScorecards />} />
        <Route path="cvss-calculator" element={<CvssCalculator />} />
        <Route path="roadmap" element={<Roadmap />} />
        <Route path="keywords" element={<KeywordWrapper />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
      </Route>
    </Routes>
  )
}

export default Router
