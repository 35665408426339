import classNames from 'classnames'
import { useCombobox } from 'downshift'
import React from 'react'
import {
  Fragment,
  useContext,
  useEffect,
  useState,
  MouseEvent,
  useRef,
} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Arrow from '../Arrow'
import Breadcrumb from '../Breadcrumb'
import { ConfigContext } from '../Config'
import Order from '../Order'
import ListGroupPlaceholder from '../Package/ListGroupPlaceholder'
import Pagination from '../Pagination'
import { Page } from '../types'

interface Cwe {
  cweId: number
  name: string
  description: string
}

const formatter = new Intl.NumberFormat()

const CweListComponent = () => {
  const configCtx = useContext(ConfigContext)
  const location = useLocation()
  const navigate = useNavigate()

  const params = new URLSearchParams(location.search)
  const sort = params.get('sort')
  let order = Order.from(sort)

  const [cwes, setCwes] = useState<Page<Cwe>>()
  const [loading, setLoading] = useState(true)

  const retrieve = async (search: string, api: string) => {
    const response = await fetch(`${api}/api/cwes${search}`)
    const data = await response.json()
    setCwes(data)
    setLoading(false)
  }

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget
    if (order && order.property === name) {
      // click on the same column
      order.reverse()
    } else {
      // click on another column
      order = Order.by(name)
    }
    params.set('sort', order.toString())
    navigate({
      search: decodeURIComponent(params.toString()),
    })
  }

  useEffect(() => {
    retrieve(location.search, configCtx.cache)
  }, [location.search, configCtx.cache])

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'CWEs', title: 'Common Weakness Enumerations' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <AutocompleteComponent />
        <div className="card mt-3">
          <div className="card-header d-flex align-items-center">
            Common Weakness Enumerations
            {cwes ? (
              <span className="badge bg-secondary ms-2">
                {formatter.format(cwes.totalElements)}
              </span>
            ) : null}
          </div>

          <ul className="list-group list-group-flush placeholder-glow">
            <li
              className="list-group-item d-flex align-items-center"
              style={{
                borderBottom: '2px solid #dee2e6',
              }}
            >
              <div className="d-none d-sm-inline-block" style={{ width: 75 }} />
              <div
                style={{ width: 100 }}
                className="d-flex align-items-center justify-content-end"
              >
                <strong>CWE ID</strong>
                <button
                  className="btn btn-sm btn-link"
                  onClick={onClick}
                  name="cweId"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Order by CWE ID"
                >
                  <Arrow name="cweId" order={order} />
                </button>
              </div>

              <div className="ms-4 d-flex align-items-center">
                <strong>Name</strong>
                <button
                  className="btn btn-sm btn-link"
                  onClick={onClick}
                  name="name"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Order by CWE name"
                >
                  <Arrow name="name" order={order} />
                </button>
              </div>
            </li>

            {loading ? (
              <ListGroupPlaceholder
                cols={[
                  6, 5, 6, 4, 5, 6, 3, 2, 6, 7, 4, 6, 5, 4, 5, 7, 6, 5, 4, 3,
                ]}
                includeUl={false}
              />
            ) : (
              cwes?.content.map((cwe, index) => {
                return (
                  <li key={cwe.cweId} className="list-group-item text-truncate">
                    <small
                      className="text-muted text-small d-none d-sm-inline-block"
                      style={{ width: 75 }}
                    >
                      #{cwes.number * cwes.size + index + 1}
                    </small>
                    <Link
                      to={`/cwes/${cwe.cweId}`}
                      className="text-decoration-none text-end d-inline-block"
                      style={{
                        width: 66,
                      }}
                    >
                      {cwe.cweId}
                    </Link>
                    <span style={{ marginLeft: 58 }}>{cwe.name}</span>
                  </li>
                )
              })
            )}
          </ul>
        </div>

        <Pagination loading={loading} page={cwes} />
      </div>
    </Fragment>
  )
}

export default CweListComponent

// from bootstrap css
// .dropdown-item:hover, .dropdown-item:focus {
//   color: #1e2125;
//   background-color: #e9ecef;
// }
const styles = {
  color: '#1e2125',
  backgroundColor: '#e9ecef',
}

const AutocompleteComponent = () => {
  const configCtx = useContext(ConfigContext)

  const [inputItems, setInputItems] = useState<Cwe[]>([])
  const navigate = useNavigate()
  const ref = useRef<HTMLInputElement>(null)

  const abortController = useRef<AbortController>()

  const combobox = useCombobox({
    items: inputItems,
    onSelectedItemChange: (changes) => {
      navigate('/cwes/' + changes.selectedItem?.cweId)
    },
    onInputValueChange: async (changes) => {
      // cancel current request if there is one
      abortController.current?.abort()

      switch (changes.type) {
        // search for package
        case useCombobox.stateChangeTypes.InputChange:
          if (changes.inputValue) {
            try {
              abortController.current = new AbortController()
              const data = await fetch(
                configCtx.cache + '/api/cwes?q=' + changes.inputValue,
                {
                  signal: abortController.current.signal,
                }
              )
              const list = await data.json()
              setInputItems(list)
            } catch (error) {
              console.log(error)
            }
          } else {
            // close menu when input is empty or on escape key
            if (changes.isOpen) {
              combobox.closeMenu()
            }
          }
          break

        default:
          break
      }
    },
  })

  return (
    <React.Fragment>
      <div {...combobox.getComboboxProps()}>
        <input
          type="search"
          {...combobox.getInputProps({
            ref,
          })}
          autoCapitalize="off"
          className="form-control"
          placeholder="Search for Common Weakness Enumeration"
        />
      </div>
      <ul
        className={classNames('dropdown-menu', {
          show: combobox.isOpen,
        })}
        {...combobox.getMenuProps()}
      >
        {inputItems.map((item, index) => {
          return (
            <li
              key={`${item}${index}`}
              {...combobox.getItemProps({ item, index })}
            >
              <button
                className="dropdown-item"
                type="button"
                style={combobox.highlightedIndex === index ? styles : {}}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.name.replace(
                      new RegExp(combobox.inputValue, 'gi'),
                      '<strong>$&</strong>'
                    ),
                  }}
                />
              </button>
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}
