import { useState, useEffect, useContext } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { ConfigContext } from '../Config'
import { Dependency } from '../types'
import ListGroupPlaceholder from './ListGroupPlaceholder'
import { useMapLocationToApi } from './utils'

const Dependencies: React.FunctionComponent = function Dependencies() {
  const configCtx = useContext(ConfigContext)
  const [loading, setLoading] = useState(true)
  const [dependencies, setDependencies] = useState<Dependency[]>([])

  const [searchParams] = useSearchParams()
  const name = searchParams.get('name')
  const version = searchParams.get('version')

  const query = useMapLocationToApi()

  const retrieve = async (query: string, api: string) => {
    const response = await fetch(`${api}/api/versions/dependencies?${query}`)
    const data = await response.json()
    setDependencies(data)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(query, configCtx.cache)
  }, [query, configCtx.cache])

  return (
    <div className="card mb-4 placeholder-glow">
      <div className="card-header">Dependencies</div>

      {loading ? (
        <ListGroupPlaceholder cols={[2, 3, 2, 1, 2]} includeUl={true} />
      ) : dependencies.length === 0 ? (
        <div className="card-body">
          <code>
            {name}@{version}
          </code>{' '}
          does not have any dependencies.
        </div>
      ) : (
        <ul className="list-group list-group-flush">
          {dependencies.map((d) => {
            return (
              <li key={d.name} className="list-group-item">
                <Link
                  className="text-decoration-none"
                  to={`/packages?name=${d.name}&version=${d.resolvedVersion}`}
                >
                  {d.name}@{d.resolvedVersion}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Dependencies
