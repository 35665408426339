import { ChangeEvent, Fragment, ReactNode, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Breadcrumb from './Breadcrumb'
import CVSS, { base } from './cvss/CVSS'
import styles from './cvss.module.scss'
import { Clipboard, Clock, HddNetwork, LayersHalf } from './Icons'
import useTooltip from './useTooltip'
import { temporal } from './cvss/temporal'
import { environmental } from './cvss/environmental'
import { useScrollToTop } from './Package/utils'

const CvssCalculator: React.FunctionComponent = () => {
  const [raw, setRaw] = useState('')

  const [params, setParams] = useSearchParams()
  const vector = params.get('vector')
  const cvss = CVSS.fromString(vector)

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    cvss.update(name, value)
    setParams({
      vector: cvss.toString(),
    })
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRaw(event.target.value)

    const { value } = event.target
    const parts = value.split('/')
    parts.forEach((p) => {
      const [key, value] = p.split(':')
      cvss.update(key, value)
      setParams({
        vector: cvss.toString(),
      })
    })
  }

  const copy = () => {
    navigator.clipboard.writeText(cvss.toString())
  }

  const clear = () => {
    setParams('')
  }

  useTooltip()

  // useEffect(() => {
  //   const callback = (entries: IntersectionObserverEntry[]) => {
  //     entries.forEach((e) => {
  //       e.target.classList.toggle('sticky', !e.isIntersecting)
  //     })
  //   }

  //   const observer = new IntersectionObserver(callback, {
  //     rootMargin: '-1px 0px 0px 0px',
  //     threshold: 1,
  //   })

  //   if (ref.current) {
  //     observer.observe(ref.current)
  //   }

  //   return () => {
  //     observer.disconnect()
  //   }
  // }, [])

  useScrollToTop()

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'CVSS Calculator' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <div className="card mb-4">
          <div className="card-header">CVSS</div>
          <div className="card-body">
            <div className="mb-3 row d-none d-sm-flex">
              <label htmlFor="raw" className="col-sm-2 col-form-label">
                Vector string
              </label>
              <div className="col-sm-10">
                <input
                  id="raw"
                  type="text"
                  className="form-control"
                  value={raw}
                  onChange={onInputChange}
                  placeholder="Enter vector string manually"
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label
                htmlFor="result"
                className="col-sm-2 col-form-label d-none d-sm-flex"
              >
                Result
              </label>
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    id="result"
                    value={cvss.toString()}
                  />
                  <button
                    className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={copy}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Copy to clipboard"
                  >
                    <Clipboard />
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 offset-sm-2">
                <button
                  className="btn btn-sm btn-outline-success"
                  type="button"
                  onClick={clear}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <BaseScore cvss={cvss} onChange={onChange} />
        <Temporal cvss={cvss} onChange={onChange} />
        <Environmental cvss={cvss} onChange={onChange} />
      </div>
    </Fragment>
  )
}

const Environmental: React.FunctionComponent<BaseScoreProps> = (
  props: BaseScoreProps
) => {
  return (
    <div className="card">
      <div className="card-header d-flex align-items-center">
        <HddNetwork />
        <span className="ms-2">Environmental Metrics</span>
        <span className="ms-auto d-none d-sm-inline">Score:</span>
        <span className="ms-auto ms-sm-1">
          {props.cvss.calcEnvironmental()}
        </span>
        <span className="ms-2 d-none d-sm-inline">Rating:</span>
        <span className="badge bg-warning text-dark ms-1">
          {props.cvss.environmentalSeverity()}
        </span>
      </div>
      <div className="card-body">
        <div className={styles.environmetal}>
          {Object.entries(environmental).map(([letter, value]) => {
            return (
              <div
                key={letter}
                className="d-flex d-sm-block align-items-center"
              >
                <p className="mb-2 d-none d-sm-block">
                  {value.text} ({letter})
                </p>
                <p
                  className="mb-0 d-sm-none"
                  style={{
                    width: '3rem',
                  }}
                >
                  {letter}
                </p>
                <div className="btn-group" role="group">
                  {Object.entries(value.metrics).map(([subletter, info]) => {
                    return (
                      <Radio
                        key={`${letter}:${subletter}`}
                        name={letter}
                        id={`${letter}:${subletter}`}
                        value={subletter}
                        onChange={props.onChange}
                        checked={props.cvss.checked(letter, subletter) ?? false}
                      >
                        <span className="d-sm-none">{subletter}</span>
                        <span className="d-none d-sm-inline">
                          {info.text} ({subletter})
                        </span>
                      </Radio>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const Temporal: React.FunctionComponent<BaseScoreProps> = (
  props: BaseScoreProps
) => {
  return (
    <div className="card mb-4">
      <div className="card-header d-flex align-items-center">
        <Clock />
        <span className="ms-2">Temporal Metrics</span>
        <span className="ms-auto d-none d-sm-inline">Score:</span>
        <span className="ms-auto ms-sm-1">{props.cvss.calcTemporal()}</span>
        <span className="ms-2 d-none d-sm-inline">Rating:</span>
        <span className="badge bg-warning text-dark ms-1">
          {props.cvss.temporalSeverity()}
        </span>
      </div>
      <div className="card-body">
        <div className={styles.temporal}>
          {Object.entries(temporal).map(([letter, value]) => {
            return (
              <div
                key={letter}
                className="d-flex d-sm-block align-items-center"
              >
                <p className="mb-2 d-none d-sm-block">
                  {value.text} ({letter})
                </p>
                <p
                  className="mb-0 d-sm-none"
                  style={{
                    width: '3rem',
                  }}
                >
                  {letter}
                </p>
                <div className="btn-group" role="group">
                  {Object.entries(value.metrics).map(([subletter, info]) => {
                    return (
                      <Radio
                        key={`${letter}:${subletter}`}
                        name={letter}
                        id={`${letter}:${subletter}`}
                        value={subletter}
                        onChange={props.onChange}
                        checked={props.cvss.checked(letter, subletter) ?? false}
                      >
                        <span className="d-sm-none">{subletter}</span>
                        <span className="d-none d-sm-inline">
                          {info.text} ({subletter})
                        </span>
                      </Radio>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

interface BaseScoreProps {
  cvss: CVSS
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const BaseScore: React.FunctionComponent<BaseScoreProps> = (
  props: BaseScoreProps
) => {
  return (
    <div className="card mb-4">
      <div className="card-header d-flex align-items-center">
        <LayersHalf />
        <span className="ms-2">Base Score</span>
        <span className="ms-auto d-none d-sm-inline">Score:</span>
        <span className="ms-auto ms-sm-1">{props.cvss.calcBase()}</span>
        <span className="ms-2 d-none d-sm-inline">Rating:</span>
        <span className="badge bg-warning text-dark ms-1">
          {props.cvss.baseSeverity()}
        </span>
      </div>
      <div className="card-body">
        <div className={styles.base}>
          {Object.entries(base).map(([key, value]) => {
            return (
              <div key={key} className="d-flex d-sm-block align-items-center">
                <p className="mb-2 d-none d-sm-block">
                  {value.text} ({key})
                </p>
                <p
                  className="mb-0 d-sm-none"
                  style={{
                    width: '3rem',
                  }}
                >
                  {key}
                </p>
                <div className="btn-group" role="group">
                  {Object.entries(value.metrics).map(([foo, bar]) => {
                    return (
                      <Radio
                        key={`${key}:${foo}`}
                        name={key}
                        id={`${key}:${foo}`}
                        value={foo}
                        onChange={props.onChange}
                        checked={props.cvss.checked(key, foo) ?? false}
                      >
                        <span className="d-sm-none">{foo}</span>
                        <span className="d-none d-sm-inline">
                          {bar.text} ({foo})
                        </span>
                      </Radio>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

interface RadioProps {
  id: string
  name: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  children: ReactNode
}

const Radio: React.FunctionComponent<RadioProps> = (props: RadioProps) => {
  return (
    <Fragment>
      <input
        type="radio"
        className="btn-check"
        name={props.name}
        id={props.id}
        value={props.value}
        autoComplete="off"
        checked={props.checked}
        onChange={props.onChange}
      />
      <label className="btn btn-sm btn-outline-primary" htmlFor={props.id}>
        {props.children}
      </label>
    </Fragment>
  )
}

export default CvssCalculator
