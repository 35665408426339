import React, {
  FunctionComponent,
  useEffect,
  useState,
  MouseEvent,
  useContext,
} from 'react'
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import { Box } from '../Icons'
import SortComponent from '../SortComponent'
import { isSortDirection, KeywordCount, Page, SortDirection } from '../types'
import Autocomplete from '../Autocomplete'
import ListGroupPlaceholder from '../Package/ListGroupPlaceholder'
import Breadcrumb from '../Breadcrumb'
import useTooltip from '../useTooltip'
import Pagination from '../Pagination'
import { ConfigContext } from '../Config'

const formatter = new Intl.NumberFormat()

const KeywordListComponent: FunctionComponent = () => {
  const configCtx = useContext(ConfigContext)
  const location = useLocation()
  const navigate = useNavigate()

  const [keywords, setKeywords] = useState<Page<KeywordCount>>()
  const [loading, setLoading] = useState(true)

  const [params] = useSearchParams()
  const sortParam = params.get('sort')
  let sortBy = ''
  let sortDirection = SortDirection.unsorted
  if (sortParam) {
    const parts = sortParam.split(',')
    sortBy = parts[0] === 'keyword' || parts[0] === 'count' ? parts[0] : sortBy
    sortDirection =
      parts[1] && isSortDirection(parts[1]) ? parts[1] : sortDirection
  }

  const oppositeSortDirection: SortDirection =
    sortDirection === SortDirection.asc ? SortDirection.desc : SortDirection.asc

  const fetchPackages = async (search: string, api: string) => {
    const data = await fetch(`${api}/api/keywords${search}`)
    const result = await data.json()
    setKeywords(result)
    setLoading(false)
  }

  useEffect(() => {
    fetchPackages(location.search, configCtx.cache)
  }, [location.search, configCtx.cache])

  useTooltip()

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget
    params.set('sort', `${name},${oppositeSortDirection}`)
    navigate({
      search: decodeURIComponent(params.toString()),
    })
  }

  return (
    <React.Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'Keywords' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <Autocomplete
          placeholder="Search for keyword"
          fetchUrl="/api/keywords?q="
          pushUrl="/keywords?value="
        />
        <div className="card mt-3">
          <div className="card-header d-flex align-items-center">
            List of keywords
            {keywords ? (
              <span className="badge bg-secondary ms-2">
                {formatter.format(keywords.totalElements)}
              </span>
            ) : null}
          </div>
          <ul className="list-group list-group-flush placeholder-glow">
            <li
              className="list-group-item d-flex align-items-center"
              style={{
                borderBottom: '2px solid #dee2e6',
              }}
            >
              <strong className="text-truncate">Keyword</strong>
              <button
                className="btn btn-sm btn-link ms-1"
                onClick={onClick}
                name="keyword"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Sort keywords alphabetically"
              >
                <div className="pe-none">
                  <SortComponent
                    sort={
                      sortBy === 'keyword' && sortDirection
                        ? sortDirection
                        : SortDirection.unsorted
                    }
                  />
                </div>
              </button>
              <strong className="ms-auto text-truncate">Packages</strong>
              <button
                className="btn btn-sm btn-link ms-1"
                onClick={onClick}
                name="count"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Sort by number of packages"
              >
                <div className="pe-none">
                  <SortComponent
                    sort={
                      sortBy === 'count' && sortDirection
                        ? sortDirection
                        : SortDirection.unsorted
                    }
                  />
                </div>
              </button>
            </li>
            {loading ? (
              <ListGroupPlaceholder
                cols={[
                  4, 3, 4, 3, 2, 2, 4, 2, 1, 3, 4, 3, 4, 3, 2, 2, 4, 2, 1, 3,
                ]}
                includeUl={false}
              />
            ) : (
              keywords?.content.map((d, index) => {
                return (
                  <li
                    key={d.keyword}
                    className="list-group-item d-flex align-items-center text-truncate"
                  >
                    <small className="text-muted text-small">
                      #{keywords.number * keywords.size + index + 1}
                    </small>

                    <Link
                      to={`/keywords?value=${d.keyword}`}
                      className="text-decoration-none me-auto ms-4 text-truncate"
                    >
                      {d.keyword}
                    </Link>
                    <div
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title={`We found ${formatter.format(
                        d.count
                      )} package(s) for keyword ${d.keyword}`}
                    >
                      <span className="me-2">{formatter.format(d.count)}</span>
                      <Box />
                    </div>
                  </li>
                )
              })
            )}
          </ul>
        </div>
        <Pagination loading={loading} page={keywords} />
      </div>
    </React.Fragment>
  )
}

export default KeywordListComponent
