import { Link } from 'react-router-dom'

interface Props {
  toggle: boolean
}

const Header: React.FunctionComponent<Props> = function Header(props: Props) {
  return (
    <nav className="sbomx-header navbar navbar-expand-md navbar-dark bg-dark">
      <div className="container-fluid">
        {props.toggle ? (
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
          >
            <span className="navbar-toggler-icon pe-none"></span>
          </button>
        ) : null}
        <Link to="/" className="navbar-brand ms-3">
          sbomx
        </Link>
      </div>
    </nav>
  )
}

export default Header
