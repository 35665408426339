import classNames from 'classnames'
import { useState, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { FileExcel } from '../Icons'
import styles from './excel.module.scss'
import qrcode from './qrcode.png'

// inspiration: https://codepen.io/oliviale/pen/rPjgmB

type Active = 'sbom' | 'lod'

const isActive = (s: string): s is Active => {
  return s === 'sbom' || s === 'lod'
}

// taken from screenshot when you open the sbomx.xlsx with mac numbers
const Excel = () => {
  const [active, setActive] = useState<Active>('sbom')

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget
    if (isActive(name)) {
      setActive(name)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.circles}>
          <Circle className={styles['circle-red']}>x</Circle>
          <Circle className={styles['circle-yellow']}>-</Circle>
          <Circle className={styles['circle-green']}>+</Circle>
        </div>
        <span className={styles.title}>
          <FileExcel className={styles.icon} />
          <span className="ms-1">sbomx</span>
        </span>
      </div>
      <div className={styles.ribbon}>
        <span>+</span>
        <Pill
          name="sbom"
          onClick={onClick}
          className={classNames({
            [styles.active]: active === 'sbom',
          })}
        >
          Software Bill of Materials
        </Pill>
        <Pill
          name="lod"
          onClick={onClick}
          className={classNames({
            [styles.active]: active === 'lod',
          })}
        >
          List of Dependencies
        </Pill>
      </div>
      <div className={styles.content}>
        {active === 'lod' ? <ListOfDeps /> : <Sbom />}
      </div>
    </div>
  )
}

interface CircleProps {
  className: string
  children: React.ReactNode
}

const Circle = (props: CircleProps) => {
  return (
    <div className={classNames(styles.circle, props.className)}>
      <span>{props.children}</span>
    </div>
  )
}

interface PillProps {
  children: React.ReactNode
  className?: string
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  name: string
}

const Pill = (props: PillProps) => {
  return (
    <button
      type="button"
      name={props.name}
      onClick={props.onClick}
      className={classNames(styles.pill, props.className)}
    >
      {props.children}
    </button>
  )
}

const Sbom = () => {
  return (
    <div className={styles.grid}>
      <div className={styles.cell}>SBOM</div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 2,
        }}
      >
        Software Bill of Materials
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 4,
        }}
      >
        Report Generator
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 4,
        }}
      >
        sbomx
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 5,
        }}
      >
        Report URL
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 5,
        }}
      >
        <Link
          to="/deps/package-lock.json/f4c3c5908a59cdfdc2d37155f9cb43052f142fe6cc61717bb48d3284c6312fdc"
          className="text-decoration-none"
        >
          https://sbomx.com
        </Link>
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 6,
        }}
      >
        Report Name
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 6,
        }}
      >
        bootstrap
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 7,
        }}
      >
        Report Date
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 7,
        }}
      >
        2022-03-24T09:47:21.899903665Z
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 8,
        }}
      >
        Report Filename
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 8,
        }}
      >
        sbomx.xlsx
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 9,
        }}
      >
        package-lock.json Hash
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 9,
        }}
      >
        f4c3c59
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 10,
        }}
      >
        Number of Dependencies
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 10,
        }}
      >
        828
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 11,
        }}
      >
        Package Manager
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 11,
        }}
      >
        npm
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 12,
        }}
      >
        Number of Licenses
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 12,
        }}
      >
        10
      </div>

      <div
        className={styles.cell}
        style={{
          gridColumn: 1,
          gridRow: 13,
        }}
      >
        Number of Maintainers
      </div>
      <div
        className={styles.cell}
        style={{
          gridColumn: 2,
          gridRow: 13,
        }}
      >
        414
      </div>

      <div className={styles.cell}></div>
      <div className={styles.cell}></div>
      <div className={styles.cell}></div>

      <div className={styles.cell}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>

      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>

      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>

      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>

      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>

      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>

      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div className={classNames(styles.cell, 'd-none', 'd-sm-block')}></div>
      <div
        className={classNames(
          styles.cell,
          styles['qr-code'],
          'd-none',
          'd-sm-block'
        )}
      >
        <img
          className="img-fluid"
          src={qrcode}
          alt="sbomx QR code"
          loading="lazy"
        />
      </div>
    </div>
  )
}

const ListOfDeps = () => {
  return (
    <div className={classNames(styles.grid, styles['grid-lod'])}>
      <div className={styles.cell}>Number</div>
      <div className={styles.cell}>Name</div>
      <div className={styles.cell}>Version</div>
      <div className={styles.cell}>Description</div>

      <div className={styles.cell}>1</div>
      <div className={styles.cell}>@ampproject/remapping</div>
      <div className={styles.cell}>2.1.0</div>
      <div className={styles.cell}></div>

      <div className={styles.cell}>2</div>
      <div className={styles.cell}>@babel/cli</div>
      <div className={styles.cell}>7.17.0</div>
      <div className={styles.cell}>Babel command line.</div>

      <div className={styles.cell}>3</div>
      <div className={styles.cell}>@babel/code-frame</div>
      <div className={styles.cell}>7.16.7</div>
      <div className={styles.cell}>
        Generate errors that contain a code frame that point to source
        locations.
      </div>

      <div className={styles.cell}>4</div>
      <div className={styles.cell}>@babel/compat-data</div>
      <div className={styles.cell}>7.17.0</div>
      <div className={styles.cell}></div>

      <div className={styles.cell}>5</div>
      <div className={styles.cell}>@babel/core</div>
      <div className={styles.cell}>7.17.0</div>
      <div className={styles.cell}>Babel compiler core.</div>

      <div className={styles.cell}>6</div>
      <div className={styles.cell}>@babel/generator</div>
      <div className={styles.cell}>7.17.0</div>
      <div className={styles.cell}>Turns an AST into code.</div>

      <div className={styles.cell}>7</div>
      <div className={styles.cell}>@babel/helper-annotate-as-pure</div>
      <div className={styles.cell}>7.16.7</div>
      <div className={styles.cell}>
        Helper function to annotate paths and nodes with #__PURE__ comment
      </div>

      <div className={styles.cell}>8</div>
      <div className={styles.cell}>
        @babel/helper-builder-binary-assignment-operator-visitor
      </div>
      <div className={styles.cell}>7.16.7</div>
      <div className={styles.cell}>
        Helper function to build binary assignment operator visitors
      </div>

      <div className={styles.cell}>9</div>
      <div className={styles.cell}>@babel/helper-compilation-targets</div>
      <div className={styles.cell}>7.16.7</div>
      <div className={styles.cell}>
        Helper functions on Babel compilation targets
      </div>

      <div className={styles.cell}>10</div>
      <div className={styles.cell}>
        @babel/helper-create-class-features-plugin
      </div>
      <div className={styles.cell}>7.17.1</div>
      <div className={styles.cell}>
        Compile class public and private fields, private methods and decorators
        to ES6
      </div>

      <div className={styles.cell}>11</div>
      <div className={styles.cell}>
        @babel/helper-create-regexp-features-plugin
      </div>
      <div className={styles.cell}>7.17.0</div>
      <div className={styles.cell}>
        Compile ESNext Regular Expressions to ES5
      </div>

      <div className={styles.cell}>12</div>
      <div className={styles.cell}>@babel/helper-define-polyfill-provider</div>
      <div className={styles.cell}>0.3.1</div>
      <div className={styles.cell}></div>
    </div>
  )
}

export default Excel
