import React, { useEffect, useState, MouseEvent, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Arrow from '../Arrow'
import AutocompleteComponent from '../Autocomplete'
import Breadcrumb from '../Breadcrumb'
import { ConfigContext } from '../Config'
import { Box } from '../Icons'
import Order from '../Order'
import ListGroupPlaceholder from '../Package/ListGroupPlaceholder'
import Pagination from '../Pagination'
import { Page } from '../types'

const formatter = new Intl.NumberFormat()

interface Maintainer {
  count: number
  email: string
  id: number
  name: string
}

function MaintainerListComponent() {
  const configCtx = useContext(ConfigContext)
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [maintainers, setMaintainers] = useState<Page<Maintainer>>()

  const params = new URLSearchParams(location.search)
  const sort = params.get('sort')
  let order = Order.from(sort)

  const retrieve = async (search: string, api: string) => {
    const data = await fetch(`${api}/api/maintainers${search}`)
    const result = await data.json()
    setMaintainers(result)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(location.search, configCtx.cache)
  }, [location.search, configCtx.cache])

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget
    if (order && order.property === name) {
      // click on the same column
      order.reverse()
    } else {
      // click on another column
      order = Order.by(name)
    }
    params.set('sort', order.toString())
    navigate({
      search: decodeURIComponent(params.toString()),
    })
  }

  return (
    <React.Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'Maintainers' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <AutocompleteComponent
          placeholder="Search for maintainer"
          fetchUrl="/api/maintainers?q="
          pushUrl="/maintainers?name="
        />
        <div className="card mt-3">
          <div className="card-header d-flex align-items-center">
            List of maintainers
            {maintainers ? (
              <span className="badge bg-secondary ms-2">
                {formatter.format(maintainers.totalElements)}
              </span>
            ) : null}
          </div>
          <ul className="list-group list-group-flush placeholder-glow">
            <li
              className="list-group-item d-flex align-items-center"
              style={{
                borderBottom: '2px solid #dee2e6',
              }}
            >
              <strong className="text-truncate">Name</strong>
              <button
                className="btn btn-sm btn-link ms-1"
                onClick={onClick}
                name="name"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Sort name alphabetically"
              >
                <Arrow name="name" order={order} />
              </button>
              <strong className="ms-auto text-truncate">Packages</strong>
              <button
                className="btn btn-sm btn-link ms-1"
                onClick={onClick}
                name="count"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Sort by number of packages"
              >
                <Arrow name="count" order={order} />
              </button>
            </li>
            {loading ? (
              <ListGroupPlaceholder
                cols={[
                  4, 3, 4, 3, 2, 2, 4, 2, 1, 3, 4, 3, 4, 3, 2, 2, 4, 2, 1, 3,
                ]}
                includeUl={false}
              />
            ) : (
              maintainers?.content.map((m, index) => {
                return (
                  <li
                    key={m.email}
                    className="list-group-item d-flex align-items-center"
                  >
                    <small className="text-muted text-small">
                      #{maintainers.number * maintainers.size + index + 1}
                    </small>
                    <Link
                      to={`/maintainers?name=${m.name}`}
                      className="text-decoration-none me-auto ms-4"
                    >
                      {m.name}
                    </Link>
                    <div>
                      <span className="me-2">{m.count}</span>
                      <Box />
                    </div>
                  </li>
                )
              })
            )}
          </ul>
        </div>
        <Pagination loading={loading} page={maintainers} />
      </div>
    </React.Fragment>
  )
}

export default MaintainerListComponent
