import { useEffect, useRef } from 'react'
import Plot from './visualization/Plot'

interface Props {
  maintainers: Map<string, number> | undefined
  color: string
  yLabel: string
}

const Chart: React.FunctionComponent<Props> = function Header(props: Props) {
  const svgRef = useRef<SVGSVGElement>(null)
  const plot = useRef<Plot>()

  useEffect(() => {
    if (svgRef.current) {
      if (!plot.current) {
        // draw chart with skeleton values
        const config = {
          target: svgRef.current,
          margin: {
            top: 30,
            right: 20,
            // some maintainer names are pretty long, e.g. "cypress-npm-publisher"
            bottom: 90,
            left: 50,
          },
          yLabel: props.yLabel,
          // color: props.color,
        }
        plot.current = new Plot(config)
      } else if (plot.current && props.maintainers) {
        // draw chart with real values
        plot.current.update(props.maintainers, props.color)
      }
    }
  }, [props.maintainers, props.color, props.yLabel])

  const resize = (): void => {
    if (svgRef.current && plot.current) {
      plot.current.resize()
    }
  }

  useEffect((): (() => void) => {
    window.addEventListener('resize', resize)
    return (): void => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return <svg ref={svgRef} />
}

export default Chart
