import classNames from 'classnames'
import styles from './security.module.scss'
import Excel from './Excel'

// Open Source Security Foundation (OpenSSF)
// Security Scorecards - Security health metrics for Open Source
// vulnerabilities
// CVEs
// CWE
// security advisories
// OWASP
// Software Composition Analysis (SCA)

const Security: React.FunctionComponent = function Examples() {
  return (
    <div
      className={classNames(
        'pt-5',
        'my-5',
        'text-md-center',
        styles['background']
      )}
    >
      <h1 className="px-4 mb-3 mt-4">Software Supply Chain Security</h1>
      <div className="px-4 col-lg-6 mx-auto mb-4">
        <p className="lead mb-5">
          A resilient software supply chain plays a critical role in software
          development. We follow the recommendations of the Open Source Security
          Foundation (OpenSSF) to recognize Common Vulnerabilities and Exposures
          (CVEs) and Common Weakness Enumerations (CWEs).
        </p>
      </div>

      <div
        className="overflow-hidden px-4"
        style={{
          maxHeight: '35vh',
        }}
      >
        <Excel />
      </div>
    </div>
  )
}

export default Security
