import { FunctionComponent, Fragment } from 'react'
import Breadcrumb from '../Breadcrumb'
import { useScrollToTop } from '../Package/utils'

// https://docs.github.com/en/code-security/supply-chain-security/about-the-dependency-graph

const Roadmap: FunctionComponent = () => {
  useScrollToTop()

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'Roadmap' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <div className="card mb-4">
          <div className="card-header">Roadmap</div>
          <div className="card-body">
            <p>
              We are the one stop solution for your Software Bill of Materials.
            </p>
            <p>Here is our roadmap:</p>
            <ul>
              <li>License check</li>
              <li>Vulnerability analysis</li>
              <li>Risk evaluation</li>
              <li>Dependency management</li>
              <li>Software supply chain management</li>
              <li>Support for all major programming languages</li>
            </ul>
          </div>
        </div>

        <div className="card d-none d-sm-block">
          <div className="card-header">Supported package ecosystems</div>
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th className="ps-3">Package manager</th>
                <th>Languages</th>
                <th>Recommended formats</th>
                <th>All supported formats</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ps-3">npm</td>
                <td>JavaScript</td>
                <td>
                  <code>package-lock.json</code>
                </td>
                <td>
                  <div>
                    <code>package-lock.json</code>
                  </div>
                  <div>
                    <code>package.json</code>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="ps-3">Yarn</td>
                <td>JavaScript</td>
                <td>
                  <code>yarn.lock</code>
                </td>
                <td>
                  <div>
                    <code>yarn.lock</code>
                  </div>
                  <div>
                    <code>package-lock.json</code>
                  </div>
                  <div>
                    <code>package.json</code>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="ps-3">Maven*</td>
                <td>
                  <div>Java</div>
                  <div>Scala</div>
                </td>
                <td>
                  <code>pom.xml</code>
                </td>
                <td>
                  <div>
                    <code>pom.xml</code>
                  </div>
                  <div>
                    <code>build.gradle</code>
                  </div>
                  <div>
                    <code>build.sbt</code>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="ps-3">Python PIP*</td>
                <td>Python</td>
                <td>
                  <div>
                    <code>requirements.txt</code>
                  </div>
                  <div>
                    <code>pipfile.lock</code>
                  </div>
                </td>
                <td>
                  <div>
                    <code>requirements.txt</code>
                  </div>
                  <div>
                    <code>pipfile.lock</code>
                  </div>
                  <div>
                    <code>pipfile</code>
                  </div>
                  <div>
                    <code>setup.py</code>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="ps-3">Composer*</td>
                <td>PHP</td>
                <td>
                  <code>composer.lock</code>
                </td>
                <td>
                  <div>
                    <code>composer.lock</code>
                  </div>
                  <div>
                    <code>composer.json</code>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-bottom-0 ps-3">RubyGems*</td>
                <td className="border-bottom-0">Ruby</td>
                <td className="border-bottom-0">
                  <code>Gemfile.lock</code>
                </td>
                <td className="border-bottom-0">
                  <div>
                    <code>Gemfile.lock</code>
                  </div>
                  <div>
                    <code>Gemfile</code>
                  </div>
                  <div>
                    <code>*.gemspec</code>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2 className="d-sm-none">Supported package ecosystems</h2>
        <Card
          manager="npm"
          languages={['JavaScript']}
          recommended={['package-lock.json']}
          supported={['package-lock.json', 'package.json']}
        />
        <Card
          manager="Yarn"
          languages={['JavaScript']}
          recommended={['yarn.lock']}
          supported={['yarn.lock', 'package-lock.json', 'package.json']}
        />
        <Card
          manager="Maven*"
          languages={['Java', 'Scala']}
          recommended={['pom.xml']}
          supported={['pom.xml', 'build.gradle', 'build.sbt']}
        />
        <Card
          manager="Python PIP*"
          languages={['Python']}
          recommended={['requirements.txt', 'pipfile.lock']}
          supported={[
            'requirements.txt',
            'pipfile.lock',
            'pipfile',
            'setup.py',
          ]}
        />
        <Card
          manager="Composer*"
          languages={['PHP']}
          recommended={['composer.lock']}
          supported={['composer.lock', 'composer.json']}
        />
        <Card
          manager="RubyGems*"
          languages={['Ruby']}
          recommended={['Gemfile.lock']}
          supported={['Gemfile.lock', 'Gemfile', '*.gemspec']}
        />
        <small className="text-muted d-block text-end p-2">*coming soon</small>
      </div>
    </Fragment>
  )
}

interface Props {
  manager: string
  languages: string[]
  recommended: string[]
  supported: string[]
}

const Card: React.FunctionComponent<Props> = function Header(props: Props) {
  return (
    <div className="card mt-4 d-sm-none">
      <div className="card-body">
        <div className="d-flex p-2">
          <strong className="w-50">Package Manager</strong>
          <div>{props.manager}</div>
        </div>
        <div className="d-flex p-2">
          <strong className="w-50">Languages</strong>
          <div>
            {props.languages.map((l) => (
              <div key={l}>{l}</div>
            ))}
          </div>
        </div>
        <div className="d-flex p-2">
          <strong className="w-50">Recommended formats</strong>
          <div>
            {props.recommended.map((r) => (
              <div key={r}>
                <code>{r}</code>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex p-2">
          <strong className="w-50">All supported formats</strong>
          <div>
            {props.supported.map((s) => (
              <div key={s}>
                <code>{s}</code>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roadmap

// - from developers for developers
// - software bill of materials
// - simple solutions for lawyers and legal
// - automated continuous dependency analysis
