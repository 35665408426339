import { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import AutocompleteComponent from '../Autocomplete'
import Breadcrumb from '../Breadcrumb'
import { ConfigContext } from '../Config'
import DropdownFilter from '../DropdownFilter'
import { XSquareFill } from '../Icons'
import Pagination from '../Pagination'
import { Page } from '../types'
import ListGroupPlaceholder from './ListGroupPlaceholder'

// license id query param
// e.g. ?license-id=MIT
export const LICENSE_ID = 'license-id'

const formatter = new Intl.NumberFormat()

const PackageListComponent = (): JSX.Element => {
  const configCtx = useContext(ConfigContext)
  const location = useLocation()
  const navigate = useNavigate()

  const [packages, setPackages] = useState<Page<string>>()
  const [licenses, setLicenses] = useState<string[]>()
  const [loading, setLoading] = useState(true)

  const params = new URLSearchParams(location.search)

  const fetchPackages = async (search: string, api: string) => {
    const data = await fetch(`${api}/api/packages${search}`)
    const pkgs = await data.json()
    setPackages(pkgs)
    setLoading(false)
  }

  const fetchLicenseIds = async (api: string) => {
    const data = await fetch(`${api}/api/licenses/ids`)
    const ids = await data.json()
    setLicenses(ids)
  }

  useEffect(() => {
    fetchLicenseIds(configCtx.cache)
  }, [configCtx.cache])

  useEffect(() => {
    fetchPackages(location.search, configCtx.cache)
  }, [location.search, configCtx.cache])

  const onDropdownClick = (licenseId: string) => {
    if (licenseId === '') {
      params.delete(LICENSE_ID)
    } else {
      params.set(LICENSE_ID, licenseId)
    }
    navigate({
      search: params.toString(),
    })
  }

  const licenseId = params.get(LICENSE_ID)

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'Packages' },
        ]}
      />

      <div className="px-2 px-md-4 py-4">
        <div className="mb-3">
          <AutocompleteComponent
            placeholder="Search for open source packages"
            fetchUrl="/api/packages?q="
            pushUrl="/packages?name="
          />
        </div>

        <div className="mb-3">
          <DropdownFilter
            items={licenses ?? []}
            active={licenseId ?? ''}
            onClick={onDropdownClick}
          />
        </div>

        <ClearComponent licenseId={licenseId} />

        <div className="card placeholder-glow">
          <div className="card-header d-flex align-items-center">
            Packages
            {packages ? (
              <span className="badge bg-secondary ms-2">
                {formatter.format(packages.totalElements)}
              </span>
            ) : null}
          </div>

          {loading ? (
            <ListGroupPlaceholder
              cols={[
                4, 3, 4, 3, 2, 2, 4, 2, 1, 3, 4, 3, 4, 3, 2, 2, 4, 2, 1, 3,
              ]}
              includeUl={true}
            />
          ) : packages?.empty ? (
            <div className="card-body">
              <span>We could not find any packages for license</span>{' '}
              <Link
                to={`/licenses/${licenseId}`}
                className="text-decoration-none"
              >
                {licenseId}
              </Link>
              <span>.</span>
            </div>
          ) : (
            <ul className="list-group list-group-flush">
              {packages?.content.map((pkg, index) => {
                return (
                  <li key={pkg} className="list-group-item">
                    <small className="text-muted text-small">
                      #{packages.number * packages.size + index + 1}
                    </small>
                    <Link
                      to={`/packages?name=${pkg}`}
                      className="text-decoration-none ms-4"
                    >
                      {pkg}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
        <Pagination loading={loading} page={packages} />
      </div>
    </Fragment>
  )
}

interface Clear {
  licenseId: string | null
}

const ClearComponent: React.FunctionComponent<Clear> = (props: Clear) => {
  if (!props.licenseId) {
    return null
  }

  return (
    <div className="mb-3 ms-1">
      <Link
        to="/packages"
        className="d-flex align-items-center text-decoration-none"
      >
        <XSquareFill />
        <span className="ms-2">Clear all filters</span>
      </Link>
    </div>
  )
}

export default PackageListComponent
