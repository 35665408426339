import { FunctionComponent } from 'react'
import { useSearchParams } from 'react-router-dom'
import PackageListComponent from './PackageListComponent'
import Version from './Version'

const PackageWrapper: FunctionComponent = () => {
  const [params] = useSearchParams()
  const name = params.get('name')
  const version = params.get('version')

  // /packages?name=debug&version=2.1.1
  if (name !== null && version !== null) {
    return <Version />
  }

  // /packages?name=debug
  if (name !== null) {
    return <Version />
  }

  // /packages
  return <PackageListComponent />
}

export default PackageWrapper
