import classNames from 'classnames'
import { mean } from 'd3-array'
import { scaleOrdinal } from 'd3-scale'
import { schemeAccent } from 'd3-scale-chromatic'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Chart from './Chart'
import { ConfigContext } from './Config'
import Editor from './Editor'
import styles from './grid.module.scss'
import {
  FileExcel,
  FileMedical,
  FilePdf,
  FileRichText,
  FileSpreadsheet,
  FileText,
  QuestionCircle,
} from './Icons'
import ListGroupPlaceholder from './Package/ListGroupPlaceholder'
import { FileType } from './types'
import BoxPlotComponent, { Box } from './visualization/BoxPlotComponent'

interface Dependency {
  name: string
  version: string
  licenseId: string
  closedIssuesCount: number
  commentFrequency: number
  commitFrequency: number
  contributorCount: number
  createdSince: number
  criticalityScore: number
  dependentsCount: number
  orgCount: number
  recentReleasesCount: number
  updatedIssuesCount: number
  updatedSince: number
  watchersCount: number
}

interface Response {
  file: string
  deps: [Dependency]
  maintainers: Map<string, number>
  licenses: Map<string, number>
  registries: Map<string, number>
}

const formatter = new Intl.NumberFormat()

interface Props {
  type: FileType
}

// http://localhost:3000/deps/package-lock.json/2670f5c8c801f1353d85f404a7a941035660e1ff075f66c3f6e7cbedd07d2ea8
// @angular/service-worker@9.1.9
// @angular/animations@9.1.9
// @grafana/toolkit@8.3.2
// @babel/helper-regex@7.8.3
// @cypress/mount-utils@1.0.2
// @apache-arrow/ts@6.0.1

const filter = (d: Dependency): boolean => {
  return (
    d.closedIssuesCount !== null &&
    d.commentFrequency !== null &&
    d.commitFrequency !== null &&
    d.contributorCount !== null &&
    d.createdSince !== null &&
    d.criticalityScore !== null &&
    d.dependentsCount !== null &&
    d.orgCount !== null &&
    d.recentReleasesCount !== null &&
    d.updatedIssuesCount !== null &&
    d.updatedSince !== null &&
    d.watchersCount !== null
  )
}

const map = (d: Dependency): Box => {
  return {
    closedIssuesCount: d.closedIssuesCount,
    commentFrequency: d.commentFrequency,
    commitFrequency: d.commitFrequency,
    contributorCount: d.contributorCount,
    createdSince: d.createdSince,
    criticalityScore: d.criticalityScore,
    dependentsCount: d.dependentsCount,
    orgCount: d.orgCount,
    recentReleasesCount: d.recentReleasesCount,
    updatedIssuesCount: d.updatedIssuesCount,
    updatedSince: d.updatedSince,
    watchersCount: d.watchersCount,
  }
}

const PackageLockJson: React.FunctionComponent<Props> = (props: Props) => {
  const configCtx = useContext(ConfigContext)

  const { hash } = useParams<'hash'>()
  const [data, setData] = useState<Response>()
  const [loading, setLoading] = useState(true)

  const retrieve = async (
    hash: string | undefined,
    type: FileType,
    api: string
  ) => {
    const url = `${api}/api/deps/${type}/${hash}`
    const response = await fetch(url)
    const data = await response.json()
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(hash, props.type, configCtx.cache)
  }, [hash, props.type, configCtx.cache])

  const editor = useRef(null)

  // we need the explicit type here
  // we also need the color here and not in the chart because we need the same color for the badges
  const color = scaleOrdinal<number, string>(schemeAccent)

  const color0 = color(0)
  const color1 = color(1)
  const color2 = color(2)
  const color3 = color(3)

  const boxes = data?.deps.filter(filter).map(map)
  const score = mean(boxes ?? [], (d) => d.criticalityScore)
  const registries = Object.entries(data?.registries ?? {})

  return (
    <div className="px-2 px-md-4 py-4">
      <div className="card mb-4">
        <div className="card-header">
          This is your <code>{props.type}</code>
        </div>
        <div className="card-body">
          {loading ? (
            <Spinner />
          ) : (
            <Editor value={data?.file} language="json" ref={editor} />
          )}
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header d-flex align-items-center">
          These are your dependencies
          <span className="badge bg-secondary ms-2">
            {formatter.format(data?.deps.length ?? 0)}
          </span>
        </div>
        <ul
          className="list-group list-group-flush placeholder-glow"
          style={{
            overflowY: 'auto',
            // 10 * height + 1/2 * height
            maxHeight: 430,
          }}
        >
          {loading ? (
            <ListGroupPlaceholder
              cols={[4, 3, 4, 3, 2, 2, 4, 2, 1, 3, 2]}
              includeUl={false}
            />
          ) : (
            data?.deps.map((d) => {
              return (
                <li key={d.name + d.version} className="list-group-item d-flex">
                  <Link
                    to={`/packages?name=${d.name}&version=${d.version}`}
                    className="text-decoration-none text-truncate"
                  >
                    {d.name}@{d.version}
                  </Link>
                  <span className="ms-auto">{d.licenseId}</span>
                </li>
              )
            })
          )}
        </ul>
      </div>
      <div className={classNames('d-grid', 'mb-4', styles['grid'])}>
        <div className={classNames('card', styles['grid-row-2'])}>
          <div className="card-header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              Maintainers
              <span
                className="badge ms-2"
                style={{
                  backgroundColor: color0,
                  color: 'currentcolor',
                }}
              >
                {formatter.format(Object.keys(data?.maintainers ?? 0).length)}
              </span>
            </div>

            <small className="text-muted">The top 25 maintainers</small>
          </div>
          <div className="card-body">
            <Chart
              maintainers={data?.maintainers}
              color={color0}
              yLabel="↑ Number of Packages"
            />
          </div>
        </div>
        <div className={classNames('card', styles['grid-row-2'])}>
          <div className="card-header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              Licenses
              <span
                className="badge ms-2"
                style={{
                  backgroundColor: color1,
                  color: 'currentcolor',
                }}
              >
                {formatter.format(Object.keys(data?.licenses ?? 0).length)}
              </span>
            </div>
            <small className="text-muted">The top 25 licenses</small>
          </div>
          <div className="card-body">
            <Chart
              maintainers={data?.licenses}
              color={color1}
              yLabel="↑ Number of Packages"
            />
          </div>
        </div>
        <div
          className={classNames('card', styles['span-2'], styles['grid-row-2'])}
        >
          <div className="card-header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              Criticality Score
              {score === undefined ? null : (
                <span
                  className="badge ms-2"
                  style={{
                    backgroundColor: color2,
                    color: 'currentcolor',
                  }}
                >
                  {formatter.format(score)}
                </span>
              )}
              <Link
                to="/criticality-score"
                className="d-flex ms-2 link-secondary"
              >
                <QuestionCircle />
              </Link>
            </div>
            <small className="text-muted d-none d-sm-block">
              Calculated for {boxes?.length}/{data?.deps.length} dependencies
            </small>
          </div>
          <div className="card-body">
            <BoxPlotComponent boxes={boxes} color={color2} />
          </div>
        </div>
        <div className={classNames('card')}>
          <div className="card-header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              Registries
              <span
                className="badge ms-2"
                style={{
                  backgroundColor: color3,
                  color: 'currentcolor',
                }}
              >
                {formatter.format(registries.length)}
              </span>
            </div>
          </div>
          <ul className="list-group list-group-flush placeholder-glow">
            {registries.map(([key, value]) => {
              return (
                <li
                  key={key}
                  className="list-group-item d-flex align-items-center"
                >
                  <span>{key}</span>
                  <span className="ms-auto badge bg-secondary">
                    {formatter.format(value)}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <Dropdown hash={hash} loading={loading} type={props.type} />
    </div>
  )
}

const Spinner: React.FunctionComponent = () => {
  return (
    <div className="d-flex" style={{ height: 400 }}>
      <div className="spinner-grow mx-auto my-auto" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

interface DropdownProps {
  hash: string | undefined
  loading: boolean
  type: FileType
}

const Dropdown: React.FunctionComponent<DropdownProps> = function Header(
  props: DropdownProps
) {
  const { hash } = props
  return (
    <div className="dropdown">
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        disabled={props.loading}
      >
        Download SBOM
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            download
            href={`https://excel.sbomx.com/${props.type}/${hash}.xlsx`}
          >
            <FileExcel />
            <span className="ms-1">Excel</span>
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex align-items-center disabled"
            download
            href={`https://web.sbomx.com/api/deps/package-lock.json/${hash}.pdf`}
          >
            <FilePdf />
            <span className="ms-1">PDF</span>
          </a>
        </li>

        <li>
          <a
            className="dropdown-item d-flex align-items-center disabled"
            download
            href={`https://web.sbomx.com/api/deps/package-lock.json/${hash}.xml`}
          >
            <FileMedical />
            <span className="ms-1">SPDX</span>
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex align-items-center disabled"
            download
            href={`https://web.sbomx.com/api/deps/package-lock.json/${hash}.xml`}
          >
            <FileRichText />
            <span className="ms-1">CycloneDX</span>
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex align-items-center disabled"
            download
            href={`https://web.sbomx.com/api/deps/package-lock.json/${hash}.xml`}
          >
            <FileText />
            <span className="ms-1">SWID</span>
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex align-items-center disabled"
            download
            href={`https://web.sbomx.com/api/deps/package-lock.json/${hash}.csv`}
          >
            <FileSpreadsheet />
            <span className="ms-1">CSV</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default PackageLockJson
