export interface License {
  id: string
  reference: string
  isDeprecatedLicenseId: boolean
  isOsiApproved: boolean
  licenseId: string
  name: string
  referenceNumber: number
  licenseText: string
}

export interface LicenseCount extends License {
  count: number
}

export type LicenseCountProjectionShort = Pick<
  LicenseCount,
  'name' | 'licenseId' | 'count'
>

export interface Dependency {
  name: string
  version: string
  resolvedVersion: string
  licenseId: string
}

export interface NpmPackageVersionLicenseProjection {
  createdAt: string
  name: string
  // package version description
  description: string
  version: string
  licenseId: string | null
  tags: string | null
}

export interface Package {
  id: number
  name: string
  // description: string
}

export interface Page<T> {
  content: T[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: Pageable
  size: number
  sort: Sort
  totalElements: number
  totalPages: number
}

export interface Pageable {
  offset: number
  pageNumber: number
  pageSize: number
  paged: boolean
  sort: Sort
  unpaged: boolean
}

export interface Sort {
  empty: boolean
  sorted: boolean
  unsorted: boolean
}

export interface MaintainerRecord {
  name: string
  email: string
  url: string
}

export interface LicenseRecord {
  name: string
  licenseId: string
}

export interface NpmPackageVersionRecord {
  homepage?: string
  repositoryType: string
  repositoryUrl?: string
  repositoryDirectory: string
  createdAt: string
  bugs?: string
  readme: string
  license?: LicenseRecord
  description: string
  version: string
}

export interface KeywordCount {
  count: number
  keyword: string
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
  unsorted = 'unsorted',
}

export const isSortDirection = (s: string): s is SortDirection => {
  return Object.values(SortDirection).includes(s as SortDirection)
}

export type FileType = 'package-lock.json' | 'yarn.lock'
