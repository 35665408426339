import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Angular, Bootstrap, Vue } from '../Icons'
import styles from './examples.module.scss'

const Examples: React.FunctionComponent = function Examples() {
  return (
    <div
      className={classNames(
        'p-5',
        'my-5',
        'text-md-center',
        styles['background']
      )}
    >
      <h1 className="mb-3 mt-4">Software Bill of Materials</h1>
      <div className="col-lg-6 mx-auto mb-4">
        <p className="lead mb-5">
          Quickly analyze and identify issues with sbomx, the world’s most
          advanced solution for creating Software Bill of Materials (SBOM).
          Inspect your dependencies before you integrate them in your products
          and keep an eye on potential security issues.
        </p>
        <div className="d-grid gap-4 d-sm-flex justify-content-sm-center">
          <Link
            className="btn btn-light btn-lg px-5 d-flex align-items-center"
            to="/deps/package-lock.json/f4c3c5908a59cdfdc2d37155f9cb43052f142fe6cc61717bb48d3284c6312fdc"
          >
            <Bootstrap width="20px" height="20px" />
            <span className="ms-2">Bootstrap</span>
          </Link>
          <Link
            className="btn btn-light btn-lg px-5 d-flex align-items-center"
            to="/deps/yarn.lock/fd96a61c11ab6666139db8366158fbb726b9617134cc302f177ead6b42343eb4"
          >
            <Angular width="20px" height="20px" />
            <span className="ms-2">Angular</span>
          </Link>

          <Link
            className="btn btn-light btn-lg px-5 d-flex align-items-center"
            to="/deps/yarn.lock/f864f85c4b0b77f7ccb3ad1254a4f33e1a57b80dc31137c518df3f4cc9a7bad0"
          >
            <Vue width="20px" height="20px" />
            <span className="ms-2">Vue</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Examples
