// http://localhost:8091/api/packages/react/versions?page=2
// https://github.com/AdeleD/react-paginate/blob/master/react_components/PaginationBoxView.js
// https://github.com/michaelbromley/ngx-pagination/blob/master/src/pagination-controls.directive.ts

import classNames from 'classnames'
import classnames from 'classnames'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { ChevronLeft, ChevronRight } from './Icons'
import { Page } from './types'

interface PaginationProps {
  loading: boolean
  page: Page<unknown> | undefined
}

// the frontend is 1-based whereas
// the backend is 0-based
const Pagination: FunctionComponent<PaginationProps> = (
  props: PaginationProps
) => {
  const foo = []

  const [params] = useSearchParams()
  const [disabled, setDisabled] = useState(false)

  const onClick = () => {
    setDisabled(true)
  }

  useEffect(() => {
    setDisabled(false)
  }, [props.page?.number])

  if (props.loading) {
    return (
      <React.Fragment>
        <div className="d-sm-none my-3 d-flex justify-content-between placeholder-glow">
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-link disabled placeholder"
          >
            <ChevronLeft width="12px" height="12px" />
            <span className="ms-1">Previous</span>
          </a>
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-link disabled placeholder"
          >
            <span className="me-1">Next</span>
            <ChevronRight width="12px" height="12px" />
          </a>
        </div>

        <div className="d-none d-sm-flex btn-group placeholder-glow my-3 justify-content-center">
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-outline-primary disabled placeholder flex-shrink-0 flex-grow-0"
          >
            <ChevronLeft width="12px" height="12px" />
            <span className="ms-1">Previous</span>
          </a>
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-outline-primary disabled placeholder flex-shrink-0 flex-grow-0"
          >
            1
          </a>
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-outline-primary disabled placeholder flex-shrink-0 flex-grow-0"
          >
            2
          </a>
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-outline-primary disabled placeholder flex-shrink-0 flex-grow-0"
          >
            3
          </a>
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-outline-primary disabled placeholder flex-shrink-0 flex-grow-0"
          >
            4
          </a>
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-outline-primary disabled placeholder flex-shrink-0 flex-grow-0"
          >
            5
          </a>
          <a
            href="/"
            tabIndex={-1}
            className="btn btn-outline-primary disabled placeholder flex-shrink-0 flex-grow-0"
          >
            <span className="me-1">Next</span>
            <ChevronRight width="12px" height="12px" />
          </a>
        </div>
      </React.Fragment>
    )
  }

  if (props.page === undefined) {
    return null
  }

  // do not show pagination for only one page
  if (props.page.totalPages <= 1) {
    return null
  }

  for (let index = 0; index < props.page.totalPages; index++) {
    const page = index + 1
    params.set('page', page.toString())

    if (Math.abs(props.page.number - index) <= 2) {
      // center block
      foo.push(
        <li
          className={classnames('page-item', {
            active: props.page.number === index,
            disabled,
          })}
          key={page}
        >
          <Link
            onClick={onClick}
            className="page-link"
            to={{
              search: params.toString(),
            }}
          >
            {page}
          </Link>
        </li>
      )
    } else if (index <= 1) {
      // left first two
      foo.push(
        <li
          className={classNames('page-item', {
            disabled,
          })}
          key={page}
        >
          <Link
            onClick={onClick}
            className="page-link"
            to={{
              search: params.toString(),
            }}
          >
            {page}
          </Link>
        </li>
      )
    } else if (props.page.totalPages - index <= 2) {
      // right last two
      foo.push(
        <li
          className={classNames('page-item', {
            disabled,
          })}
          key={page}
        >
          <Link
            onClick={onClick}
            className="page-link"
            to={{
              search: params.toString(),
            }}
          >
            {page}
          </Link>
        </li>
      )
    } else if (Math.abs(props.page.number - index) === 3) {
      // ellipsis
      foo.push(
        <li className="page-item disabled" key={page}>
          <a className="page-link" href="/" tabIndex={-1} aria-disabled="true">
            ...
          </a>
        </li>
      )
    }
  }

  return (
    <React.Fragment>
      <nav className="d-sm-none my-3 d-flex justify-content-between">
        <Link
          onClick={onClick}
          className={classNames(
            'btn btn-link d-flex align-items-center text-decoration-none',
            {
              disabled: props.page?.first || disabled,
            }
          )}
          to={{
            search: ((params: URLSearchParams) => {
              params.set('page', props.page.number.toString())
              return params.toString()
            })(params),
          }}
        >
          <ChevronLeft width="12px" height="12px" />
          <span className="ms-1">Previous</span>
        </Link>
        <Link
          onClick={onClick}
          className={classNames(
            'btn btn-link d-flex align-items-center text-decoration-none',
            {
              disabled: props.page?.last || disabled,
            }
          )}
          to={{
            search: ((params: URLSearchParams) => {
              params.set('page', (props.page.number + 2).toString())
              return params.toString()
            })(params),
          }}
        >
          <span className="me-1">Next</span>
          <ChevronRight width="12px" height="12px" />
        </Link>
      </nav>
      <nav className="d-none d-sm-block my-3">
        <ul className="pagination justify-content-center">
          <li
            className={classnames('page-item', {
              disabled: props.page?.first || disabled,
            })}
          >
            <Link
              onClick={onClick}
              className="page-link d-flex align-items-center"
              to={{
                search: ((params: URLSearchParams) => {
                  params.set('page', props.page.number.toString())
                  return params.toString()
                })(params),
              }}
            >
              <ChevronLeft width="12px" height="12px" />
              <span className="ms-1">Previous</span>
            </Link>
          </li>
          {foo}
          <li
            className={classnames('page-item', {
              disabled: props.page?.last || disabled,
            })}
          >
            <Link
              onClick={onClick}
              className="page-link d-flex align-items-center"
              to={{
                search: ((params: URLSearchParams) => {
                  params.set('page', (props.page.number + 2).toString())
                  return params.toString()
                })(params),
              }}
            >
              <span className="me-1">Next</span>
              <ChevronRight width="12px" height="12px" />
            </Link>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  )
}

export default Pagination
