import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

export interface BreadcrumbLink {
  href: string | null
  text: string
  title?: string
}

interface Props {
  links: BreadcrumbLink[]
  className: string
}

const Breadcrumb: FunctionComponent<Props> = (props: Props) => {
  const length = props.links.length
  const truncate = length > 2

  const items: Array<JSX.Element> = []

  const prev = props.links[length - 2]
  if (prev) {
    items.push(
      <li className="breadcrumb-item" key={prev.text}>
        <Link
          title={prev.title}
          to={prev.href ?? ''}
          className="text-decoration-none"
          style={
            truncate
              ? {
                  margin: '-0.25rem',
                  padding: '0.25rem',
                }
              : undefined
          }
        >
          {truncate ? '..' : prev.text}
        </Link>
      </li>
    )
  }

  const last = props.links[length - 1]
  if (last) {
    items.push(
      <li
        className="breadcrumb-item active text-truncate"
        title={last.title}
        key={last.text}
      >
        {last.text}
      </li>
    )
  }

  return (
    <nav
      aria-label="breadcrumb"
      className={classNames('px-4', 'p-3', 'sbomx-background', props.className)}
    >
      <ol className="breadcrumb flex-nowrap d-sm-none m-0">{items}</ol>
      <ol className="breadcrumb flex-nowrap d-none d-sm-flex m-0">
        {props.links.map((link) => {
          if (link.href === null) {
            if (link.text) {
              return (
                <li
                  key={link.text}
                  className="breadcrumb-item active text-truncate"
                  title={link.title}
                >
                  {link.text}
                </li>
              )
            }
            return (
              <li
                key={link.text}
                className="breadcrumb-item placeholder-glow d-flex align-items-center"
                style={{ width: 200 }}
                title={link.title}
              >
                <span className="placeholder w-75" />
              </li>
            )
          }
          return (
            <li key={link.text} className="breadcrumb-item d-flex">
              <Link
                to={link.href}
                className="text-decoration-none"
                title={link.title}
              >
                {link.text}
              </Link>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumb
