import { useContext, useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { ConfigContext } from '../Config'

interface Cwe {
  cweId: number
  name: string
  description: string
}

const CweComponent = () => {
  const configCtx = useContext(ConfigContext)

  const { cweId } = useParams<'cweId'>()
  const [cwe, setCwe] = useState<Cwe>()
  const [loading, setLoading] = useState(true)

  const retrieve = async (cweId: string | undefined, api: string) => {
    const response = await fetch(`${api}/api/cwes/${cweId}`)
    const data = await response.json()
    setCwe(data)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(cweId, configCtx.cache)
  }, [cweId, configCtx.cache])

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          {
            href: '/cwes',
            text: 'CWEs',
            title: 'Common Weakness Enumerations',
          },
          { href: null, text: cweId ?? '' },
        ]}
      />

      <div className="px-2 px-md-4 py-4">
        <div className="card">
          <div className="card-header placeholder-glow">
            {loading ? (
              <span className="placeholder col-3" />
            ) : (
              <span>{cwe?.name}</span>
            )}
          </div>
          <div className="card-body">
            {loading ? (
              <CwePlaceholder />
            ) : (
              <Fragment>
                <h5>CWE ID</h5>
                <p>{cwe?.cweId}</p>
                <h5>Name</h5>
                <p>{cwe?.name}</p>
                <h5>Description</h5>
                <p>{cwe?.description}</p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const CwePlaceholder = () => {
  return (
    <div className="placeholder-glow">
      <h5>
        <span className="placeholder col-3" />
      </h5>
      <p>
        <span className="placeholder col-1" />
      </p>
      <h5>
        <span className="placeholder col-3" />
      </h5>
      <p>
        <span className="placeholder col-8" />
      </p>
      <h5>
        <span className="placeholder col-3" />
      </h5>
      <p>
        <span className="placeholder col-10" />
      </p>
    </div>
  )
}

export default CweComponent
