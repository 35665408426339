import classNames from 'classnames'
import { useCombobox } from 'downshift'
import React, { FunctionComponent, useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConfigContext } from './Config'

interface Autocomplete {
  placeholder: string
  fetchUrl: string
  pushUrl: string
}

// from bootstrap css
// .dropdown-item:hover, .dropdown-item:focus {
//   color: #1e2125;
//   background-color: #e9ecef;
// }
const styles = {
  color: '#1e2125',
  backgroundColor: '#e9ecef',
}

const AutocompleteComponent: FunctionComponent<Autocomplete> = (
  props: Autocomplete
) => {
  const configCtx = useContext(ConfigContext)

  const [inputItems, setInputItems] = useState<string[]>([])
  const navigate = useNavigate()
  const ref = useRef<HTMLInputElement>(null)

  const { pushUrl, fetchUrl } = props

  const abortController = useRef<AbortController>()

  const combobox = useCombobox({
    items: inputItems,
    onSelectedItemChange: (changes) => {
      navigate(pushUrl + changes.selectedItem)
    },
    onInputValueChange: async (changes) => {
      // cancel current request if there is one
      abortController.current?.abort()

      switch (changes.type) {
        // search for package
        case useCombobox.stateChangeTypes.InputChange:
          if (changes.inputValue) {
            try {
              abortController.current = new AbortController()
              const data = await fetch(
                configCtx.cache + fetchUrl + changes.inputValue,
                {
                  signal: abortController.current.signal,
                }
              )
              const list = await data.json()
              setInputItems(list)
            } catch (error) {
              console.log(error)
            }
          } else {
            // close menu when input is empty or on escape key
            if (changes.isOpen) {
              combobox.closeMenu()
            }
          }
          break

        default:
          break
      }
    },
  })

  return (
    <React.Fragment>
      <div {...combobox.getComboboxProps()}>
        <input
          type="search"
          {...combobox.getInputProps({
            ref,
            // go to package when you hit enter in the input field
            // e.g. search for "react" and you do not like the autocomplete
            // then you can still select the package by simply hitting "enter"
            onKeyDown: (event) => {
              if (event.key === 'Enter' && combobox.highlightedIndex === -1) {
                const url = pushUrl + event.currentTarget.value
                navigate(url)
              }
            },
          })}
          autoCapitalize="off"
          className="form-control"
          placeholder={props.placeholder}
        />
      </div>
      <ul
        className={classNames('dropdown-menu', {
          show: combobox.isOpen,
        })}
        {...combobox.getMenuProps()}
      >
        {inputItems.map((item, index) => {
          const highlighted = item.replaceAll(
            combobox.inputValue,
            `<strong>${combobox.inputValue}</strong>`
          )
          return (
            <li
              key={`${item}${index}`}
              {...combobox.getItemProps({ item, index })}
            >
              <button
                className="dropdown-item"
                type="button"
                style={combobox.highlightedIndex === index ? styles : {}}
              >
                <span dangerouslySetInnerHTML={{ __html: highlighted }} />
              </button>
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}

export default AutocompleteComponent
