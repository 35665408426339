import { Toast } from 'bootstrap'
import {
  useEffect,
  useRef,
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
} from 'react'

interface ToastContextInterface {
  show: (content: string) => void
}

export const ToastContext = createContext<ToastContextInterface>({
  show: () => {
    // some comment
  },
})

interface Props {
  children: ReactNode
}

// remove translucent from toast. it looks bad on top of the navbar
const ToastContextProvider: FunctionComponent<Props> = (props: Props) => {
  const toast = useRef<Toast>()
  const [content, setContent] = useState('')

  useEffect(() => {
    const element = document.querySelector('.toast')
    const instance = new Toast(element as Element)
    toast.current = instance
  })

  const show = (val: string) => {
    setContent(val)
    if (toast.current) {
      toast.current.show()
    }
  }

  const context = {
    show,
  }

  return (
    <ToastContext.Provider value={context}>
      {props.children}

      <div className="toast-container position-fixed top-0 end-0 p-2 p-md-4">
        <div
          className="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style={{
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <div className="toast-header">
            <svg
              className="bd-placeholder-img rounded me-2"
              width="20"
              height="20"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <rect width="100%" height="100%" fill="#007aff"></rect>
            </svg>
            <strong className="me-auto">sbomx</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div className="toast-body">{content}</div>
        </div>
      </div>
    </ToastContext.Provider>
  )
}

export default ToastContextProvider
