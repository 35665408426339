/**
 * Calculate the width of an html element.
 * We need this to make the SVG element as wide as the parent element on first render.
 *
 * @param node the html element
 * @returns the width for the svg element
 */
export const calc = (node: HTMLElement): number => {
  const cs = window.getComputedStyle(node)
  return (
    parseFloat(cs.width || '') -
    parseFloat(cs.paddingLeft || '') -
    parseFloat(cs.paddingRight || '')
  )
}

export const calcHeight = (node: HTMLElement): number => {
  const cs = window.getComputedStyle(node)
  return (
    parseFloat(cs.height || '') -
    parseFloat(cs.paddingTop || '') -
    parseFloat(cs.paddingBottom || '')
  )
}

// /**
//  * Calculate the height depending on the width.
//  * On a smaller screen, e.g. mobile phone, it is prettier to have smaller charts.
//  *
//  * @param width the width of the parent element
//  * @returns the height for the svg element
//  */
// export const calcHeight = (width: number): number => {
//   return width <= 500 ? 200 : 480
// }
