import { useEffect } from 'react'
import { useMatch, useSearchParams } from 'react-router-dom'

export const useMapLocationToApi = () => {
  const [params] = useSearchParams()
  const name = params.get('name')
  const version = params.get('version')
  const page = params.get('page')

  const query = new URLSearchParams()

  // the browser url `packages/versions?name=json2csv` is special
  // it always queries the same endpoint and neither includes `version` nor `tag`
  // no matter at which version we are looking we always want to see all other versions
  // it queries the parent entity `package` and has nothing to do with a single version
  const foo = useMatch('/packages/versions')
  if (foo !== null) {
    if (name) {
      query.set('name', name)
    }
    if (page) {
      query.set('page', page)
    }
    return query.toString()
  }

  // from here on we might either look at a version or a tag, e.g. `latest`
  if (name) {
    query.set('name', name)
  }
  if (version) {
    query.set('version', version)
  } else {
    query.set('tag', 'latest')
  }

  // /packages/dependents?name=json2csv needs pagination
  if (page) {
    query.set('page', page)
  }

  // remember that the query does not have a starting ?
  // it must be added manually to the string in the calling function
  return query.toString()
}

export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
}
