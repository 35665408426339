export enum EnvironmentalMetric {
  ConfidentialityRequirement = 'CR',
  IntegrityRequirement = 'IR',
  AvailabilityRequirement = 'AR',
  ModifiedAttackVector = 'MAV',
  ModifiedAttackComplexity = 'MAC',
  ModifiedPrivilegesRequired = 'MPR',
  ModifiedUserInteraction = 'MUI',
  ModifiedScope = 'MS',
  ModifiedConfidentiality = 'MC',
  ModifiedIntegrity = 'MI',
  ModifiedAvailability = 'MA',
}

export const isEnvironmentalMetric = (s: string): s is EnvironmentalMetric => {
  return Object.values<string>(EnvironmentalMetric).includes(s)
}

export enum ConfidentialityRequirement {
  NotDefined = 'X',
  Low = 'L',
  Medium = 'M',
  High = 'H',
}

export const isConfidentialityRequirement = (
  s: string
): s is ConfidentialityRequirement => {
  return Object.values<string>(ConfidentialityRequirement).includes(s)
}

export enum IntegrityRequirement {
  NotDefined = 'X',
  Low = 'L',
  Medium = 'M',
  High = 'H',
}

export const isIntegrityRequirement = (
  s: string
): s is IntegrityRequirement => {
  return Object.values<string>(IntegrityRequirement).includes(s)
}

export enum AvailabilityRequirement {
  NotDefined = 'X',
  Low = 'L',
  Medium = 'M',
  High = 'H',
}

export const isAvailabilityRequirement = (
  s: string
): s is AvailabilityRequirement => {
  return Object.values<string>(AvailabilityRequirement).includes(s)
}

export enum ModifiedAttackVector {
  NotDefined = 'X',
  Network = 'N',
  Adjacent = 'A',
  Local = 'L',
  Physical = 'P',
}

export const isModifiedAttackVector = (
  s: string
): s is ModifiedAttackVector => {
  return Object.values<string>(ModifiedAttackVector).includes(s)
}

export enum ModifiedAttackComplexity {
  NotDefined = 'X',
  Low = 'L',
  High = 'H',
}

export const isModifiedAttackComplexity = (
  s: string
): s is ModifiedAttackComplexity => {
  return Object.values<string>(ModifiedAttackComplexity).includes(s)
}

export enum ModifiedPrivilegesRequired {
  NotDefined = 'X',
  None = 'N',
  Low = 'L',
  High = 'H',
}

export const isModifiedPrivilegesRequired = (
  s: string
): s is ModifiedPrivilegesRequired => {
  return Object.values<string>(ModifiedPrivilegesRequired).includes(s)
}

export enum ModifiedUserInteraction {
  NotDefined = 'X',
  None = 'N',
  Required = 'R',
}

export const isModifiedUserInteraction = (
  s: string
): s is ModifiedUserInteraction => {
  return Object.values<string>(ModifiedUserInteraction).includes(s)
}

export enum ModifiedScope {
  NotDefined = 'X',
  Unchanged = 'U',
  Changed = 'C',
}

export const isModifiedScope = (s: string): s is ModifiedScope => {
  return Object.values<string>(ModifiedScope).includes(s)
}

export enum ModifiedConfidentiality {
  NotDefined = 'X',
  High = 'H',
  Low = 'L',
  None = 'N',
}

export const isModifiedConfidentiality = (
  s: string
): s is ModifiedConfidentiality => {
  return Object.values<string>(ModifiedConfidentiality).includes(s)
}

export enum ModifiedIntegrity {
  NotDefined = 'X',
  High = 'H',
  Low = 'L',
  None = 'N',
}

export const isModifiedIntegrity = (s: string): s is ModifiedIntegrity => {
  return Object.values<string>(ModifiedIntegrity).includes(s)
}

export enum ModifiedAvailability {
  NotDefined = 'X',
  High = 'H',
  Low = 'L',
  None = 'N',
}

export const isModifiedAvailability = (
  s: string
): s is ModifiedAvailability => {
  return Object.values<string>(ModifiedAvailability).includes(s)
}

type EnvironmentalMetricMap = {
  [EnvironmentalMetric.ConfidentialityRequirement]: ConfidentialityRequirement
  [EnvironmentalMetric.IntegrityRequirement]: IntegrityRequirement
  [EnvironmentalMetric.AvailabilityRequirement]: AvailabilityRequirement
  [EnvironmentalMetric.ModifiedAttackVector]: ModifiedAttackVector
  [EnvironmentalMetric.ModifiedAttackComplexity]: ModifiedAttackComplexity
  [EnvironmentalMetric.ModifiedPrivilegesRequired]: ModifiedPrivilegesRequired
  [EnvironmentalMetric.ModifiedUserInteraction]: ModifiedUserInteraction
  [EnvironmentalMetric.ModifiedScope]: ModifiedScope
  [EnvironmentalMetric.ModifiedConfidentiality]: ModifiedConfidentiality
  [EnvironmentalMetric.ModifiedIntegrity]: ModifiedIntegrity
  [EnvironmentalMetric.ModifiedAvailability]: ModifiedAvailability
}

type InformationMap = {
  [EnvironmentalMetric.ConfidentialityRequirement]: Information
  [EnvironmentalMetric.IntegrityRequirement]: Information
  [EnvironmentalMetric.AvailabilityRequirement]: Information
  [EnvironmentalMetric.ModifiedAttackVector]: Information
  [EnvironmentalMetric.ModifiedAttackComplexity]: Information
  [EnvironmentalMetric.ModifiedPrivilegesRequired]: ModifiedInformation
  [EnvironmentalMetric.ModifiedUserInteraction]: Information
  [EnvironmentalMetric.ModifiedScope]: Text
  [EnvironmentalMetric.ModifiedConfidentiality]: Information
  [EnvironmentalMetric.ModifiedIntegrity]: Information
  [EnvironmentalMetric.ModifiedAvailability]: Information
}

interface Text {
  text: string
}

interface Information {
  text: string
  value: number
}

export interface ScopeInformation {
  U: number
  C: number
}

interface ModifiedInformation {
  text: string
  value: ScopeInformation
}

type Environmental = {
  [K in EnvironmentalMetric]: {
    text: string
    metrics: Record<EnvironmentalMetricMap[K], InformationMap[K]>
  }
}

export const environmental: Environmental = {
  [EnvironmentalMetric.ConfidentialityRequirement]: {
    text: 'Confidentiality Requirement',
    metrics: {
      [ConfidentialityRequirement.NotDefined]: {
        text: 'Not Defined',
        value: 1,
      },
      [ConfidentialityRequirement.Low]: { text: 'Low', value: 0.5 },
      [ConfidentialityRequirement.Medium]: { text: 'Medium', value: 1 },
      [ConfidentialityRequirement.High]: { text: 'High', value: 1.5 },
    },
  },
  [EnvironmentalMetric.IntegrityRequirement]: {
    text: 'Integrity Requirement',
    metrics: {
      [IntegrityRequirement.NotDefined]: { text: 'Not Defined', value: 1 },
      [IntegrityRequirement.Low]: { text: 'Low', value: 0.5 },
      [IntegrityRequirement.Medium]: { text: 'Medium', value: 1 },
      [IntegrityRequirement.High]: { text: 'High', value: 1.5 },
    },
  },
  [EnvironmentalMetric.AvailabilityRequirement]: {
    text: 'Availability Requirement',
    metrics: {
      [AvailabilityRequirement.NotDefined]: { text: 'Not Defined', value: 1 },
      [AvailabilityRequirement.Low]: { text: 'Low', value: 0.5 },
      [AvailabilityRequirement.Medium]: { text: 'Medium', value: 1 },
      [AvailabilityRequirement.High]: { text: 'High', value: 1.5 },
    },
  },
  [EnvironmentalMetric.ModifiedAttackVector]: {
    text: 'Modified Attack Vector',
    metrics: {
      [ModifiedAttackVector.NotDefined]: { text: 'Not Defined', value: 1 },
      [ModifiedAttackVector.Network]: { text: 'Network', value: 0.85 },
      [ModifiedAttackVector.Adjacent]: { text: 'Adjacent', value: 0.62 },
      [ModifiedAttackVector.Local]: { text: 'Local', value: 0.55 },
      [ModifiedAttackVector.Physical]: { text: 'Physical', value: 0.2 },
    },
  },
  [EnvironmentalMetric.ModifiedAttackComplexity]: {
    text: 'Modified Attack Complexity',
    metrics: {
      [ModifiedAttackComplexity.NotDefined]: { text: 'Not Defined', value: 1 },
      [ModifiedAttackComplexity.Low]: { text: 'Low', value: 0.77 },
      [ModifiedAttackComplexity.High]: { text: 'High', value: 0.44 },
    },
  },
  [EnvironmentalMetric.ModifiedPrivilegesRequired]: {
    text: 'Modified Privileges Required',
    metrics: {
      [ModifiedPrivilegesRequired.NotDefined]: {
        text: 'Not Defined',
        value: { C: 1, U: 1 },
      },
      [ModifiedPrivilegesRequired.None]: {
        text: 'None',
        value: { C: 0.85, U: 0.85 },
      },
      [ModifiedPrivilegesRequired.Low]: {
        text: 'Low',
        value: { C: 0.68, U: 0.62 },
      },
      [ModifiedPrivilegesRequired.High]: {
        text: 'High',
        value: { C: 0.5, U: 0.27 },
      },
    },
  },
  [EnvironmentalMetric.ModifiedUserInteraction]: {
    text: 'Modified User Interaction',
    metrics: {
      [ModifiedUserInteraction.NotDefined]: { text: 'Not Defined', value: 1 },
      [ModifiedUserInteraction.None]: { text: 'None', value: 0.85 },
      [ModifiedUserInteraction.Required]: { text: 'Required', value: 0.62 },
    },
  },
  [EnvironmentalMetric.ModifiedScope]: {
    text: 'Modified Scope',
    metrics: {
      [ModifiedScope.NotDefined]: { text: 'Not Defined' },
      [ModifiedScope.Unchanged]: { text: 'Unchanged' },
      [ModifiedScope.Changed]: { text: 'Changed' },
    },
  },
  [EnvironmentalMetric.ModifiedConfidentiality]: {
    text: 'Modified Confidentiality',
    metrics: {
      [ModifiedConfidentiality.NotDefined]: { text: 'Not Defined', value: 1 },
      [ModifiedConfidentiality.None]: { text: 'None', value: 0 },
      [ModifiedConfidentiality.Low]: { text: 'Low', value: 0.22 },
      [ModifiedConfidentiality.High]: { text: 'High', value: 0.56 },
    },
  },
  [EnvironmentalMetric.ModifiedIntegrity]: {
    text: 'Modified Integrity',
    metrics: {
      [ModifiedIntegrity.NotDefined]: { text: 'Not Defined', value: 1 },
      [ModifiedIntegrity.None]: { text: 'None', value: 0 },
      [ModifiedIntegrity.Low]: { text: 'Low', value: 0.22 },
      [ModifiedIntegrity.High]: { text: 'High', value: 0.56 },
    },
  },
  [EnvironmentalMetric.ModifiedAvailability]: {
    text: 'Modified Availability',
    metrics: {
      [ModifiedAvailability.NotDefined]: { text: 'Not Defined', value: 1 },
      [ModifiedAvailability.None]: { text: 'None', value: 0 },
      [ModifiedAvailability.Low]: { text: 'Low', value: 0.22 },
      [ModifiedAvailability.High]: { text: 'High', value: 0.56 },
    },
  },
}
