import { createContext, FunctionComponent, ReactNode } from 'react'

interface ConfigContextInterface {
  api: string
  cache: string
}

export const ConfigContext = createContext<ConfigContextInterface>({
  api: '',
  cache: '',
})

interface Props {
  children: ReactNode
}

const api = process.env['REACT_APP_API']
const cache = process.env['REACT_APP_CACHE']

const ConfigContextProvider: FunctionComponent<Props> = (props: Props) => {
  if (!api) {
    throw new Error('REACT_APP_API missing')
  }

  if (!cache) {
    throw new Error('REACT_APP_CACHE missing')
  }

  const context = {
    api,
    cache,
  }

  return (
    <ConfigContext.Provider value={context}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigContextProvider
