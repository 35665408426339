export enum Severity {
  None = 'None',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Critical = 'Critical',
}

export enum AttackVector {
  Network = 'N',
  Adjacent = 'A',
  Local = 'L',
  Physical = 'P',
}

export const isAttackVector = (s: string): s is AttackVector => {
  return Object.values<string>(AttackVector).includes(s)
}

export enum AttackComplexity {
  Low = 'L',
  High = 'H',
}

export const isAttackComplexity = (s: string): s is AttackComplexity => {
  return Object.values<string>(AttackComplexity).includes(s)
}

export enum PrivilegesRequired {
  None = 'N',
  Low = 'L',
  High = 'H',
}

export const isPrivilegesRequired = (s: string): s is PrivilegesRequired => {
  return Object.values<string>(PrivilegesRequired).includes(s)
}

export enum UserInteraction {
  None = 'N',
  Required = 'R',
}

export const isUserInteraction = (s: string): s is UserInteraction => {
  return Object.values<string>(UserInteraction).includes(s)
}

export enum Scope {
  Unchanged = 'U',
  Changed = 'C',
}

export const isScope = (s: string): s is Scope => {
  return Object.values<string>(Scope).includes(s)
}

export enum Confidentiality {
  High = 'H',
  Low = 'L',
  None = 'N',
}

export const isConfidentiality = (s: string): s is Confidentiality => {
  return Object.values<string>(Confidentiality).includes(s)
}

export enum Integrity {
  High = 'H',
  Low = 'L',
  None = 'N',
}

export const isIntegrity = (s: string): s is Integrity => {
  return Object.values<string>(Integrity).includes(s)
}

export enum Availability {
  High = 'H',
  Low = 'L',
  None = 'N',
}

export const isAvailability = (s: string): s is Availability => {
  return Object.values<string>(Availability).includes(s)
}

export enum BaseMetric {
  AttackVector = 'AV',
  AttackComplexity = 'AC',
  PrivilegesRequired = 'PR',
  UserInteraction = 'UI',
  Scope = 'S',
  Confidentiality = 'C',
  Integrity = 'I',
  Availability = 'A',
}

export const isBaseMetric = (s: string): s is BaseMetric => {
  return Object.values<string>(BaseMetric).includes(s)
}
