import { Fragment, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import CriticalityScoreComponent, {
  parameters,
} from '../Package/CriticalityScoreComponent'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import classNames from 'classnames'

const algorithm =
  'C_{\\textrm{Project}} = \\frac{1}{\\sum_{i}{a_i}} \\sum_{i}{a_i} \\frac{\\log(1+S_i)}{\\log(1+\\max(S_i, T_i))}'

const CriticalityScoreExplanation = () => {
  const element = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (element.current) {
      katex.render(algorithm, element.current, {
        displayMode: false,
        throwOnError: false,
      })
    }
  }, [])

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'Criticality Score' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <div className="card mb-4">
          <div className="card-header">Criticality Score</div>
          <div className="card-body">
            <p className="card-text">
              A project&apos;s{' '}
              <a
                href="https://github.com/ossf/criticality_score"
                className="text-decoration-none"
                target="_blank"
                rel="noreferrer"
              >
                criticality score
              </a>{' '}
              defines the influence and importance of a project. It is a number
              between <strong>0 (least-critical)</strong> and{' '}
              <strong>1 (most-critical)</strong>.
            </p>
            <p className="card-text">
              It is based on the following{' '}
              <a
                href="https://github.com/ossf/criticality_score/blob/main/Quantifying_criticality_algorithm.pdf"
                className="text-decoration-none"
                target="_blank"
                rel="noreferrer"
              >
                algorithm
              </a>{' '}
              by{' '}
              <a
                href="https://github.com/robpike"
                className="text-decoration-none"
                target="_blank"
                rel="noreferrer"
              >
                Rob Pike
              </a>
              :
            </p>
            <div className="mb-3" ref={element}></div>
            <p className="card-text">
              Take a look at the criticality score for the package{' '}
              <Link
                className="text-decoration-none"
                to="/packages?name=@angular/service-worker"
              >
                @angular/service-worker
              </Link>
              .
            </p>
          </div>
        </div>

        <CriticalityScoreComponent name="@angular/service-worker" />

        <div className="card mt-4">
          <div className="card-header">Parameters</div>
          <div className="table-responsive">
            <table className="table table-hover mb-0 align-middle">
              <thead>
                <tr>
                  <th className="ps-3">
                    Parameter (S<sub>i</sub>)
                  </th>
                  <th>
                    Weight (α<sub>i</sub>)
                  </th>
                  <th>
                    Max Threshold (T<sub>i</sub>)
                  </th>
                  <th>Description</th>
                  <th>Reasoning</th>
                </tr>
              </thead>
              <tbody>
                {parameters.map((p, i) => (
                  <tr key={p.key}>
                    <td
                      className={classNames('ps-3', {
                        'border-bottom-0': i === parameters.length - 1,
                      })}
                    >
                      {p.title}
                    </td>
                    <td
                      className={classNames({
                        'border-bottom-0': i === parameters.length - 1,
                      })}
                    >
                      {p.weight}
                    </td>
                    <td
                      className={classNames({
                        'border-bottom-0': i === parameters.length - 1,
                      })}
                    >
                      {p.max}
                    </td>
                    <td
                      className={classNames({
                        'border-bottom-0': i === parameters.length - 1,
                      })}
                    >
                      {p.description}
                    </td>
                    <td
                      className={classNames({
                        'border-bottom-0': i === parameters.length - 1,
                      })}
                    >
                      {p.reasoning}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CriticalityScoreExplanation
