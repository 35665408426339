import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ConfigContext } from './Config'
import { Dependency } from './types'

interface HashResponse {
  packageJson: Map<string, unknown>
  deps: [Dependency]
}

const Deps: React.FunctionComponent = () => {
  const configCtx = useContext(ConfigContext)

  const { hash } = useParams<'hash'>()
  const [data, setData] = useState<HashResponse>()
  const element = useRef<HTMLDivElement>(null)
  const editor = useRef(null)

  const retrieve = async (hash: string | undefined, api: string) => {
    const response = await fetch(`${api}/api/deps/package.json/${hash}`)
    const data = await response.json()
    setData(data)
  }

  useEffect(() => {
    retrieve(hash, configCtx.cache)
  }, [hash, configCtx.cache])

  useEffect(() => {
    /* eslint-disable */
    if (editor.current) {
      // @ts-ignore
      editor.current.setValue(JSON.stringify(data?.packageJson, undefined, 2))
    } else if (element.current && !editor.current) {
      // @ts-ignore
      window.require.config({
        paths: {
          vs: 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.30.1/min/vs',
        },
      })
      // @ts-ignore
      window.require(['vs/editor/editor.main'], function () {
        // @ts-ignore
        const foo = monaco.editor.create(element.current, {
          value: data,
          language: 'json',
          automaticLayout: true,
          minimap: {
            enabled: false,
          },
          scrollbar: {
            // Subtle shadows to the left & top. Defaults to true.
            useShadows: false,
          },
          lineNumbers: 'off',
        })
        editor.current = foo
      })
      /* eslint-enable */
    }
  }, [data])

  return (
    <div>
      <div className="card mb-4">
        <div className="card-header">This is your package.json</div>
        <div className="card-body">
          <div
            style={{
              height: '600px',
            }}
            ref={element}
          ></div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">These are your dependencies</div>
        <ul className="list-group list-group-flush">
          {data?.deps.map((d) => {
            return (
              <li key={d.name + d.version} className="list-group-item d-flex">
                <Link to={`/packages?name=${d.name}&version=${d.version}`}>
                  {d.name}@{d.version}
                </Link>
                <span className="ms-auto">{d.licenseId}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Deps
