import { ArrowDown, ArrowUp, Dash } from './Icons'
import Order, { Direction } from './Order'

interface ArrowProps {
  name: string
  order: Order | null
}

const Arrow = (props: ArrowProps): JSX.Element => {
  if (props.order === null || props.name !== props.order.property) {
    return <Dash width="16px" height="16px" />
  }

  switch (props.order.direction) {
    case Direction.asc:
      return <ArrowUp width="16px" height="16px" />
    case Direction.desc:
      return <ArrowDown width="16px" height="16px" />
  }
}

export default Arrow
