import { ArrowDown, ArrowUp, Dash } from './Icons'
import { SortDirection } from './types'

interface Sort {
  sort: SortDirection
}

const SortComponent = (props: Sort): JSX.Element => {
  if (props.sort === SortDirection.desc) {
    return <ArrowDown width="16px" height="16px" />
  }
  if (props.sort === SortDirection.asc) {
    return <ArrowUp width="16px" height="16px" />
  }
  return <Dash width="16px" height="16px" />
}

export default SortComponent
