import { Offcanvas } from 'bootstrap'
import { useEffect, useRef, Fragment, FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'
import Header from './Header'
import {
  Box,
  CardText,
  Controller,
  FileEarmarkText,
  ListOL,
  PersonBadge,
} from './Icons'

const Navigation: FunctionComponent = function Navigation() {
  const canv = useRef<Offcanvas>()

  const onClick = () => {
    canv.current?.hide()
  }

  useEffect(() => {
    const foo = new Offcanvas(
      document.getElementById('offcanvasExample') as Element
    )
    canv.current = foo
  }, [])

  return (
    <Fragment>
      <Header toggle={true} />

      <div
        className="offcanvas offcanvas-start offcanvas-sbomx"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <nav className="p-3 bg-dark flex-grow-1">
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <NavLink
                to="/demo"
                className="nav-link d-flex align-items-center text-white"
                onClick={onClick}
              >
                <Controller />
                <span className="ms-2">Demo</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/packages"
                className="nav-link d-flex align-items-center text-white"
                onClick={onClick}
              >
                <Box />
                <span className="ms-2">Packages</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/licenses"
                className="nav-link d-flex align-items-center text-white"
                onClick={onClick}
              >
                <FileEarmarkText />
                <span className="ms-2">Licenses</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/maintainers"
                className="nav-link d-flex align-items-center text-white"
                onClick={onClick}
              >
                <PersonBadge />
                <span className="ms-2">Maintainers</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/keywords"
                className="nav-link d-flex align-items-center text-white"
                onClick={onClick}
              >
                <CardText />
                <span className="ms-2">Keywords</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/cwes"
                className="nav-link d-flex align-items-center text-white"
                onClick={onClick}
              >
                <ListOL />
                <span className="ms-2">Weaknesses</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </Fragment>
  )
}

export default Navigation
