export enum TemporalMetric {
  ExploitCodeMaturity = 'E',
  RemediationLevel = 'RL',
  ReportConfidence = 'RC',
}

export const isTemporalMetric = (s: string): s is TemporalMetric => {
  return Object.values<string>(TemporalMetric).includes(s)
}

export enum ExploitCodeMaturity {
  NotDefined = 'X',
  High = 'H',
  Functional = 'F',
  ProofOfConcept = 'P',
  Unproven = 'U',
}

export const isExploitCodeMaturity = (s: string): s is ExploitCodeMaturity => {
  return Object.values<string>(ExploitCodeMaturity).includes(s)
}

export enum RemediationLevel {
  NotDefined = 'X',
  Unavailable = 'U',
  Workaround = 'W',
  TemporaryFix = 'T',
  OfficialFix = 'O',
}

export const isRemediationLevel = (s: string): s is RemediationLevel => {
  return Object.values<string>(RemediationLevel).includes(s)
}

export enum ReportConfidence {
  NotDefined = 'X',
  Confirmed = 'C',
  Reasonable = 'R',
  Unknown = 'U',
}

export const isReportConfidence = (s: string): s is ReportConfidence => {
  return Object.values<string>(ReportConfidence).includes(s)
}

type TemporalMetricMap = {
  [TemporalMetric.ExploitCodeMaturity]: ExploitCodeMaturity
  [TemporalMetric.RemediationLevel]: RemediationLevel
  [TemporalMetric.ReportConfidence]: ReportConfidence
}

interface Information {
  text: string
  value: number
}

type Temporal = {
  [K in TemporalMetric]: {
    text: string
    metrics: Record<TemporalMetricMap[K], Information>
  }
}

export const temporal: Temporal = {
  [TemporalMetric.ExploitCodeMaturity]: {
    text: 'Exploit Code Maturity',
    metrics: {
      [ExploitCodeMaturity.NotDefined]: { text: 'Not Defined', value: 1 },
      [ExploitCodeMaturity.High]: { text: 'High', value: 1 },
      [ExploitCodeMaturity.Functional]: { text: 'Functional', value: 0.97 },
      [ExploitCodeMaturity.ProofOfConcept]: {
        text: 'Proof-of-Concept',
        value: 0.94,
      },
      [ExploitCodeMaturity.Unproven]: { text: 'Unproven', value: 0.91 },
    },
  },
  [TemporalMetric.RemediationLevel]: {
    text: 'Remediation Level',
    metrics: {
      [RemediationLevel.NotDefined]: { text: 'Not Defined', value: 1 },
      [RemediationLevel.Unavailable]: { text: 'Unavailable', value: 1 },
      [RemediationLevel.Workaround]: { text: 'Workaround', value: 0.97 },
      [RemediationLevel.TemporaryFix]: { text: 'Temporary Fix', value: 0.96 },
      [RemediationLevel.OfficialFix]: { text: 'Official Fix', value: 0.95 },
    },
  },
  [TemporalMetric.ReportConfidence]: {
    text: 'Report Confidence',
    metrics: {
      [ReportConfidence.NotDefined]: { text: 'Not Defined', value: 1 },
      [ReportConfidence.Confirmed]: { text: 'Confirmed', value: 1 },
      [ReportConfidence.Reasonable]: { text: 'Reasonable', value: 0.96 },
      [ReportConfidence.Unknown]: { text: 'Unknown Fix', value: 0.92 },
    },
  },
}
