import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ConfigContext } from '../Config'
import { MaintainerRecord } from '../types'
import ListGroupPlaceholder from './ListGroupPlaceholder'
import { useMapLocationToApi } from './utils'

const Maintainers: React.FunctionComponent = function Maintainers() {
  const configCtx = useContext(ConfigContext)
  const [loading, setLoading] = useState(true)
  const [maintainers, setMaintainers] = useState<MaintainerRecord[]>([])

  const query = useMapLocationToApi()

  const retrieve = async (query: string, api: string) => {
    const response = await fetch(`${api}/api/versions/maintainers?${query}`)
    const data = await response.json()
    setMaintainers(data)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(query, configCtx.cache)
  }, [query, configCtx.cache])

  return (
    <div className="card mb-4 placeholder-glow">
      <div className="card-header">Maintainers</div>

      {loading ? (
        <ListGroupPlaceholder cols={[2, 3, 2, 1, 2]} includeUl={true} />
      ) : (
        <ul className="list-group list-group-flush">
          {maintainers.map((m) => {
            return (
              <li key={m.name} className="list-group-item">
                <Link
                  className="text-decoration-none"
                  to={`/maintainers?name=${m.name}`}
                >
                  {m.name}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Maintainers
