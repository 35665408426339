import classNames from 'classnames'
import { Fragment } from 'react'
import Breadcrumb from '../Breadcrumb'
import { useScrollToTop } from '../Package/utils'

const SecurityScorecards = () => {
  useScrollToTop()

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'Security Scorecards' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <div className="card mb-4">
          <div className="card-header">Security Scorecards</div>
          <div className="card-body">
            <h6>What is Scorecards?</h6>
            <p className="card-text">
              <a
                href="https://github.com/ossf/scorecard"
                className="text-decoration-none"
                target="_blank"
                rel="noreferrer"
              >
                Scorecards
              </a>{' '}
              is an automated tool that assesses a number of important
              heuristics (&quot;checks&quot;) associated with software security
              and assigns each check a score of 0-10.
            </p>
            <p className="card-text">
              You can use these scores to understand specific areas to improve
              in order to strengthen the security posture of your project. You
              can also assess the risks that dependencies introduce, and make
              informed decisions about accepting these risks, evaluating
              alternative solutions, or working with the maintainers to make
              improvements.
            </p>
            <h6>Project Goals</h6>
            <ol>
              <li>
                Automate analysis and trust decisions on the security posture of
                open source projects.
              </li>
              <li>
                Use this data to proactively improve the security posture of the
                critical projects the world depends on.
              </li>
            </ol>
          </div>
        </div>

        <Checks />
      </div>
    </Fragment>
  )
}

type RiskLevel = 'High' | 'Low' | 'Critical' | 'Medium'

interface Check {
  key: string
  name: string
  description: string
  riskLevel: RiskLevel
}

const checkList: Check[] = [
  {
    key: 'binaryArtifacts',
    name: 'Binary Artifacts',
    description: 'Is the project free of checked-in binaries?',
    riskLevel: 'High',
  },
  {
    key: 'branchProtection',
    name: 'Branch Protection',
    description: 'Does the project use Branch Protection?',
    riskLevel: 'High',
  },
  {
    key: 'ciTests',
    name: 'CI Tests',
    description: 'Does the project run tests in CI, e.g. GitHub Actions, Prow?',
    riskLevel: 'Low',
  },
  {
    key: 'ciiBestPractices',
    name: 'CII Best Practices',
    description: 'Does the project have a CII Best Practices Badge?',
    riskLevel: 'Low',
  },
  {
    key: 'codeReview',
    name: 'Code Review',
    description: 'Does the project require code review before code is merged?',
    riskLevel: 'High',
  },
  {
    key: 'contributors',
    name: 'Contributors',
    description:
      'Does the project have contributors from at least two different organizations?',
    riskLevel: 'Low',
  },
  {
    key: 'dangerousWorkflow',
    name: 'Dangerous Workflow',
    description:
      '	Does the project avoid dangerous coding patterns in GitHub Action workflows?',
    riskLevel: 'Critical',
  },
  {
    key: 'dependencyUpdateTool',
    name: 'Dependency Update Tool',
    description: 'Does the project use tools to help update its dependencies?',
    riskLevel: 'High',
  },
  {
    key: 'fuzzing',
    name: 'Fuzzing',
    description: 'Does the project use fuzzing tools, e.g. OSS-Fuzz?',
    riskLevel: 'Medium',
  },
  {
    key: 'license',
    name: 'License',
    description: 'Does the project declare a license?',
    riskLevel: 'Low',
  },
  {
    key: 'maintained',
    name: 'Maintained',
    description: 'Is the project maintained?',
    riskLevel: 'High',
  },
  {
    key: 'pinnedDependencies',
    name: 'Pinned Dependencies',
    description: 'Does the project declare and pin dependencies?',
    riskLevel: 'Medium',
  },
  {
    key: 'packaging',
    name: 'Packaging',
    description:
      'Does the project build and publish official packages from CI/CD, e.g. GitHub Publishing?',
    riskLevel: 'Medium',
  },
  {
    key: 'sast',
    name: 'SAST',
    description:
      'Does the project use static code analysis tools, e.g. CodeQL, LGTM, SonarCloud?',
    riskLevel: 'Medium',
  },
  {
    key: 'securityPolicy',
    name: 'Security Policy',
    description: 'Does the project contain a security policy?',
    riskLevel: 'Medium',
  },
  {
    key: 'signedReleases',
    name: 'Signed Releases',
    description: 'Does the project cryptographically sign releases?',
    riskLevel: 'High',
  },
  {
    key: 'tokenPermissions',
    name: 'Token Permissions',
    description:
      'Does the project declare GitHub workflow tokens as read only?',
    riskLevel: 'High',
  },
  {
    key: 'vulnerabilities',
    name: 'Vulnerabilities',
    description:
      'Does the project have unfixed vulnerabilities? Uses the OSV service.',
    riskLevel: 'High',
  },
]

const Checks = () => {
  return (
    <div className="card">
      <div className="card-header">Scorecard Checks</div>
      <div className="table-responsive">
        <table className="table table-hover mb-0 align-middle">
          <thead>
            <tr>
              <th className="ps-3">Name</th>
              <th>Description</th>
              <th>Risk Level</th>
            </tr>
          </thead>
          <tbody>
            {checkList.map((c, i) => (
              <tr key={c.key}>
                <td
                  className={classNames('ps-3', {
                    'border-bottom-0': i === checkList.length - 1,
                  })}
                >
                  {c.name}
                </td>
                <td
                  className={classNames({
                    'border-bottom-0': i === checkList.length - 1,
                  })}
                >
                  {c.description}
                </td>
                <td
                  className={classNames({
                    'border-bottom-0': i === checkList.length - 1,
                  })}
                >
                  {c.riskLevel}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SecurityScorecards
