import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Header'
import Examples from './Examples'
import Flow from './Flow'
import Numbers from './Numbers'
import Security from './Security'
import Standards from './Standards'

const Landing: React.FunctionComponent = function Landing() {
  return (
    <React.Fragment>
      <Header toggle={false} />
      <Flow />
      <div className="text-center mb-5">
        <Link to="/demo" className="btn btn-primary btn-lg px-5">
          Try the demo
        </Link>
      </div>
      <Examples />
      <Standards />
      <Security />
      <Numbers />
    </React.Fragment>
  )
}

export default Landing
