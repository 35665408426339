import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link, useParams } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { ConfigContext } from '../Config'
import { LICENSE_ID } from '../Package/PackageListComponent'
import { LicenseCount } from '../types'

const formatter = new Intl.NumberFormat()

const LicenseComponent = (): JSX.Element => {
  const configCtx = useContext(ConfigContext)

  const { licenseId } = useParams<'licenseId'>()
  const [license, setLicense] = useState<LicenseCount>()
  const [loading, setLoading] = useState(true)

  const retrieve = async (licenseId: string | undefined, api: string) => {
    const response = await fetch(`${api}/api/licenses/${licenseId}`)
    const data = await response.json()
    setLicense(data)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(licenseId, configCtx.cache)
  }, [licenseId, configCtx.cache])

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: '/licenses', text: 'Licenses' },
          { href: null, text: license?.name ?? '' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <div className="card mb-4">
          <div className="card-header">Number of packages</div>
          <div className="card-body">
            We found{' '}
            <Link
              to={`/packages?${LICENSE_ID}=${licenseId}`}
              className="text-decoration-none"
            >
              {formatter.format(license?.count ?? 0)}
            </Link>{' '}
            packages for the {license?.name} license.
          </div>
        </div>
        <div className="card">
          <div className="card-header placeholder-glow">
            {license?.name ? (
              <span>{license?.name}</span>
            ) : (
              <span className="placeholder col-3" />
            )}
          </div>
          <div className="card-body">
            {loading ? (
              <LicensePlaceholder />
            ) : (
              <Fragment>
                <h5>Full name</h5>
                <p>{license?.name}</p>
                <h5>Short identifier</h5>
                <p>{license?.licenseId}</p>
                <h5>Text</h5>
                <p
                  style={{
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {license?.licenseText}
                </p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const LicensePlaceholder: FunctionComponent = () => {
  return (
    <div className="placeholder-glow">
      <h5>
        <span className="placeholder col-2" />
      </h5>
      <p>
        <span className="placeholder col-3" />
      </p>
      <h5>
        <span className="placeholder col-2" />
      </h5>
      <p>
        <span className="placeholder col-1" />
      </p>
      <h5>
        <span className="placeholder col-1" />
      </h5>
      <p>
        <span className="placeholder col-4" />
        <span className="placeholder col-9" />
        <span className="placeholder col-6" />
        <span className="placeholder col-8" />
      </p>
    </div>
  )
}

export default LicenseComponent
