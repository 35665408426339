import { max } from 'd3-array'
import { Axis, axisBottom, axisLeft } from 'd3-axis'
import { format } from 'd3-format'
import {
  NumberValue,
  ScaleBand,
  scaleBand,
  ScaleLinear,
  scaleLinear,
} from 'd3-scale'
import { select, Selection } from 'd3-selection'
import { calc, calcHeight } from './utils'

interface Margin {
  left: number
  right: number
  top: number
  bottom: number
}

interface Properties {
  margin: Margin
  target: SVGSVGElement
  yLabel: string
}

interface Datum {
  name: string
  value: number
}

const colorSkeleton = '#919294'

export default class Plot {
  private config: Properties
  private w: number
  private h: number
  private chart: Selection<SVGGElement, unknown, null, undefined>
  private xScale: ScaleBand<string>
  private xAxis: Axis<string>
  private xAxisGroup: Selection<SVGGElement, unknown, null, undefined>
  private yScale: ScaleLinear<number, number, never>
  private yAxis: Axis<NumberValue>
  private yAxisGroup: Selection<SVGGElement, unknown, null, undefined>
  private columns: Selection<SVGRectElement, Datum, SVGGElement, unknown>

  public constructor(config: Properties) {
    this.config = config

    const width = calc(config.target.parentNode as HTMLElement)
    const height = calcHeight(config.target.parentNode as HTMLElement)

    this.w = width - config.margin.left - config.margin.right
    this.h = height - config.margin.top - config.margin.bottom

    this.chart = select(config.target)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr(
        'transform',
        `translate(${config.margin.left}, ${config.margin.top})`
      )

    const data: Datum[] = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
    ].map((i) => ({ name: i, value: Math.random() }))

    this.xScale = scaleBand()
      .domain(data.map((d) => d.name))
      .range([0, this.w])
      .padding(0.5)

    const highest = max(data, (d) => d.value)
    this.yScale = scaleLinear()
      .domain([0, highest as number])
      .range([this.h, 0])

    this.xAxis = axisBottom(this.xScale).tickValues([])

    this.yAxis = axisLeft(this.yScale).tickValues([])

    // add x axis at the bottom
    this.xAxisGroup = this.chart
      .append('g')
      .style('color', colorSkeleton)
      .attr('transform', `translate(0, ${this.h})`)
      .call(this.xAxis)

    // add y axis at the left
    this.yAxisGroup = this.chart
      .append('g')
      .style('color', colorSkeleton)
      .call(this.yAxis)
      .call((g) =>
        g
          .append('text')
          .attr('x', -this.config.margin.left)
          .attr('y', -this.config.margin.top + 10)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'start')
          .text(this.config.yLabel)
      )

    // columns
    this.columns = this.chart
      .append('g')
      .selectAll<SVGRectElement, Datum>('rect')
      .data(data, (d) => d.name)
      .join('rect')
      .attr('fill', colorSkeleton)
      .attr('x', (d) => this.xScale(d.name) as number)
      .attr('y', (d) => this.yScale(d.value))
      .attr('width', this.xScale.bandwidth())
      .attr('height', (d) => this.h - this.yScale(d.value))
  }

  /**
   * Resize the plot.
   * We have to update all SVG elements that depend on the width and height, e.g. svg, axis, and the rects.
   */
  public resize(): void {
    // calculate new svg width
    const width = calc(this.config.target.parentNode as HTMLElement)
    const height = calcHeight(this.config.target.parentNode as HTMLElement)

    // calculate new plot size
    this.w = width - this.config.margin.left - this.config.margin.right
    this.h = height - this.config.margin.top - this.config.margin.bottom

    // svg element
    select(this.config.target).attr('width', width).attr('height', height)

    // x scale
    this.xScale.range([0, this.w])

    // y scale
    this.yScale.range([this.h, 0])

    // x axis
    this.xAxisGroup
      .attr('transform', `translate(0, ${this.h})`)
      .call(this.xAxis)

    // y axis
    this.yAxisGroup.call(this.yAxis)

    // rects
    this.columns
      .attr('x', (d) => this.xScale(d.name) as number)
      .attr('y', (d) => this.yScale(d.value))
      .attr('width', this.xScale.bandwidth())
      .attr('height', (d) => this.h - this.yScale(d.value))
  }

  // update the chart with real data
  public update(values: Map<string, number>, color: string): void {
    const data = Object.entries(values)
      .map(([name, value]) => ({
        name,
        value,
      }))
      // sort to show the most important maintainers
      .sort((a, b) => b.value - a.value)
      // show the top 25 maintainers
      // otherwise the visualization would break
      // todo: add drag, pan, zoom later
      .slice(0, 25)

    // x scale
    this.xScale.domain(data.map((d) => d.name))

    // y scale
    const highest = max(data, (d) => d.value)
    this.yScale.domain([0, highest as number])

    // x axis
    this.xAxis = axisBottom(this.xScale)

    // y axis
    const yAxisTicks = this.yScale.ticks().filter(Number.isInteger)
    this.yAxis.tickValues(yAxisTicks).tickFormat(format('d'))

    // x axis group
    this.xAxisGroup
      // reset the color
      .style('color', null)
      .call(this.xAxis)

    // rotate labels so that they do not overlap
    this.xAxisGroup
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('transform', 'rotate(-45)')

    // y axis group
    this.yAxisGroup.style('color', null).call(this.yAxis)

    // columns
    this.columns = this.columns
      .data(data, (d) => d.name)
      .join('rect')
      .attr('fill', color)
      .attr('x', (d) => this.xScale(d.name) as number)
      .attr('y', (d) => this.yScale(d.value))
      .attr('width', this.xScale.bandwidth())
      .attr('height', (d) => this.h - this.yScale(d.value))
  }
}
