import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import styles from './flow.module.scss'

interface Position {
  x: number
  y: number
  width: number
  height: number
}

const Flow = () => {
  const pomXml = useRef<HTMLDivElement>(null)
  const yarnLock = useRef<HTMLDivElement>(null)
  const packageLockJson = useRef<HTMLDivElement>(null)
  const dockerfile = useRef<HTMLDivElement>(null)
  const buildGradle = useRef<HTMLDivElement>(null)
  const sbomx = useRef<HTMLDivElement>(null)
  const goMod = useRef<HTMLDivElement>(null)

  const csv = useRef<HTMLDivElement>(null)
  const excel = useRef<HTMLDivElement>(null)
  const cycloneDx = useRef<HTMLDivElement>(null)
  const spdx = useRef<HTMLDivElement>(null)
  const swid = useRef<HTMLDivElement>(null)

  const packageManager = useRef<HTMLDivElement>(null)
  const vulnerabilities = useRef<HTMLDivElement>(null)
  const advisories = useRef<HTMLDivElement>(null)

  const [positionPomXml, setPositionPomXml] = useState<Position>()
  const [positionYarnLock, setPositionYarnLock] = useState<Position>()
  const [positionPackageLockJson, setPositionPackageLockJson] =
    useState<Position>()
  const [positionDockerfile, setPositionDockerfile] = useState<Position>()
  const [positionBuildGradle, setPositionBuildGradle] = useState<Position>()
  const [positionGoMod, setPositionGoMod] = useState<Position>()
  const [positionCsv, setPositionCsv] = useState<Position>()
  const [positionExcel, setPositionExcel] = useState<Position>()
  const [positionCycloneDx, setPositionCycloneDx] = useState<Position>()
  const [positionSpdx, setPositionSpdx] = useState<Position>()
  const [positionSwid, setPositionSwid] = useState<Position>()

  const [positionPackageManager, setPositionPackageManager] =
    useState<Position>()
  const [positionVulnerabilities, setPositionVulnerabilities] =
    useState<Position>()
  const [positionAdvisories, setPositionAdvisories] = useState<Position>()

  const [positionSbomx, setPositionSbomx] = useState<Position>()

  const draw = () => {
    if (
      pomXml.current &&
      yarnLock.current &&
      sbomx.current &&
      packageLockJson.current &&
      dockerfile.current &&
      buildGradle.current &&
      goMod.current &&
      csv.current &&
      excel.current &&
      cycloneDx.current &&
      spdx.current &&
      swid.current &&
      packageManager.current &&
      vulnerabilities.current &&
      advisories.current
    ) {
      setPositionPomXml({
        x: pomXml.current.getBoundingClientRect().width,
        y: pomXml.current.getBoundingClientRect().height / 2,
        width: pomXml.current.getBoundingClientRect().width,
        height: pomXml.current.getBoundingClientRect().height,
      })
      setPositionSbomx({
        x: sbomx.current.offsetLeft,
        y:
          sbomx.current.offsetTop +
          sbomx.current.getBoundingClientRect().height / 2,
        width: sbomx.current.getBoundingClientRect().width,
        height: sbomx.current.getBoundingClientRect().height,
      })
      setPositionYarnLock({
        x: yarnLock.current.getBoundingClientRect().width,
        y:
          yarnLock.current.offsetTop +
          yarnLock.current.getBoundingClientRect().height / 2,
        width: yarnLock.current.getBoundingClientRect().width,
        height: yarnLock.current.getBoundingClientRect().height,
      })
      setPositionDockerfile({
        x: dockerfile.current.getBoundingClientRect().width,
        y:
          dockerfile.current.offsetTop +
          dockerfile.current.getBoundingClientRect().height / 2,
        width: dockerfile.current.getBoundingClientRect().width,
        height: dockerfile.current.getBoundingClientRect().height,
      })
      setPositionBuildGradle({
        x: buildGradle.current.getBoundingClientRect().width,
        y:
          buildGradle.current.offsetTop +
          buildGradle.current.getBoundingClientRect().height / 2,
        width: buildGradle.current.getBoundingClientRect().width,
        height: buildGradle.current.getBoundingClientRect().height,
      })
      setPositionPackageLockJson({
        x: packageLockJson.current.getBoundingClientRect().width,
        y:
          packageLockJson.current.offsetTop +
          packageLockJson.current.getBoundingClientRect().height / 2,
        width: packageLockJson.current.getBoundingClientRect().width,
        height: packageLockJson.current.getBoundingClientRect().height,
      })
      setPositionGoMod({
        x: goMod.current.getBoundingClientRect().width,
        y:
          goMod.current.offsetTop +
          goMod.current.getBoundingClientRect().height / 2,
        width: goMod.current.getBoundingClientRect().width,
        height: goMod.current.getBoundingClientRect().height,
      })

      setPositionCsv({
        x: csv.current.offsetLeft,
        y:
          csv.current.offsetTop +
          csv.current.getBoundingClientRect().height / 2,
        width: csv.current.getBoundingClientRect().width,
        height: csv.current.getBoundingClientRect().height,
      })

      setPositionExcel({
        x: excel.current.offsetLeft,
        y:
          excel.current.offsetTop +
          excel.current.getBoundingClientRect().height / 2,
        width: excel.current.getBoundingClientRect().width,
        height: excel.current.getBoundingClientRect().height,
      })

      setPositionCycloneDx({
        x: cycloneDx.current.offsetLeft,
        y:
          cycloneDx.current.offsetTop +
          cycloneDx.current.getBoundingClientRect().height / 2,
        width: cycloneDx.current.getBoundingClientRect().width,
        height: cycloneDx.current.getBoundingClientRect().height,
      })
      setPositionSpdx({
        x: spdx.current.offsetLeft,
        y:
          spdx.current.offsetTop +
          spdx.current.getBoundingClientRect().height / 2,
        width: spdx.current.getBoundingClientRect().width,
        height: spdx.current.getBoundingClientRect().height,
      })
      setPositionSwid({
        x: swid.current.offsetLeft,
        y:
          swid.current.offsetTop +
          swid.current.getBoundingClientRect().height / 2,
        width: swid.current.getBoundingClientRect().width,
        height: swid.current.getBoundingClientRect().height,
      })

      setPositionPackageManager({
        x:
          packageManager.current.offsetLeft +
          packageManager.current.getBoundingClientRect().width / 2,
        y: packageManager.current.offsetTop,
        width: packageManager.current.getBoundingClientRect().width,
        height: packageManager.current.getBoundingClientRect().height,
      })

      setPositionVulnerabilities({
        x:
          vulnerabilities.current.offsetLeft +
          vulnerabilities.current.getBoundingClientRect().width / 2,
        y: vulnerabilities.current.offsetTop,
        width: vulnerabilities.current.getBoundingClientRect().width,
        height: vulnerabilities.current.getBoundingClientRect().height,
      })

      setPositionAdvisories({
        x:
          advisories.current.offsetLeft +
          advisories.current.getBoundingClientRect().width / 2,
        y: advisories.current.offsetTop,
        width: advisories.current.getBoundingClientRect().width,
        height: advisories.current.getBoundingClientRect().height,
      })
    }
  }

  useEffect((): (() => void) => {
    window.addEventListener('resize', draw)
    return (): void => {
      window.removeEventListener('resize', draw)
    }
  }, [])

  useEffect(() => {
    draw()
  }, [])

  return (
    <div className={styles.background}>
      <div className={classNames(styles['small-wrapper'], 'd-md-none')}>
        <svg className={styles.svg}>
          <line
            className={classNames(styles.animation, styles.blue)}
            x1="135"
            y1="0"
            x2="135"
            y2="225"
            strokeWidth="3"
          />
          <line
            className={classNames(styles.animation, styles.lila)}
            x1="135"
            y1="225"
            x2="135"
            y2="450"
            strokeWidth="3"
          />
        </svg>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 0,
            top: 0,
          }}
        >
          pom.xml
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 10,
            top: 10,
          }}
        >
          yarn.lock
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 20,
            top: 20,
          }}
        >
          package-lock.json
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 30,
            top: 30,
          }}
        >
          Dockerfile
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 40,
            top: 40,
          }}
        >
          build.gradle
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 50,
            top: 50,
          }}
        >
          Package Information
        </div>
        <div className={styles['small-sbomx']}>sbomx</div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 10,
            bottom: 40,
          }}
        >
          CVS
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 20,
            bottom: 30,
          }}
        >
          Excel
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 30,
            bottom: 20,
          }}
        >
          CycloneDX
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 40,
            bottom: 10,
          }}
        >
          SPDX
        </div>
        <div
          className={classNames(styles['small-box'])}
          style={{
            right: 50,
            bottom: 0,
          }}
        >
          Software Bill of Materials
        </div>
      </div>
      <div className={classNames(styles.wrapper, 'd-none', 'd-md-grid')}>
        <Chart
          positionPomXml={positionPomXml}
          positionSbomx={positionSbomx}
          positionYarnLock={positionYarnLock}
          positionPackageLockJson={positionPackageLockJson}
          positionDockerfile={positionDockerfile}
          positionBuildGradle={positionBuildGradle}
          positionGoMod={positionGoMod}
          positionCsv={positionCsv}
          positionExcel={positionExcel}
          positionCycloneDx={positionCycloneDx}
          positionSpdx={positionSpdx}
          positionSwid={positionSwid}
          positionPackageManager={positionPackageManager}
          positionVulnerabilities={positionVulnerabilities}
          positionAdvisories={positionAdvisories}
        />
        <div
          ref={pomXml}
          className={classNames(styles.box, styles['pom-xml'], styles.right)}
        >
          pom.xml
        </div>
        <div
          ref={yarnLock}
          className={classNames(styles.box, styles['yarn-lock'], styles.right)}
        >
          yarn.lock
        </div>
        <div
          ref={packageLockJson}
          className={classNames(
            styles.box,
            styles['package-lock-json'],
            styles.right
          )}
        >
          package-lock.json
        </div>
        <div
          ref={dockerfile}
          className={classNames(styles.box, styles.dockerfile, styles.right)}
        >
          Dockerfile
        </div>
        <div
          ref={buildGradle}
          className={classNames(
            styles.box,
            styles['build-gradle'],
            styles.right
          )}
        >
          build.gradle
        </div>
        <div
          ref={goMod}
          className={classNames(styles.box, styles['go-mod'], styles.right)}
        >
          go.mod
        </div>
        <div ref={sbomx} className={classNames(styles.box, styles.sbomx)}>
          <span>sbomx</span>
          <span className={styles.small}>Software Bill of Materials</span>
        </div>

        <div
          ref={csv}
          className={classNames(styles.box, styles.csv, styles.left)}
        >
          CSV
        </div>

        <div
          ref={excel}
          className={classNames(styles.box, styles.excel, styles.left)}
        >
          Excel
        </div>

        <div
          ref={cycloneDx}
          className={classNames(styles.box, styles['cyclone-dx'], styles.left)}
        >
          CycloneDX
        </div>

        <div
          ref={spdx}
          className={classNames(styles.box, styles.spdx, styles.left)}
        >
          SPDX
        </div>

        <div
          ref={swid}
          className={classNames(styles.box, styles.swid, styles.left)}
        >
          SWID
        </div>

        <div
          ref={packageManager}
          className={classNames(
            styles.box,
            styles['package-manager'],
            styles.bottom
          )}
        >
          Package Manager
        </div>
        <div
          ref={vulnerabilities}
          className={classNames(
            styles.box,
            styles.vulnerabilities,
            styles.bottom
          )}
        >
          Vulnerabilities
        </div>
        <div
          ref={advisories}
          className={classNames(styles.box, styles.advisories, styles.bottom)}
        >
          Advisories
        </div>
      </div>
    </div>
  )
}

interface SvgProps {
  positionPomXml: Position | undefined
  positionSbomx: Position | undefined
  positionYarnLock: Position | undefined
  positionPackageLockJson: Position | undefined
  positionDockerfile: Position | undefined
  positionBuildGradle: Position | undefined
  positionGoMod: Position | undefined
  positionCsv: Position | undefined
  positionExcel: Position | undefined
  positionCycloneDx: Position | undefined
  positionSpdx: Position | undefined
  positionSwid: Position | undefined
  positionPackageManager: Position | undefined
  positionVulnerabilities: Position | undefined
  positionAdvisories: Position | undefined
}

const Chart = (props: SvgProps) => {
  const {
    positionPomXml,
    positionSbomx,
    positionYarnLock,
    positionPackageLockJson,
    positionDockerfile,
    positionBuildGradle,
    positionGoMod,
    positionCsv,
    positionExcel,
    positionCycloneDx,
    positionSpdx,
    positionSwid,
    positionPackageManager,
    positionVulnerabilities,
    positionAdvisories,
  } = props

  if (
    !(
      positionPomXml &&
      positionSbomx &&
      positionYarnLock &&
      positionPackageLockJson &&
      positionDockerfile &&
      positionBuildGradle &&
      positionGoMod &&
      positionCsv &&
      positionExcel &&
      positionCycloneDx &&
      positionSpdx &&
      positionSwid &&
      positionPackageManager &&
      positionVulnerabilities &&
      positionAdvisories
    )
  ) {
    return null
  }

  return (
    <svg className={classNames(styles.svg, 'd-none', 'd-md-block')}>
      <path
        className={classNames(styles.animation, styles.blue)}
        d={`
            M${positionPomXml.x},${positionPomXml.y}
            C${positionPomXml.x + 50},${positionPomXml.y}
            ${positionSbomx.x - 50},${positionSbomx.y}
            ${positionSbomx.x},${positionSbomx.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.blue)}
        d={`
            M${positionYarnLock.x},${positionYarnLock.y}
            C${positionYarnLock.x + 50},${positionYarnLock.y}
            ${positionSbomx.x - 50},${positionSbomx.y}
            ${positionSbomx.x},${positionSbomx.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.blue)}
        d={`
            M${positionPackageLockJson.x},${positionPackageLockJson.y}
            C${positionPackageLockJson.x + 50},${positionPackageLockJson.y}
            ${positionSbomx.x - 50},${positionSbomx.y}
            ${positionSbomx.x},${positionSbomx.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.blue)}
        d={`
            M${positionDockerfile.x},${positionDockerfile.y}
            C${positionDockerfile.x + 50},${positionDockerfile.y}
            ${positionSbomx.x - 50},${positionSbomx.y}
            ${positionSbomx.x},${positionSbomx.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.blue)}
        d={`
            M${positionBuildGradle.x},${positionBuildGradle.y}
            C${positionBuildGradle.x + 50},${positionBuildGradle.y}
            ${positionSbomx.x - 50},${positionSbomx.y}
            ${positionSbomx.x},${positionSbomx.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.blue)}
        d={`
            M${positionGoMod.x},${positionGoMod.y}
            C${positionGoMod.x + 50},${positionGoMod.y}
            ${positionSbomx.x - 50},${positionSbomx.y}
            ${positionSbomx.x},${positionSbomx.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.lila)}
        d={`
            M${positionSbomx.x + positionSbomx.width},${positionSbomx.y}
            C${positionSbomx.x + positionSbomx.width + 50},${positionSbomx.y}
            ${positionCsv.x - 50},${positionCsv.y}
            ${positionCsv.x},${positionCsv.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.lila)}
        d={`
            M${positionSbomx.x + positionSbomx.width},${positionSbomx.y}
            C${positionSbomx.x + positionSbomx.width + 50},${positionSbomx.y}
            ${positionExcel.x - 50},${positionExcel.y}
            ${positionExcel.x},${positionExcel.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.lila)}
        d={`
            M${positionSbomx.x + positionSbomx.width},${positionSbomx.y}
            C${positionSbomx.x + positionSbomx.width + 50},${positionSbomx.y}
            ${positionCycloneDx.x - 50},${positionCycloneDx.y}
            ${positionCycloneDx.x},${positionCycloneDx.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.lila)}
        d={`
            M${positionSbomx.x + positionSbomx.width},${positionSbomx.y}
            C${positionSbomx.x + positionSbomx.width + 50},${positionSbomx.y}
            ${positionSpdx.x - 50},${positionSpdx.y}
            ${positionSpdx.x},${positionSpdx.y}
        `}
        fill="none"
        strokeWidth="3"
      />
      <path
        className={classNames(styles.animation, styles.lila)}
        d={`
            M${positionSbomx.x + positionSbomx.width},${positionSbomx.y}
            C${positionSbomx.x + positionSbomx.width + 50},${positionSbomx.y}
            ${positionSwid.x - 50},${positionSwid.y}
            ${positionSwid.x},${positionSwid.y}
        `}
        fill="none"
        strokeWidth="3"
      />

      <path
        className={classNames(styles.animation, styles.yellow)}
        d={`
            M${positionPackageManager.x},${positionPackageManager.y}
            C${positionPackageManager.x},${positionPackageManager.y - 50}
            ${positionSbomx.x + positionSbomx.width / 2},${
          positionSbomx.y + positionSbomx.height / 2 + 50
        }
            ${positionSbomx.x + positionSbomx.width / 2},${
          positionSbomx.y + positionSbomx.height / 2
        }
        `}
        fill="none"
        strokeWidth="3"
      />

      <path
        className={classNames(styles.animation, styles.yellow)}
        d={`
            M${positionAdvisories.x},${positionAdvisories.y}
            C${positionAdvisories.x},${positionAdvisories.y - 50}
            ${positionSbomx.x + positionSbomx.width / 2},${
          positionSbomx.y + positionSbomx.height / 2 + 50
        }
            ${positionSbomx.x + positionSbomx.width / 2},${
          positionSbomx.y + positionSbomx.height / 2
        }
        `}
        fill="none"
        strokeWidth="3"
      />

      <path
        className={classNames(styles.animation, styles.yellow)}
        d={`
            M${positionVulnerabilities.x},${positionVulnerabilities.y}
            C${positionVulnerabilities.x},${positionVulnerabilities.y - 50}
            ${positionSbomx.x + positionSbomx.width / 2},${
          positionSbomx.y + positionSbomx.height / 2 + 50
        }
            ${positionSbomx.x + positionSbomx.width / 2},${
          positionSbomx.y + positionSbomx.height / 2
        }
        `}
        fill="none"
        strokeWidth="3"
      />
    </svg>
  )
}

export default Flow
