import classNames from 'classnames'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useContext, useEffect, useState } from 'react'
import { ConfigContext } from '../Config'
import { CheckCircle, ExclamationCircle, XCircle } from '../Icons'
import { useScrollToTop } from './utils'
dayjs.extend(relativeTime)

interface ScoreProps {
  name: string | null
}

interface CriticalityScore {
  name: string
  url: string
  language: string
  description: string
  createdSince: number
  updatedSince: number
  contributorCount: number
  watchersCount: number
  orgCount: number
  commitFrequency: number
  recentReleasesCount: number
  updatedIssuesCount: number
  closedIssuesCount: number
  commentFrequency: number
  dependentsCount: number
  criticalityScore: number
  creationTimestamp: string
  updateTimestamp: string
}

const formatter = new Intl.NumberFormat()

export const parameters = [
  {
    key: 'createdSince',
    title: 'Created Since',
    description: 'Time since the project was created (in months).',
    reasoning:
      'Older project has higher chance of being widely used or being dependent upon.',
    weight: 1,
    max: 120,
  },
  {
    key: 'updatedSince',
    title: 'Updated Since',
    description: 'Time since the project was last updated (in months).',
    reasoning:
      'Unmaintained projects with no recent commits have higher chance of being less relied upon.',
    weight: -1,
    max: 120,
  },
  {
    key: 'contributorCount',
    title: 'Contributor Count',
    description: 'Count of project contributors (with commits).',
    reasoning:
      "Different contributors involvement indicates project's importance.",
    weight: 2,
    max: 5000,
  },
  {
    key: 'orgCount',
    title: 'Org Count',
    description: 'Count of distinct organizations that contributors belong to.',
    reasoning: 'Indicates cross-organization dependency.',
    weight: 1,
    max: 10,
  },
  {
    key: 'commitFrequency',
    title: 'Commit Frequency',
    description: 'Average number of commits per week in the last year.',
    reasoning:
      "Higher code churn has slight indication of project's importance. Also, higher susceptibility to vulnerabilities.",
    weight: 1,
    max: 1000,
  },
  {
    key: 'recentReleasesCount',
    title: 'Recent Releases Count',
    description: 'Number of releases in the last year.',
    reasoning:
      'Frequent releases indicates user dependency. Lower weight since this is not always used.',
    weight: 0.5,
    max: 26,
  },
  {
    key: 'closedIssuesCount',
    title: 'Closed Issues Count',
    description: 'Number of issues closed in the last 90 days.',
    reasoning:
      'Indicates high contributor involvement and focus on closing user issues. Lower weight since it is dependent on project contributors.',
    weight: 0.5,
    max: 5000,
  },
  {
    key: 'updatedIssuesCount',
    title: 'Updated Issues Count',
    description: 'Number of issues updated in the last 90 days.',
    reasoning:
      'Indicates high contributor involvement. Lower weight since it is dependent on project contributors.',
    weight: 0.5,
    max: 5000,
  },
  {
    key: 'commentFrequency',
    title: 'Comment Frequency',
    description: 'Average number of comments per issue in the last 90 days.',
    reasoning: 'Indicates high user activity and dependence.',
    weight: 1,
    max: 15,
  },
  {
    key: 'dependentsCount',
    title: 'Dependents Count',
    description: 'Number of project mentions in the commit messages',
    reasoning:
      "Indicates repository use, usually in version rolls. This parameter works across all languages, including C/C++ that don't have package dependency graphs (though hack-ish). Plan to add package dependency trees in the near future.",
    weight: 2,
    max: 500000,
  },
]

const CriticalityScoreComponent: React.FunctionComponent<ScoreProps> =
  function Score(props: ScoreProps) {
    const configCtx = useContext(ConfigContext)
    const [score, setScore] = useState<CriticalityScore>()
    const [status, setStatus] = useState(200)
    const [loading, setLoading] = useState(true)

    const fetchCriticality = async (name: string | null, api: string) => {
      try {
        const search = new URLSearchParams({
          name: name ?? '',
        })
        const response = await fetch(
          `${api}/api/packages/criticality?${search.toString()}`
        )
        setStatus(response.status)
        if (response.ok) {
          const result = await response.json()
          setScore(result)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    useScrollToTop()

    useEffect(() => {
      fetchCriticality(props.name, configCtx.cache)
    }, [props.name, configCtx.cache])

    if (loading) {
      return (
        <div className="card mb-4">
          <div className="card-header">Criticality Score</div>
          <div className="card-body">
            <p className="card-text placeholder-glow">
              <span className="placeholder col-5"></span>
            </p>
          </div>
        </div>
      )
    }

    if (status !== 200) {
      return (
        <div className="card mb-4">
          <div className="card-header">Criticality Score</div>
          <div className="card-body">
            Criticality Score for package <code>{props.name}</code> not
            calculated yet. Check back soon!
          </div>
        </div>
      )
    }

    return (
      <div className="card mb-4">
        <div className="card-header d-flex align-items-center">
          Criticality Score
          <span className="badge bg-secondary ms-2">
            {formatter.format(score?.criticalityScore ?? 0)}
          </span>
        </div>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          {parameters.map((p) => {
            let v = score?.[p.key as keyof CriticalityScore] as number
            // updated since has negative weight
            v = p.weight <= 0 ? Math.abs(v - p.max) : v
            const percentage = (v / p.max) * 100
            const success = percentage >= 66
            const warning = percentage >= 33 && percentage < 66
            const danger = percentage < 33
            return (
              <div className="accordion-item" key={p.key}>
                <h2 className="accordion-header" id={`flush-heading-${p.key}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse-${p.key}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse-${p.key}`}
                  >
                    <span
                      style={{
                        flexBasis: '200px',
                      }}
                    >
                      {p.title}
                    </span>

                    <div
                      className="progress w-25 me-4"
                      style={{
                        height: 8,
                      }}
                    >
                      <div
                        className={classNames('progress-bar', {
                          'bg-success': success,
                          'bg-warning': warning,
                          'bg-danger': danger,
                        })}
                        style={{
                          width: `${percentage}%`,
                        }}
                        role="progressbar"
                        aria-valuenow={percentage}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <small
                      className="d-none d-md-flex text-truncate"
                      style={{
                        flexBasis: '150px',
                      }}
                    >
                      {formatter.format(v)} / {formatter.format(p.max)}
                    </small>
                    <span className="me-4">
                      <Indicator
                        success={success}
                        warning={warning}
                        danger={danger}
                      />
                    </span>
                  </button>
                </h2>
                <div
                  id={`flush-collapse-${p.key}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading-${p.key}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <dl>
                      <dt>Description</dt>
                      <dd>{p.description}</dd>

                      <dt>Reasoning</dt>
                      <dd>{p.reasoning}</dd>

                      <dt>Weight</dt>
                      <dd>{p.weight}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="card-footer text-end small">
          Criticality Score as of {dayjs(score?.creationTimestamp).fromNow()}.
        </div>
      </div>
    )
  }

interface IndicatorProps {
  success: boolean
  warning: boolean
  danger: boolean
}

const Indicator: React.FunctionComponent<IndicatorProps> = function Score(
  props: IndicatorProps
) {
  if (props.danger) {
    return <XCircle />
  }
  if (props.warning) {
    return <ExclamationCircle />
  }
  if (props.success) {
    return <CheckCircle />
  }
  return null
}

export default CriticalityScoreComponent
