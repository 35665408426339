import React from 'react'
import { useEffect, useRef } from 'react'

interface EditorProps {
  value: string | undefined
  language: string
}

// we forward the ref to be able to get the value in our parent component
const Editor = React.forwardRef(function Editor(props: EditorProps, ref) {
  const element = useRef<HTMLDivElement>(null)

  useEffect(() => {
    /* eslint-disable */
    // @ts-ignore
    if (ref.current) {
      // @ts-ignore
      ref.current.setValue(props.value)
      // @ts-ignore
    } else if (element.current && !ref.current) {
      // @ts-ignore
      window.require.config({
        paths: {
          vs: 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.30.1/min/vs',
        },
      })
      // @ts-ignore
      window.require(['vs/editor/editor.main'], function () {
        // @ts-ignore
        ref.current = monaco.editor.create(element.current, {
          value: props.value,
          language: props.language,
          automaticLayout: true,
          minimap: {
            enabled: false,
          },
          scrollbar: {
            // Subtle shadows to the left & top. Defaults to true.
            useShadows: false,
          },
          lineNumbers: 'off',
        })
      })
    }
    /* eslint-enable */
  }, [props.value, props.language])

  return (
    <div
      style={{
        height: '400px',
      }}
      ref={element}
    ></div>
  )
})

export default Editor
