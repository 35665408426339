import {
  AttackVector,
  AttackComplexity,
  PrivilegesRequired,
  UserInteraction,
  Scope,
  Confidentiality,
  Integrity,
  Availability,
  Severity,
  isBaseMetric,
  isAttackVector,
  isAttackComplexity,
  isPrivilegesRequired,
  isUserInteraction,
  isScope,
  isConfidentiality,
  isIntegrity,
  isAvailability,
} from './base'
import {
  AvailabilityRequirement,
  ConfidentialityRequirement,
  environmental,
  EnvironmentalMetric,
  IntegrityRequirement,
  isAvailabilityRequirement,
  isConfidentialityRequirement,
  isEnvironmentalMetric,
  isIntegrityRequirement,
  isModifiedAttackComplexity,
  isModifiedAttackVector,
  isModifiedAvailability,
  isModifiedConfidentiality,
  isModifiedIntegrity,
  isModifiedPrivilegesRequired,
  isModifiedScope,
  isModifiedUserInteraction,
  ModifiedAttackComplexity,
  ModifiedAttackVector,
  ModifiedAvailability,
  ModifiedConfidentiality,
  ModifiedIntegrity,
  ModifiedPrivilegesRequired,
  ModifiedScope,
  ModifiedUserInteraction,
} from './environmental'
import {
  ExploitCodeMaturity,
  isExploitCodeMaturity,
  isRemediationLevel,
  isReportConfidence,
  isTemporalMetric,
  RemediationLevel,
  ReportConfidence,
  temporal,
  TemporalMetric,
} from './temporal'

export const base = {
  AV: {
    text: 'Attack Vector',
    metrics: {
      N: {
        text: 'Network',
        value: 0.85,
      },
      A: {
        text: 'Adjacent',
        value: 0.62,
      },
      L: {
        text: 'Local',
        value: 0.55,
      },
      P: {
        text: 'Physical',
        value: 0.2,
      },
    },
  },
  AC: {
    text: 'Attack Complexity',
    metrics: {
      L: {
        text: 'Low',
        value: 0.77,
      },
      H: {
        text: 'High',
        value: 0.44,
      },
    },
  },
  PR: {
    text: 'Privileges Required',
    metrics: {
      N: {
        text: 'None',
        value: {
          // scope unchanged
          U: 0.85,
          // scope changed
          C: 0.85,
        },
      },
      L: {
        text: 'Low',
        value: {
          U: 0.62,
          C: 0.68,
        },
      },
      H: {
        text: 'High',
        value: {
          U: 0.27,
          C: 0.5,
        },
      },
    },
  },
  UI: {
    text: 'User Interaction',
    metrics: {
      N: {
        text: 'None',
        value: 0.85,
      },
      R: {
        text: 'Required',
        value: 0.62,
      },
    },
  },
  S: {
    text: 'Scope',
    metrics: {
      U: {
        text: 'Unchanged',
      },
      C: {
        text: 'Changed',
      },
    },
  },
  C: {
    text: 'Confidentiality',
    metrics: {
      N: {
        text: 'None',
        value: 0,
      },
      L: {
        text: 'Low',
        value: 0.22,
      },
      H: {
        text: 'High',
        value: 0.56,
      },
    },
  },
  I: {
    text: 'Integrity',
    metrics: {
      N: {
        text: 'None',
        value: 0,
      },
      L: {
        text: 'Low',
        value: 0.22,
      },
      H: {
        text: 'High',
        value: 0.56,
      },
    },
  },
  A: {
    text: 'Availability',
    metrics: {
      N: {
        text: 'None',
        value: 0,
      },
      L: {
        text: 'Low',
        value: 0.22,
      },
      H: {
        text: 'High',
        value: 0.56,
      },
    },
  },
}

class CVSS {
  // base
  private attackVector: AttackVector | undefined
  private attackComplexity: AttackComplexity | undefined
  private privilegesRequired: PrivilegesRequired | undefined
  private userInteraction: UserInteraction | undefined
  private scope: Scope | undefined
  private confidentiality: Confidentiality | undefined
  private integrity: Integrity | undefined
  private availability: Availability | undefined

  // temporal
  private exploitCodeMaturity = ExploitCodeMaturity.NotDefined
  private remediationLevel = RemediationLevel.NotDefined
  private reportConfidence = ReportConfidence.NotDefined

  // environmental
  private confidentialityRequirement = ConfidentialityRequirement.NotDefined
  private integrityRequirement = IntegrityRequirement.NotDefined
  private availabilityRequirement = AvailabilityRequirement.NotDefined
  private modifiedAttackVector = ModifiedAttackVector.NotDefined
  private modifiedAttackComplexity = ModifiedAttackComplexity.NotDefined
  private modifiedPrivilegesRequired = ModifiedPrivilegesRequired.NotDefined
  private modifiedUserInteraction = ModifiedUserInteraction.NotDefined
  private modifiedScope = ModifiedScope.NotDefined
  private modifiedConfidentiality = ModifiedConfidentiality.NotDefined
  private modifiedIntegrity = ModifiedIntegrity.NotDefined
  private modifiedAvailability = ModifiedAvailability.NotDefined

  constructor(
    av: AttackVector | undefined,
    ac: AttackComplexity | undefined,
    pr: PrivilegesRequired | undefined,
    ui: UserInteraction | undefined,
    s: Scope | undefined,
    c: Confidentiality | undefined,
    i: Integrity | undefined,
    a: Availability | undefined,

    // temporal
    e: ExploitCodeMaturity,
    rl: RemediationLevel,
    rc: ReportConfidence,

    // environmental
    cr: ConfidentialityRequirement,
    ir: IntegrityRequirement,
    ar: AvailabilityRequirement,
    mav: ModifiedAttackVector,
    mac: ModifiedAttackComplexity,
    mpr: ModifiedPrivilegesRequired,
    mui: ModifiedUserInteraction,
    ms: ModifiedScope,
    mc: ModifiedConfidentiality,
    mi: ModifiedIntegrity,
    ma: ModifiedAvailability
  ) {
    this.attackVector = av
    this.attackComplexity = ac
    this.privilegesRequired = pr
    this.userInteraction = ui
    this.scope = s
    this.confidentiality = c
    this.integrity = i
    this.availability = a
    this.exploitCodeMaturity = e
    this.remediationLevel = rl
    this.reportConfidence = rc
    this.confidentialityRequirement = cr
    this.integrityRequirement = ir
    this.availabilityRequirement = ar
    this.modifiedAttackVector = mav
    this.modifiedAttackComplexity = mac
    this.modifiedPrivilegesRequired = mpr
    this.modifiedUserInteraction = mui
    this.modifiedScope = ms
    this.modifiedConfidentiality = mc
    this.modifiedIntegrity = mi
    this.modifiedAvailability = ma
  }

  public clear() {
    this.attackVector = undefined
    this.attackComplexity = undefined
    this.privilegesRequired = undefined
    this.userInteraction = undefined
    this.scope = undefined
    this.confidentiality = undefined
    this.integrity = undefined
    this.availability = undefined
    this.exploitCodeMaturity = ExploitCodeMaturity.NotDefined
    this.remediationLevel = RemediationLevel.NotDefined
    this.reportConfidence = ReportConfidence.NotDefined
    this.confidentialityRequirement = ConfidentialityRequirement.NotDefined
    this.integrityRequirement = IntegrityRequirement.NotDefined
    this.availabilityRequirement = AvailabilityRequirement.NotDefined
    this.modifiedAttackVector = ModifiedAttackVector.NotDefined
    this.modifiedAttackComplexity = ModifiedAttackComplexity.NotDefined
    this.modifiedPrivilegesRequired = ModifiedPrivilegesRequired.NotDefined
    this.modifiedUserInteraction = ModifiedUserInteraction.NotDefined
    this.modifiedScope = ModifiedScope.NotDefined
    this.modifiedConfidentiality = ModifiedConfidentiality.NotDefined
    this.modifiedIntegrity = ModifiedIntegrity.NotDefined
    this.modifiedAvailability = ModifiedAvailability.NotDefined
  }

  public environmentalSeverity() {
    const score = this.calcEnvironmental()
    return this.severity(score)
  }

  public temporalSeverity() {
    const score = this.calcTemporal()
    return this.severity(score)
  }

  public baseSeverity() {
    const score = this.calcBase()
    return this.severity(score)
  }

  private severity(v: number | undefined): Severity | undefined {
    if (v === undefined) {
      return v
    }
    if (v === 0) {
      return Severity.None
    }
    if (v >= 0 && v <= 3.9) {
      return Severity.Low
    }
    if (v >= 4 && v <= 6.9) {
      return Severity.Medium
    }
    if (v >= 7 && v <= 8.9) {
      return Severity.High
    }
    if (v >= 9 && v <= 10) {
      return Severity.Critical
    }
    return undefined
  }

  private roundup(n: number): number {
    const v = Math.round(n * 100000)
    if (v % 10000 === 0) {
      return v / 100000.0
    }
    return (Math.floor(v / 10000) + 1) / 10.0
  }

  public calcTemporal(): number | undefined {
    const baseScore = this.calcBase()
    if (baseScore === undefined) {
      return undefined
    }
    if (
      this.exploitCodeMaturity &&
      this.remediationLevel &&
      this.reportConfidence
    ) {
      const exploitCodeMaturity =
        temporal[TemporalMetric.ExploitCodeMaturity].metrics[
          this.exploitCodeMaturity
        ].value
      const remediationLevel =
        temporal[TemporalMetric.RemediationLevel].metrics[this.remediationLevel]
          .value
      const reportConfidence =
        temporal[TemporalMetric.ReportConfidence].metrics[this.reportConfidence]
          .value
      return this.roundup(
        baseScore * exploitCodeMaturity * remediationLevel * reportConfidence
      )
    }
    return undefined
  }

  // For metrics that are modified versions of Base Score metrics, e.g. Modified Attack Vector, use the value of
  // the Base Score metric if the modified version value is "X" ("Not Defined").
  // https://www.first.org/cvss/calculator/cvsscalc31.js
  public calcEnvironmental(): number | undefined {
    if (
      this.attackVector &&
      this.attackComplexity &&
      this.userInteraction &&
      this.confidentiality &&
      this.integrity &&
      this.availability &&
      this.privilegesRequired &&
      this.scope
    ) {
      // environmental
      const confidentialityRequirement =
        environmental[EnvironmentalMetric.ConfidentialityRequirement].metrics[
          this.confidentialityRequirement
        ].value
      const integrityRequirement =
        environmental[EnvironmentalMetric.IntegrityRequirement].metrics[
          this.integrityRequirement
        ].value
      const availabilityRequirement =
        environmental[EnvironmentalMetric.AvailabilityRequirement].metrics[
          this.availabilityRequirement
        ].value

      const modifiedAttackVector =
        environmental[EnvironmentalMetric.ModifiedAttackVector].metrics[
          this.modifiedAttackVector !== ModifiedAttackVector.NotDefined
            ? this.modifiedAttackVector
            : this.attackVector
        ].value
      const modifiedAttackComplexity =
        environmental[EnvironmentalMetric.ModifiedAttackComplexity].metrics[
          this.modifiedAttackComplexity !== ModifiedAttackComplexity.NotDefined
            ? this.modifiedAttackComplexity
            : this.attackComplexity
        ].value
      const modifiedPrivilegesRequired =
        environmental[EnvironmentalMetric.ModifiedPrivilegesRequired].metrics[
          this.modifiedPrivilegesRequired !==
          ModifiedPrivilegesRequired.NotDefined
            ? this.modifiedPrivilegesRequired
            : this.privilegesRequired
        ].value
      const modifiedUserInteraction =
        environmental[EnvironmentalMetric.ModifiedUserInteraction].metrics[
          this.modifiedUserInteraction !== ModifiedUserInteraction.NotDefined
            ? this.modifiedUserInteraction
            : this.userInteraction
        ].value
      const modifiedConfidentiality =
        environmental[EnvironmentalMetric.ModifiedConfidentiality].metrics[
          this.modifiedConfidentiality !== ModifiedConfidentiality.NotDefined
            ? this.modifiedConfidentiality
            : this.confidentiality
        ].value
      const modifiedIntegrity =
        environmental[EnvironmentalMetric.ModifiedIntegrity].metrics[
          this.modifiedIntegrity !== ModifiedIntegrity.NotDefined
            ? this.modifiedIntegrity
            : this.integrity
        ].value
      const modifiedAvailability =
        environmental[EnvironmentalMetric.ModifiedAvailability].metrics[
          this.modifiedAvailability !== ModifiedAvailability.NotDefined
            ? this.modifiedAvailability
            : this.availability
        ].value
      // temporal
      const exploitCodeMaturity =
        temporal[TemporalMetric.ExploitCodeMaturity].metrics[
          this.exploitCodeMaturity
        ].value
      const remediationLevel =
        temporal[TemporalMetric.RemediationLevel].metrics[this.remediationLevel]
          .value
      const reportConfidence =
        temporal[TemporalMetric.ReportConfidence].metrics[this.reportConfidence]
          .value

      const miss = Math.min(
        1 -
          (1 - confidentialityRequirement * modifiedConfidentiality) *
            (1 - integrityRequirement * modifiedIntegrity) *
            (1 - availabilityRequirement * modifiedAvailability),
        0.915
      )

      let modifiedImpact: number
      if (
        this.modifiedScope === ModifiedScope.Unchanged ||
        (this.modifiedScope === ModifiedScope.NotDefined &&
          this.scope === Scope.Unchanged)
      ) {
        modifiedImpact = 6.42 * miss
      } else {
        modifiedImpact =
          7.52 * (miss - 0.029) - 3.25 * Math.pow(miss * 0.9731 - 0.02, 13)
      }

      const modifiedExploitability =
        8.22 *
        modifiedAttackVector *
        modifiedAttackComplexity *
        modifiedPrivilegesRequired[
          this.modifiedScope !== ModifiedScope.NotDefined
            ? this.modifiedScope
            : this.scope
        ] *
        modifiedUserInteraction

      if (modifiedImpact <= 0) {
        return 0
      } else if (
        this.modifiedScope === ModifiedScope.Unchanged ||
        (this.modifiedScope === ModifiedScope.NotDefined &&
          this.scope === Scope.Unchanged)
      ) {
        return this.roundup(
          this.roundup(Math.min(modifiedImpact + modifiedExploitability, 10)) *
            exploitCodeMaturity *
            remediationLevel *
            reportConfidence
        )
      } else {
        return this.roundup(
          this.roundup(
            Math.min(1.08 * (modifiedImpact + modifiedExploitability), 10)
          ) *
            exploitCodeMaturity *
            remediationLevel *
            reportConfidence
        )
      }
    }
    return undefined
  }

  public calcBase(): number | undefined {
    if (
      this.attackVector &&
      this.attackComplexity &&
      this.privilegesRequired &&
      this.userInteraction &&
      this.scope &&
      this.confidentiality &&
      this.integrity &&
      this.availability
    ) {
      const confidentiality = base.C.metrics[this.confidentiality].value
      const integrity = base.I.metrics[this.integrity].value
      const availability = base.A.metrics[this.availability].value

      const iss =
        1 - (1 - confidentiality) * (1 - integrity) * (1 - availability)

      let impact: number | undefined
      if (this.scope === Scope.Unchanged) {
        impact = 6.42 * iss
      } else {
        impact = 7.52 * (iss - 0.029) - 3.25 * Math.pow(iss - 0.02, 15)
      }

      const attackVector = base.AV.metrics[this.attackVector].value
      const attackComplexity = base.AC.metrics[this.attackComplexity].value
      const privilegesRequired =
        base.PR.metrics[this.privilegesRequired].value[this.scope]
      const userInteraction = base.UI.metrics[this.userInteraction].value

      const exploitability =
        8.22 *
        attackVector *
        attackComplexity *
        privilegesRequired *
        userInteraction

      if (impact <= 0) {
        return 0
      } else {
        if (this.scope === Scope.Unchanged) {
          return this.roundup(Math.min(impact + exploitability, 10))
        } else if (this.scope === Scope.Changed) {
          return this.roundup(Math.min(1.08 * (impact + exploitability), 10))
        }
      }
    }

    return undefined
  }

  public toString(): string {
    const result = []
    // base
    if (this.attackVector) {
      result.push(`AV:${this.attackVector}`)
    }
    if (this.attackComplexity) {
      result.push(`AC:${this.attackComplexity}`)
    }
    if (this.privilegesRequired) {
      result.push(`PR:${this.privilegesRequired}`)
    }
    if (this.userInteraction) {
      result.push(`UI:${this.userInteraction}`)
    }
    if (this.scope) {
      result.push(`S:${this.scope}`)
    }
    if (this.confidentiality) {
      result.push(`C:${this.confidentiality}`)
    }
    if (this.integrity) {
      result.push(`I:${this.integrity}`)
    }
    if (this.availability) {
      result.push(`A:${this.availability}`)
    }
    // temporal
    if (this.exploitCodeMaturity !== ExploitCodeMaturity.NotDefined) {
      result.push(`E:${this.exploitCodeMaturity}`)
    }
    if (this.remediationLevel !== RemediationLevel.NotDefined) {
      result.push(`RL:${this.remediationLevel}`)
    }
    if (this.reportConfidence !== ReportConfidence.NotDefined) {
      result.push(`RC:${this.reportConfidence}`)
    }
    // environmental
    if (
      this.confidentialityRequirement !== ConfidentialityRequirement.NotDefined
    ) {
      result.push(`CR:${this.confidentialityRequirement}`)
    }
    if (this.integrityRequirement !== IntegrityRequirement.NotDefined) {
      result.push(`IR:${this.integrityRequirement}`)
    }
    if (this.availabilityRequirement !== AvailabilityRequirement.NotDefined) {
      result.push(`AR:${this.availabilityRequirement}`)
    }
    if (this.modifiedAttackVector !== ModifiedAttackVector.NotDefined) {
      result.push(`MAV:${this.modifiedAttackVector}`)
    }
    if (this.modifiedAttackComplexity !== ModifiedAttackComplexity.NotDefined) {
      result.push(`MAC:${this.modifiedAttackComplexity}`)
    }
    if (
      this.modifiedPrivilegesRequired !== ModifiedPrivilegesRequired.NotDefined
    ) {
      result.push(`MPR:${this.modifiedPrivilegesRequired}`)
    }
    if (this.modifiedUserInteraction !== ModifiedUserInteraction.NotDefined) {
      result.push(`MUI:${this.modifiedUserInteraction}`)
    }
    if (this.modifiedScope !== ModifiedScope.NotDefined) {
      result.push(`MS:${this.modifiedScope}`)
    }
    if (this.modifiedConfidentiality !== ModifiedConfidentiality.NotDefined) {
      result.push(`MC:${this.modifiedConfidentiality}`)
    }
    if (this.modifiedIntegrity !== ModifiedIntegrity.NotDefined) {
      result.push(`MI:${this.modifiedIntegrity}`)
    }
    if (this.modifiedAvailability !== ModifiedAvailability.NotDefined) {
      result.push(`MA:${this.modifiedAvailability}`)
    }
    return result.join('/')
  }

  public update(name: string | undefined, value: string | undefined) {
    if (name && value) {
      if (isBaseMetric(name)) {
        if (name === 'AV' && isAttackVector(value)) {
          this.attackVector = value
        } else if (name === 'AC' && isAttackComplexity(value)) {
          this.attackComplexity = value
        } else if (name === 'PR' && isPrivilegesRequired(value)) {
          this.privilegesRequired = value
        } else if (name === 'UI' && isUserInteraction(value)) {
          this.userInteraction = value
        } else if (name === 'S' && isScope(value)) {
          this.scope = value
        } else if (name === 'C' && isConfidentiality(value)) {
          this.confidentiality = value
        } else if (name === 'I' && isIntegrity(value)) {
          this.integrity = value
        } else if (name === 'A' && isAvailability(value)) {
          this.availability = value
        }
      } else if (isTemporalMetric(name)) {
        if (
          name === TemporalMetric.ExploitCodeMaturity &&
          isExploitCodeMaturity(value)
        ) {
          this.exploitCodeMaturity = value
        } else if (
          name === TemporalMetric.RemediationLevel &&
          isRemediationLevel(value)
        ) {
          this.remediationLevel = value
        } else if (
          name === TemporalMetric.ReportConfidence &&
          isReportConfidence(value)
        ) {
          this.reportConfidence = value
        }
      } else if (isEnvironmentalMetric(name)) {
        if (
          name === EnvironmentalMetric.ConfidentialityRequirement &&
          isConfidentialityRequirement(value)
        ) {
          this.confidentialityRequirement = value
        } else if (
          name === EnvironmentalMetric.IntegrityRequirement &&
          isIntegrityRequirement(value)
        ) {
          this.integrityRequirement = value
        } else if (
          name === EnvironmentalMetric.AvailabilityRequirement &&
          isAvailabilityRequirement(value)
        ) {
          this.availabilityRequirement = value
        } else if (
          name === EnvironmentalMetric.ModifiedAttackVector &&
          isModifiedAttackVector(value)
        ) {
          this.modifiedAttackVector = value
        } else if (
          name === EnvironmentalMetric.ModifiedAttackComplexity &&
          isModifiedAttackComplexity(value)
        ) {
          this.modifiedAttackComplexity = value
        } else if (
          name === EnvironmentalMetric.ModifiedPrivilegesRequired &&
          isModifiedPrivilegesRequired(value)
        ) {
          this.modifiedPrivilegesRequired = value
        } else if (
          name === EnvironmentalMetric.ModifiedUserInteraction &&
          isModifiedUserInteraction(value)
        ) {
          this.modifiedUserInteraction = value
        } else if (
          name === EnvironmentalMetric.ModifiedScope &&
          isModifiedScope(value)
        ) {
          this.modifiedScope = value
        } else if (
          name === EnvironmentalMetric.ModifiedConfidentiality &&
          isModifiedConfidentiality(value)
        ) {
          this.modifiedConfidentiality = value
        } else if (
          name === EnvironmentalMetric.ModifiedIntegrity &&
          isModifiedIntegrity(value)
        ) {
          this.modifiedIntegrity = value
        } else if (
          name === EnvironmentalMetric.ModifiedAvailability &&
          isModifiedAvailability(value)
        ) {
          this.modifiedAvailability = value
        }
      }
    }
  }

  public checked(name: string, value: string) {
    if (isBaseMetric(name)) {
      if (name === 'AV' && isAttackVector(value)) {
        return this.attackVector === value
      }
      if (name === 'AC' && isAttackComplexity(value)) {
        return this.attackComplexity === value
      }
      if (name === 'PR' && isPrivilegesRequired(value)) {
        return this.privilegesRequired === value
      }
      if (name === 'UI' && isUserInteraction(value)) {
        return this.userInteraction === value
      }
      if (name === 'S' && isScope(value)) {
        return this.scope === value
      }
      if (name === 'C' && isConfidentiality(value)) {
        return this.confidentiality === value
      }
      if (name === 'I' && isIntegrity(value)) {
        return this.integrity === value
      }
      if (name === 'A' && isAvailability(value)) {
        return this.availability === value
      }
    } else if (isTemporalMetric(name)) {
      if (
        name === TemporalMetric.ExploitCodeMaturity &&
        isExploitCodeMaturity(value)
      ) {
        return this.exploitCodeMaturity === value
      } else if (
        name === TemporalMetric.RemediationLevel &&
        isRemediationLevel(value)
      ) {
        return this.remediationLevel === value
      } else if (
        name === TemporalMetric.ReportConfidence &&
        isReportConfidence(value)
      ) {
        return this.reportConfidence === value
      }
    } else if (isEnvironmentalMetric(name)) {
      if (
        name === EnvironmentalMetric.ConfidentialityRequirement &&
        isConfidentialityRequirement(value)
      ) {
        return this.confidentialityRequirement === value
      } else if (
        name === EnvironmentalMetric.IntegrityRequirement &&
        isIntegrityRequirement(value)
      ) {
        return this.integrityRequirement === value
      } else if (
        name === EnvironmentalMetric.AvailabilityRequirement &&
        isAvailabilityRequirement(value)
      ) {
        return this.availabilityRequirement === value
      } else if (
        name === EnvironmentalMetric.ModifiedAttackVector &&
        isModifiedAttackVector(value)
      ) {
        return this.modifiedAttackVector === value
      } else if (
        name === EnvironmentalMetric.ModifiedAttackComplexity &&
        isModifiedAttackComplexity(value)
      ) {
        return this.modifiedAttackComplexity === value
      } else if (
        name === EnvironmentalMetric.ModifiedPrivilegesRequired &&
        isModifiedPrivilegesRequired(value)
      ) {
        return this.modifiedPrivilegesRequired === value
      } else if (
        name === EnvironmentalMetric.ModifiedUserInteraction &&
        isModifiedUserInteraction(value)
      ) {
        return this.modifiedUserInteraction === value
      } else if (
        name === EnvironmentalMetric.ModifiedScope &&
        isModifiedScope(value)
      ) {
        return this.modifiedScope === value
      } else if (
        name === EnvironmentalMetric.ModifiedConfidentiality &&
        isModifiedConfidentiality(value)
      ) {
        return this.modifiedConfidentiality === value
      } else if (
        name === EnvironmentalMetric.ModifiedIntegrity &&
        isModifiedIntegrity(value)
      ) {
        return this.modifiedIntegrity === value
      } else if (
        name === EnvironmentalMetric.ModifiedAvailability &&
        isModifiedAvailability(value)
      ) {
        return this.modifiedAvailability === value
      }
    }
    return false
  }

  static fromString(input: string | null): CVSS {
    // base
    let av: AttackVector | undefined = undefined
    let ac: AttackComplexity | undefined = undefined
    let pr: PrivilegesRequired | undefined = undefined
    let ui: UserInteraction | undefined = undefined
    let s: Scope | undefined = undefined
    let c: Confidentiality | undefined = undefined
    let i: Integrity | undefined = undefined
    let a: Availability | undefined = undefined

    // temporal
    let e = ExploitCodeMaturity.NotDefined
    let rl = RemediationLevel.NotDefined
    let rc = ReportConfidence.NotDefined

    // environmental
    let cr = ConfidentialityRequirement.NotDefined
    let ir = IntegrityRequirement.NotDefined
    let ar = AvailabilityRequirement.NotDefined
    let mav = ModifiedAttackVector.NotDefined
    let mac = ModifiedAttackComplexity.NotDefined
    let mpr = ModifiedPrivilegesRequired.NotDefined
    let mui = ModifiedUserInteraction.NotDefined
    let ms = ModifiedScope.NotDefined
    let mc = ModifiedConfidentiality.NotDefined
    let mi = ModifiedIntegrity.NotDefined
    let ma = ModifiedAvailability.NotDefined
    if (input !== null) {
      const parts = input.split('/')
      parts.forEach((p) => {
        const [key, value] = p.split(':')
        if (key && value) {
          if (isBaseMetric(key)) {
            if (key === 'AV' && isAttackVector(value)) {
              av = value
            } else if (key === 'AC' && isAttackComplexity(value)) {
              ac = value
            } else if (key === 'PR' && isPrivilegesRequired(value)) {
              pr = value
            } else if (key === 'UI' && isUserInteraction(value)) {
              ui = value
            } else if (key === 'S' && isScope(value)) {
              s = value
            } else if (key === 'C' && isConfidentiality(value)) {
              c = value
            } else if (key === 'I' && isIntegrity(value)) {
              i = value
            } else if (key === 'A' && isAvailability(value)) {
              a = value
            }
          } else if (isTemporalMetric(key)) {
            if (
              key === TemporalMetric.ExploitCodeMaturity &&
              isExploitCodeMaturity(value)
            ) {
              e = value
            } else if (
              key === TemporalMetric.RemediationLevel &&
              isRemediationLevel(value)
            ) {
              rl = value
            } else if (
              key === TemporalMetric.ReportConfidence &&
              isReportConfidence(value)
            ) {
              rc = value
            }
          } else if (isEnvironmentalMetric(key)) {
            if (
              key === EnvironmentalMetric.ConfidentialityRequirement &&
              isConfidentialityRequirement(value)
            ) {
              cr = value
            } else if (
              key === EnvironmentalMetric.IntegrityRequirement &&
              isIntegrityRequirement(value)
            ) {
              ir = value
            } else if (
              key === EnvironmentalMetric.AvailabilityRequirement &&
              isAvailabilityRequirement(value)
            ) {
              ar = value
            } else if (
              key === EnvironmentalMetric.ModifiedAttackVector &&
              isModifiedAttackVector(value)
            ) {
              mav = value
            } else if (
              key === EnvironmentalMetric.ModifiedAttackComplexity &&
              isModifiedAttackComplexity(value)
            ) {
              mac = value
            } else if (
              key === EnvironmentalMetric.ModifiedPrivilegesRequired &&
              isModifiedPrivilegesRequired(value)
            ) {
              mpr = value
            } else if (
              key === EnvironmentalMetric.ModifiedUserInteraction &&
              isModifiedUserInteraction(value)
            ) {
              mui = value
            } else if (
              key === EnvironmentalMetric.ModifiedScope &&
              isModifiedScope(value)
            ) {
              ms = value
            } else if (
              key === EnvironmentalMetric.ModifiedConfidentiality &&
              isModifiedConfidentiality(value)
            ) {
              mc = value
            } else if (
              key === EnvironmentalMetric.ModifiedIntegrity &&
              isModifiedIntegrity(value)
            ) {
              mi = value
            } else if (
              key === EnvironmentalMetric.ModifiedAvailability &&
              isModifiedAvailability(value)
            ) {
              ma = value
            }
          }
        }
      })
    }
    return new CVSS(
      av,
      ac,
      pr,
      ui,
      s,
      c,
      i,
      a,
      e,
      rl,
      rc,
      cr,
      ir,
      ar,
      mav,
      mac,
      mpr,
      mui,
      ms,
      mc,
      mi,
      ma
    )
  }
}

export default CVSS
