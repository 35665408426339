import classNames from 'classnames'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Fragment, useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link, useSearchParams } from 'react-router-dom'
import { ConfigContext } from '../Config'
import { NpmPackageVersionRecord } from '../types'
import CriticalityScoreComponent from './CriticalityScoreComponent'
import Links from './Links'
import { useMapLocationToApi } from './utils'
dayjs.extend(relativeTime)

const Overview: React.FunctionComponent = function Overview() {
  const configCtx = useContext(ConfigContext)

  const [params] = useSearchParams()
  const name = params.get('name')

  const [version, setVersion] = useState<NpmPackageVersionRecord>()

  const query = useMapLocationToApi()

  const fetchVersion = async (search: string, api: string) => {
    try {
      const response = await fetch(`${api}/api/versions?${search}`)
      const result = await response.json()
      setVersion(result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchVersion(query, configCtx.cache)
  }, [query, configCtx.cache])

  return (
    <Fragment>
      <AdvisoryComponent />

      <div className="card mb-4">
        <div className="card-header">{name}</div>
        <div className="card-body placeholder-glow">
          <h5>Description</h5>
          {version ? (
            <p>{version.description}</p>
          ) : (
            <p>
              <span className="placeholder col-6" />
            </p>
          )}
          <h5>Version</h5>
          {version ? (
            <p>{version?.version}</p>
          ) : (
            <p>
              <span className="placeholder col-3" />
            </p>
          )}
          <h5>Published</h5>
          {version ? (
            <p title={version.createdAt}>
              {dayjs(version?.createdAt).fromNow()}
            </p>
          ) : (
            <p>
              <span className="placeholder col-4" />
            </p>
          )}
          <h5>License</h5>
          {version ? (
            <p>
              <Link
                to={`/licenses/${version.license?.licenseId}`}
                className="text-decoration-none"
              >
                {version.license?.name}
              </Link>
            </p>
          ) : (
            <p>
              <span className="placeholder col-4" />
            </p>
          )}
        </div>
      </div>

      <CriticalityScoreComponent name={name} />

      <Links
        name={name}
        bugs={version?.bugs}
        homepage={version?.homepage}
        repositoryUrl={version?.repositoryUrl}
      />
    </Fragment>
  )
}

interface Advisory {
  description: string
  ghsaId: string
  notificationsPermalink: string
  origin: string
  publishedAt: string
  severity: string
  summary: string
  references: Array<string>
  cvss: Cvss
  identifiers: Array<Identifier>
  vulnerabilities: Array<Vulnerability>
  cwes: Array<Cwe>
}

interface Cwe {
  cweId: string
  description: string
  name: string
}

interface Cvss {
  score: number
  vectorString: string
}

interface Identifier {
  type: string
  value: string
}

interface Vulnerability {
  vulnerableVersionRange: string
  firstPatchedVersion: string
  severity: string
  updatedAt: string
}

const AdvisoryComponent = () => {
  const configCtx = useContext(ConfigContext)
  const query = useMapLocationToApi()
  const [advisory, setAdvisory] = useState<Advisory>()

  const fetchAdvisory = async (search: string, api: string) => {
    try {
      const response = await fetch(`${api}/api/advisories?${search}`)
      if (response.ok) {
        const result = await response.json()
        setAdvisory(result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchAdvisory(query, configCtx.cache)
  }, [query, configCtx.cache])

  if (!advisory) {
    return null
  }

  console.log('here')
  console.log(advisory)

  return (
    <div className="card border-danger mb-4">
      <div className="card-header d-flex align-items-center">
        {advisory.summary}
        <span className="badge bg-danger ms-auto">{advisory.severity}</span>
      </div>
      <div className="card-body text-danger">
        <ReactMarkdown>{advisory.description}</ReactMarkdown>
        <h5>References</h5>
        <ul>
          {advisory.references.map((r) => (
            <li key={r}>
              <a
                className="link-danger text-decoration-none"
                href={r}
                target="_blank"
                rel="noreferrer"
              >
                {r}
              </a>
            </li>
          ))}
        </ul>
        <h5>CVSS</h5>
        <p className="card-text">Score: {advisory.cvss.score}</p>
        <p className="card-text">
          Vector:{' '}
          <Link
            className="link-danger text-decoration-none"
            to={`/cvss-calculator?vector=${advisory.cvss.vectorString}`}
          >
            {advisory.cvss.vectorString}
          </Link>
        </p>
        <h5>Identifiers</h5>
        {advisory.identifiers.map((i) => {
          return (
            <p key={i.type} className="card-text">
              {i.type}: {i.value}
            </p>
          )
        })}
        <h5>CWEs</h5>
        <ul>
          {advisory.cwes.map((cwe) => {
            return (
              <li key={cwe.cweId}>
                {cwe.cweId} - {cwe.name}
              </li>
            )
          })}
        </ul>
      </div>
      <h2 className="text-danger mx-3">Vulnerabilities</h2>
      <table className="table table-hover mb-0 align-middle">
        <thead>
          <tr>
            <th className="ps-3">Affected versions</th>
            <th>Patched versions</th>
            <th>Severity</th>
          </tr>
        </thead>
        <tbody>
          {advisory.vulnerabilities.map((v, i) => (
            <tr key={v.vulnerableVersionRange}>
              <td
                className={classNames('ps-3', {
                  'border-bottom-0': i === advisory.vulnerabilities.length - 1,
                })}
              >
                {v.vulnerableVersionRange}
              </td>
              <td
                className={classNames({
                  'border-bottom-0': i === advisory.vulnerabilities.length - 1,
                })}
              >
                {v.firstPatchedVersion}
              </td>
              <td
                className={classNames({
                  'border-bottom-0': i === advisory.vulnerabilities.length - 1,
                })}
              >
                {v.severity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Overview
