import { useEffect, useRef } from 'react'
import { EyeSlash } from '../Icons'
import BoxPlot from './BoxPlot'

export interface Box {
  closedIssuesCount: number
  commentFrequency: number
  commitFrequency: number
  contributorCount: number
  createdSince: number
  criticalityScore: number
  dependentsCount: number
  orgCount: number
  recentReleasesCount: number
  updatedIssuesCount: number
  updatedSince: number
  watchersCount: number
}

interface Props {
  boxes: Box[] | undefined
  color: string
}

const BoxPlotComponent: React.FunctionComponent<Props> =
  function BoxPlotComponent(props: Props) {
    const svgRef = useRef<SVGSVGElement>(null)
    const plot = useRef<BoxPlot>()

    useEffect(() => {
      if (svgRef.current) {
        if (!plot.current) {
          const config = {
            target: svgRef.current,
            margin: {
              top: 20,
              right: 20,
              bottom: 100,
              left: 50,
            },
            color: props.color,
          }
          plot.current = new BoxPlot(config)
        } else if (plot.current && props.boxes) {
          plot.current.update(props.boxes)
        }
      }
    }, [props.boxes, props.color])

    const resize = (): void => {
      if (svgRef.current && plot.current) {
        plot.current.resize()
      }
    }

    useEffect((): (() => void) => {
      window.addEventListener('resize', resize)
      return (): void => {
        window.removeEventListener('resize', resize)
      }
    }, [])

    if (props.boxes?.length === 0) {
      return (
        <div className="h-100 d-flex flex-column align-items-center justify-content-center">
          <div className="mb-4">
            <EyeSlash width="40px" height="40px" />
          </div>
          <p className="mb-0">
            Criticality Score for packages not calculated yet.
          </p>
          <p>Check back soon!</p>
        </div>
      )
    }

    return <svg ref={svgRef} />
  }

export default BoxPlotComponent
