import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link, useLocation } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { ConfigContext } from '../Config'
import ListGroupPlaceholder from '../Package/ListGroupPlaceholder'
import Pagination from '../Pagination'
import { Page } from '../types'

const formatter = new Intl.NumberFormat()

const KeywordComponent: FunctionComponent = () => {
  const configCtx = useContext(ConfigContext)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const value = params.get('value')

  const [loading, setLoading] = useState(true)
  const [packages, setPackages] = useState<Page<string>>()

  const retrieve = async (search: string, api: string) => {
    const data = await fetch(`${api}/api/packages${search}`)
    const result = await data.json()
    setPackages(result)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(location.search, configCtx.cache)
  }, [location.search, configCtx.cache])

  return (
    <React.Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: '/keywords', text: 'Keywords' },
          { href: null, text: value ?? '' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <div className="card">
          <div className="card-header">
            Packages with keyword <code>{value}</code>
            {packages ? (
              <span className="badge bg-secondary ms-2">
                {formatter.format(packages.totalElements)}
              </span>
            ) : null}
          </div>

          {loading ? (
            <ListGroupPlaceholder
              cols={[4, 3, 4, 3, 2, 2, 4, 2, 1, 3]}
              includeUl={true}
            />
          ) : packages?.empty ? (
            <div className="card-body">
              We could not find any packages for keyword <code>{value}</code>
            </div>
          ) : (
            <ul className="list-group list-group-flush">
              {packages?.content.map((p) => {
                return (
                  <li
                    key={p}
                    className="list-group-item d-flex align-items-center"
                  >
                    <Link
                      to={`/packages?name=${p}`}
                      className="text-decoration-none me-auto"
                    >
                      {p}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
        <Pagination loading={loading} page={packages} />
      </div>
    </React.Fragment>
  )
}

export default KeywordComponent
