import { Route, Routes, useSearchParams } from 'react-router-dom'
import Breadcrumb, { BreadcrumbLink } from '../Breadcrumb'

import Maintainers from './Maintainers'
import Keywords from './Keywords'
import Overview from './Overview'
import Dependencies from './Dependencies'
import Dependents from './Dependents'
import Tabs from './Tabs'
import Versions from './Versions'

const Version: React.FunctionComponent = () => {
  const [params] = useSearchParams()
  const name = params.get('name')
  const version = params.get('version')

  const links: BreadcrumbLink[] = [
    { href: '/', text: 'Home' },
    { href: '/packages', text: 'Packages' },
  ]

  if (version) {
    // we are looking at a specific version, e.g.
    // Home / Packages / @angular/service-worker / 9.1.13
    links.push({ href: `/packages?name=${name}`, text: name ?? '' })
    links.push({ href: null, text: version ?? '' })
  } else {
    // we are looking at the default i.e. latest version, e.g.
    // Home / Packages / @angular/service-worker
    links.push({ href: null, text: name ?? '' })
  }

  return (
    <div>
      <Breadcrumb className="" links={links} />
      <Tabs name={name} version={version} />
      <div className="px-2 px-md-4 py-4">
        <Routes>
          <Route index element={<Overview />} />
          <Route path="versions" element={<Versions />} />
          <Route path="maintainers" element={<Maintainers />} />
          <Route path="keywords" element={<Keywords />} />
          <Route path="dependencies" element={<Dependencies />} />
          <Route path="dependents" element={<Dependents />} />
        </Routes>
      </div>
    </div>
  )
}

export default Version
