import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import ConfigContextProvider from './Config'
import ErrorBoundary from './ErrorBoundary'
import './index.scss'
import Router from './Router'
import ToastContextProvider from './Toast/Toast'

console.log(process.env['REACT_APP_HASH'])

// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <ConfigContextProvider>
          <ToastContextProvider>
            <Router />
          </ToastContextProvider>
        </ConfigContextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </StrictMode>
)
