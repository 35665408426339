import { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { ConfigContext } from '../Config'
import { NpmPackageVersionLicenseProjection, Page } from '../types'
import ListGroupPlaceholder from './ListGroupPlaceholder'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Pagination from '../Pagination'
import { useMapLocationToApi } from './utils'
import styles from './versions.module.scss'
import classNames from 'classnames'
dayjs.extend(relativeTime)

const backgroundColor = (
  version: string | null,
  v: NpmPackageVersionLicenseProjection
): string | undefined => {
  if (version) {
    if (version === v.version) {
      return '#fff8c5'
    }
  } else if (v.tags?.split(',').includes('latest')) {
    return '#fff8c5'
  }
  return undefined
}

const Versions: React.FunctionComponent = function Maintainers() {
  const configCtx = useContext(ConfigContext)
  const [params] = useSearchParams()
  const version = params.get('version')
  const name = params.get('name')

  const query = useMapLocationToApi()

  const [versions, setVersions] =
    useState<Page<NpmPackageVersionLicenseProjection>>()
  const [loading, setLoading] = useState(true)

  const fetchVersions = async (query: string, api: string) => {
    try {
      const data = await fetch(`${api}/api/versions?${query}`)
      const result = await data.json()
      setVersions(result)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchVersions(query, configCtx.cache)
  }, [query, configCtx.cache])

  return (
    <Fragment>
      <TaggedVersions />
      <div className="card placeholder-glow">
        <div className="card-header">All Versions</div>

        {loading ? (
          <ListGroupPlaceholder cols={[6, 5, 6, 4, 3, 2]} includeUl={true} />
        ) : (
          <ul className="list-group list-group-flush">
            {versions?.content.map((v) => {
              return (
                <li
                  key={v.version}
                  className="list-group-item d-flex align-items-center"
                  style={{
                    gap: 10,
                    backgroundColor: backgroundColor(version, v),
                  }}
                >
                  <div className={classNames('text-truncate', styles.version)}>
                    <Link
                      to={`/packages?name=${name}&version=${v.version}`}
                      className="text-decoration-none"
                    >
                      {v.version}
                    </Link>
                  </div>
                  <div style={{ flexBasis: 100 }}>
                    {v.tags?.split(',').map((t) => {
                      return (
                        <span key={t} className="badge bg-secondary">
                          {t}
                        </span>
                      )
                    })}
                  </div>
                  <span
                    className="text-truncate d-none d-md-block"
                    style={{
                      flex: 1,
                    }}
                  >
                    {v.description}
                  </span>
                  <Link
                    to={`/licenses/${v.licenseId}`}
                    style={{
                      flexBasis: 120,
                    }}
                    className="text-truncate text-decoration-none d-none d-md-block"
                  >
                    {v.licenseId}
                  </Link>
                  <span
                    className="text-muted text-end"
                    style={{
                      flexBasis: 120,
                    }}
                  >
                    {dayjs(v.createdAt).fromNow()}
                  </span>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <Pagination loading={loading} page={versions} />
    </Fragment>
  )
}

interface TaggedVersion {
  name: string
  version: string
  licenseId: string
  description: string
  createdAt: string
}

const TaggedVersions: React.FunctionComponent = function Maintainers() {
  const configCtx = useContext(ConfigContext)
  const [params] = useSearchParams()
  const name = params.get('name')

  const query = useMapLocationToApi()

  const [versions, setVersions] = useState<TaggedVersion[]>()
  const [loading, setLoading] = useState(true)

  const fetchVersions = async (query: string, api: string) => {
    try {
      const data = await fetch(`${api}/api/tags?${query}`)
      const result = await data.json()
      setVersions(result)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchVersions(query, configCtx.cache)
  }, [query, configCtx.cache])

  return (
    <div className="card placeholder-glow mb-4">
      <div className="card-header">Tagged Versions</div>
      {loading ? (
        <ListGroupPlaceholder cols={[6, 5, 6]} includeUl={true} />
      ) : (
        <ul className="list-group list-group-flush">
          {versions?.map((v) => {
            return (
              <li
                key={v.name}
                className="list-group-item d-flex align-items-center"
                style={{
                  gap: 10,
                }}
              >
                <div
                  className="text-truncate flex-shrink-0"
                  style={{
                    flexBasis: 100,
                  }}
                >
                  <span className="badge bg-secondary">{v.name}</span>
                </div>

                <div className={classNames('text-truncate', styles.version)}>
                  <Link
                    to={`/packages?name=${name}&version=${v.version}`}
                    className="text-decoration-none"
                  >
                    {v.version}
                  </Link>
                </div>
                <span
                  className="text-truncate d-none d-md-block"
                  style={{
                    flex: 1,
                  }}
                >
                  {v.description}
                </span>

                <Link
                  to={`/licenses/${v.licenseId}`}
                  style={{
                    flexBasis: 120,
                  }}
                  className="text-truncate text-decoration-none d-none d-md-block"
                >
                  {v.licenseId}
                </Link>

                <span
                  className="text-muted text-end ms-auto"
                  style={{
                    flexBasis: 120,
                  }}
                >
                  {dayjs(v.createdAt).fromNow()}
                </span>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Versions
