import { FunctionComponent } from 'react'
import { useSearchParams } from 'react-router-dom'
import MaintainerComponent from './MaintainerComponent'
import MaintainerListComponent from './MaintainerListComponent'

const MaintainerWrapper: FunctionComponent = () => {
  const [params] = useSearchParams()
  const name = params.get('name')

  if (name !== null) {
    return <MaintainerComponent />
  }

  return <MaintainerListComponent />
}

export default MaintainerWrapper
