import { useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { ConfigContext } from '../Config'
import ListGroupPlaceholder from './ListGroupPlaceholder'
import { useMapLocationToApi } from './utils'

const Keywords: React.FunctionComponent = function Keywords() {
  const configCtx = useContext(ConfigContext)
  const [loading, setLoading] = useState(true)
  const [keywords, setKeywords] = useState<string[]>([])

  const [searchParams] = useSearchParams()
  const name = searchParams.get('name')
  const version = searchParams.get('version')

  const query = useMapLocationToApi()

  const retrieve = async (query: string, api: string) => {
    const response = await fetch(`${api}/api/versions/keywords?${query}`)
    const data = await response.json()
    setKeywords(data)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(query, configCtx.cache)
  }, [query, configCtx.cache])

  return (
    <div className="card mb-4 placeholder-glow">
      <div className="card-header">Keywords</div>

      {loading ? (
        <ListGroupPlaceholder cols={[2, 3, 2, 1, 2]} includeUl={true} />
      ) : keywords.length === 0 ? (
        <div className="card-body">
          <code>
            {name}@{version}
          </code>{' '}
          does not have any keywords.
        </div>
      ) : (
        <ul className="list-group list-group-flush">
          {keywords.map((k) => {
            return (
              <li key={k} className="list-group-item">
                <Link
                  className="text-decoration-none"
                  to={`/keywords?value=${k}`}
                >
                  {k}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Keywords
