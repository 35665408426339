import classnames from 'classnames'
import React, {
  ChangeEvent,
  DragEvent,
  Fragment,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link, NavLink, Route, Routes, useNavigate } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { ConfigContext } from '../Config'
// import { ToastContext } from '../Toast/Toast'
// import demo package-lock.json for showing in monaco editor
import packageLockJson from '../demo-package-lock.json'
import { CheckCircle, CloudUpload } from '../Icons'
import { ToastContext } from '../Toast/Toast'

function HomeComponent(): JSX.Element {
  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'Demo' },
        ]}
      />
      <div className="px-2 px-md-4 py-4">
        <div className="card mb-4">
          <div className="card-header">Upload project metadata</div>
          <div className="card-body">
            <p>
              At the moment we only support <strong>package-lock.json</strong>{' '}
              and <strong>yarn.lock</strong> from JavaScript projects.
            </p>
            <p>
              In the future we will support much more different files and
              programming languages. Have a look at our{' '}
              <Link to="/roadmap" className="text-decoration-none">
                roadmap
              </Link>
              .
            </p>

            <ul className="nav nav-tabs">
              <li className="nav-item">
                <NavLink className="nav-link" to="" end>
                  Paste content
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="upload">
                  Upload file
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="remote">
                  Remote file
                </NavLink>
              </li>
            </ul>

            <Routes>
              <Route path="" element={<ExampleComponent />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/remote" element={<Remote />} />
            </Routes>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const Remote = () => {
  const toastCtx = useContext(ToastContext)
  const navigate = useNavigate()
  const configCtx = useContext(ConfigContext)
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value)
  }

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    setLoading(true)
    try {
      const response = await fetch(`${configCtx.api}/remote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      })
      if (response.ok) {
        const result = await response.text()
        navigate(`/deps/${result}`)
        return
      }
      toastCtx.show(`Cannot handle file.`)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="py-4">
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label htmlFor="url" className="form-label">
            Remote URL
          </label>
          <input
            value={url}
            onChange={onChange}
            type="text"
            className="form-control"
            id="url"
            placeholder="https://raw.githubusercontent.com/nestjs/nest/master/package-lock.json"
          />
        </div>
        <button
          onClick={onSubmit}
          type="submit"
          className="btn btn-primary"
          disabled={loading}
        >
          {loading ? (
            <React.Fragment>
              <Spinner />
              <span>Loading...</span>
            </React.Fragment>
          ) : (
            'Submit'
          )}
        </button>
      </form>
    </div>
  )
}

export default HomeComponent

const ExampleComponent: React.FunctionComponent = () => {
  const configCtx = useContext(ConfigContext)
  const toastCtx = useContext(ToastContext)
  const [loading, setLoading] = useState(false)
  const element = useRef<HTMLDivElement>(null)
  const editor = useRef(null)
  const navigate = useNavigate()

  const [fileType, setFileType] = useState('package-lock.json')

  useEffect(() => {
    if (element.current && !editor.current) {
      /* eslint-disable */
      // @ts-ignore
      window.require.config({
        paths: {
          vs: 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.30.1/min/vs',
        },
      })
      // @ts-ignore
      window.require(['vs/editor/editor.main'], function () {
        // @ts-ignore
        const foo = monaco.editor.create(element.current, {
          value: JSON.stringify(packageLockJson, undefined, 2),
          language: 'json',
          automaticLayout: true,
          minimap: {
            enabled: false,
          },
          scrollbar: {
            // Subtle shadows to the left & top. Defaults to true.
            useShadows: false,
          },
          lineNumbers: 'off',
        })
        editor.current = foo
      })
      /* eslint-enable */
    }
  }, [])

  const submit = async (event: MouseEvent<HTMLButtonElement>) => {
    setLoading(true)
    event.preventDefault()
    if (editor.current) {
      /* eslint-disable */
      // @ts-ignore
      const content = editor.current.getValue()
      /* eslint-enable */
      const data = new FormData()
      const blob = new Blob([content], { type: 'application/json' })
      data.append('file', new File([blob], fileType))

      // upload file
      try {
        const response = await fetch(`${configCtx.api}/upload`, {
          method: 'POST',
          body: data,
        })
        // try/catch only works for network errors
        // for server error we have to check the response
        if (response.ok) {
          const result = await response.text()
          navigate(`/deps/${result}`)
          return
        }
        toastCtx.show(`Cannot parse ${fileType} file.`)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onFileTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFileType(event.target.value)
  }

  return (
    <div className="py-4">
      <div
        className="border mb-4"
        style={{
          height: '600px',
        }}
        ref={element}
      ></div>

      <div className="mb-3">
        <label htmlFor="type" className="form-label">
          Select file type
        </label>
        <select
          className="form-select"
          id="type"
          value={fileType}
          onChange={onFileTypeChange}
        >
          <option value="package-lock.json">package-lock.json</option>
          <option value="yarn.lock">yarn.lock</option>
          <option value="package.json" disabled>
            package.json
          </option>
          <option value="build.gradle" disabled>
            build.gradle
          </option>
          <option value="pom.xml" disabled>
            pom.xml
          </option>
          <option value="Dockerfile" disabled>
            Dockerfile
          </option>
        </select>
        <small className="text-muted">More file types are coming soon.</small>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <button
          type="submit"
          className="btn btn-primary px-5"
          onClick={submit}
          disabled={loading}
        >
          {loading ? <Spinner /> : null}
          {loading ? 'Loading...' : 'Submit'}
        </button>
      </div>
    </div>
  )
}

const Spinner: React.FunctionComponent = () => {
  return (
    <div className="spinner-grow spinner-grow-sm me-2" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}

const Upload = () => {
  const configCtx = useContext(ConfigContext)
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)

  const [file, setFile] = useState<File>()
  const [active, setActive] = useState(false)
  const [uploading, setUploading] = useState(false)

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      setFile(event.target.files[0])
    }
  }

  const submit = async (event: MouseEvent<HTMLButtonElement>) => {
    setUploading(true)
    event.preventDefault()
    if (file) {
      const data = new FormData()
      data.append('file', file)
      const response = await fetch(`${configCtx.api}/upload`, {
        method: 'POST',
        body: data,
      })
      const result = await response.text()
      navigate(`/deps/${result}`)
    }
  }

  const choose = () => {
    inputRef.current?.click()
  }

  const dragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.dataTransfer?.items && event.dataTransfer.items.length) {
      setActive(true)
    }
  }

  const dragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setActive(false)
  }

  const dragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const drop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0]
      setActive(false)
      setFile(file)
    }
  }

  const cancel = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setFile(undefined)
    // also reset the input field to make sure the same file
    // selected again will trigger a change event
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <form className="py-4">
      <div className="form-group">
        <input
          id="file"
          type="file"
          name="file"
          className="d-none"
          onChange={onChange}
          ref={inputRef}
        />
        <div
          className={classnames('dropzone', {
            active,
            file: file !== undefined,
          })}
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          onDragEnter={dragEnter}
          onDrop={drop}
          role="button"
          onClick={choose}
        >
          <strong
            style={{
              pointerEvents: 'none',
            }}
          >
            {file === undefined ? (
              <Fragment>
                <div className="mb-4 d-flex justify-content-center">
                  <CloudUpload width="40px" height="40px" />
                </div>
                <span>Drop file here or click to choose.</span>
              </Fragment>
            ) : (
              <Fragment>
                <div className="mb-4 d-flex justify-content-center">
                  <CheckCircle width="40px" height="40px" />
                </div>
                <span>
                  {file.name}: {file.size} bytes
                </span>
              </Fragment>
            )}
          </strong>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <button
          type="button"
          className="btn btn-light me-4 px-5"
          disabled={file === undefined}
          onClick={cancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary px-5"
          disabled={file === undefined}
          onClick={submit}
        >
          {uploading ? (
            <Fragment>
              <Spinner />
              <span>Uploading ...</span>
            </Fragment>
          ) : (
            'Upload'
          )}
        </button>
      </div>
    </form>
  )
}
