import classnames from 'classnames'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Check } from './Icons'

interface Props {
  items: string[]
  active: string
  onClick: (licenseId: string) => void
}

const DropdownFilter: React.FunctionComponent<Props> = (props: Props) => {
  const input = useRef<HTMLInputElement>(null)
  const dropdown = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState('')

  const focus = () => {
    input.current?.focus()
  }

  useEffect(() => {
    const d = dropdown.current
    d?.addEventListener('shown.bs.dropdown', focus)

    return () => {
      d?.removeEventListener('shown.bs.dropdown', focus)
    }
  }, [])

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.currentTarget.value)
  }

  const filter = (license: string): boolean => {
    const lowerSearch = search.toLocaleLowerCase()
    const lowerLicense = license.toLocaleLowerCase()
    return lowerLicense.includes(lowerSearch)
  }

  return (
    <div className="dropdown" ref={dropdown}>
      <button
        className="btn btn-light dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Licenses
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <h6 className="dropdown-header">Filter by licenses</h6>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <form className="px-3 py-1" autoComplete="off">
          <div>
            <input
              type="search"
              className="form-control"
              placeholder="Filter licenses"
              ref={input}
              onChange={onChange}
              value={search}
              autoCapitalize="off"
            />
          </div>
        </form>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <div
          style={{
            overflow: 'auto',
            maxHeight: 320,
          }}
        >
          {props.items.filter(filter).map((license) => (
            <li key={license}>
              <a
                className={classnames(
                  'dropdown-item d-flex align-items-center',
                  {
                    active: license === props.active,
                  }
                )}
                href="/"
                onClick={(event) => {
                  event.preventDefault()
                  props.onClick(license)
                }}
              >
                {license === props.active ? (
                  <Check width="20px" height="20px" />
                ) : (
                  <div style={{ width: 20, height: 20 }}></div>
                )}
                <span className="ms-1">{license}</span>
              </a>
            </li>
          ))}
        </div>
      </ul>
    </div>
  )
}

export default DropdownFilter
