import {
  useEffect,
  useState,
  Fragment,
  ChangeEvent,
  MouseEvent,
  useContext,
} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Box } from '../Icons'
import Breadcrumb from '../Breadcrumb'
import { LicenseCountProjectionShort, SortDirection } from '../types'
import SortComponent from '../SortComponent'
import useTooltip from '../useTooltip'
import ListGroupPlaceholder from '../Package/ListGroupPlaceholder'
import { ConfigContext } from '../Config'

interface Sorting {
  count: SortDirection
  licenseId: SortDirection
  name: string
  [key: string]: string
}

const formatter = new Intl.NumberFormat()

const LicenseListComponent = (): JSX.Element => {
  const configCtx = useContext(ConfigContext)

  const location = useLocation()
  const navigate = useNavigate()

  const [licenses, setLicenses] = useState<LicenseCountProjectionShort[]>([])
  const [loading, setLoading] = useState(true)

  const [sorting, setSorting] = useState<Sorting>({
    count: SortDirection.unsorted,
    licenseId: SortDirection.unsorted,
    name: SortDirection.unsorted,
  })

  const retrieve = async (api: string) => {
    const response = await fetch(`${api}/api/licenses`)
    const data = await response.json()
    setLicenses(data)
    setLoading(false)
  }

  useTooltip()

  useEffect(() => {
    retrieve(configCtx.cache)
  }, [configCtx.cache])

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    const params = new URLSearchParams(location.search)

    if (value === '') {
      params.delete('search')
    } else {
      params.set('search', value)
    }
    navigate({
      search: params.toString(),
    })
  }

  const params = new URLSearchParams(location.search)
  const search = params.get('search') ?? ''

  const filter = (license: LicenseCountProjectionShort): boolean => {
    const lower = search.toLocaleLowerCase()
    const name = license.name.toLowerCase()
    const licenseId = license.licenseId.toLowerCase()
    return name.includes(lower) || licenseId.includes(lower)
  }

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget
    const current = sorting[name]
    let next = SortDirection.desc
    if (current === SortDirection.desc) {
      next = SortDirection.asc
    }
    setSorting({
      count: SortDirection.unsorted,
      licenseId: SortDirection.unsorted,
      name: SortDirection.unsorted,
      [name]: next,
    })
  }

  const active = Object.entries(sorting).filter(
    ([_, value]) => value !== SortDirection.unsorted
  )

  const sort = (
    a: LicenseCountProjectionShort,
    b: LicenseCountProjectionShort
  ): number => {
    if (active.length && active[0] !== undefined) {
      const [key, value] = active[0]
      const left = a[key as keyof LicenseCountProjectionShort]
      const right = b[key as keyof LicenseCountProjectionShort]
      if (typeof left === 'number' && typeof right === 'number') {
        return value === 'asc' ? left - right : right - left
      } else if (typeof left === 'string' && typeof right === 'string') {
        return value === 'asc'
          ? right.localeCompare(left)
          : left.localeCompare(right)
      }
    }
    return 0
  }

  return (
    <Fragment>
      <Breadcrumb
        className="sbomx-border"
        links={[
          { href: '/', text: 'Home' },
          { href: null, text: 'Licenses' },
        ]}
      />

      <div className="px-2 px-md-4 py-4">
        <input
          placeholder="Search for license"
          onChange={onChange}
          autoComplete="off"
          value={search}
          type="search"
          className="form-control"
          id="search"
          autoCapitalize="off"
        />

        <div className="card mt-3">
          <div className="card-header">
            Licenses
            <span className="badge bg-secondary ms-2">
              {formatter.format(licenses.length)}
            </span>
          </div>
          <ul className="list-group list-group-flush placeholder-glow">
            <li
              className="list-group-item d-flex align-items-center"
              style={{
                borderBottom: '2px solid #dee2e6',
              }}
            >
              <strong className="d-none d-lg-inline text-truncate licenses-name">
                License name
              </strong>
              <strong>License Id</strong>
              <button
                className="btn btn-sm btn-link ms-1"
                onClick={onClick}
                name="licenseId"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Sort by license id"
              >
                <div className="pe-none">
                  <SortComponent sort={sorting.licenseId} />
                </div>
              </button>

              <strong className="ms-auto text-truncate">Packages</strong>
              <button
                className="btn btn-sm btn-link ms-1"
                onClick={onClick}
                name="count"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Sort by number of packages"
              >
                <div className="pe-none">
                  <SortComponent sort={sorting.count} />
                </div>
              </button>
            </li>

            {loading ? (
              <ListGroupPlaceholder
                cols={[
                  4, 3, 4, 3, 2, 2, 4, 2, 1, 3, 4, 3, 4, 3, 2, 2, 4, 2, 1, 3,
                ]}
                includeUl={false}
              />
            ) : (
              <ListComponent licenses={licenses.filter(filter).sort(sort)} />
            )}
          </ul>
        </div>
      </div>
    </Fragment>
  )
}

export default LicenseListComponent

interface ListComponentProps {
  licenses: LicenseCountProjectionShort[]
}

const ListComponent = (props: ListComponentProps): JSX.Element => {
  const formatter = new Intl.NumberFormat()

  if (props.licenses.length === 0) {
    return (
      <div
        className="p-5 d-flex justify-content-center"
        style={{
          backgroundColor: '#f8f9fa',
          color: '#adb5bd',
          fontSize: '1.25rem',
        }}
      >
        Nothing found, try searching again.
      </div>
    )
  }

  return (
    <Fragment>
      {props.licenses.map((license) => {
        return (
          <li
            key={license.licenseId}
            className="list-group-item d-flex align-items-center"
          >
            <Link
              to={`/licenses/${license.licenseId}`}
              className="d-none d-lg-inline text-truncate licenses-name text-decoration-none"
            >
              {license.name}
            </Link>
            <Link
              to={`/licenses/${license.licenseId}`}
              className="d-lg-none text-truncate licenses-name text-decoration-none"
            >
              {license.licenseId}
            </Link>
            <span className="d-none d-lg-inline">{license.licenseId}</span>
            <span className="ms-auto me-2">
              {formatter.format(license.count) ?? 0}
            </span>
            <Box />
          </li>
        )
      })}
    </Fragment>
  )
}
