interface LinksProps {
  name: string | null
  bugs: string | undefined
  homepage: string | undefined
  repositoryUrl: string | undefined
}

const Links: React.FunctionComponent<LinksProps> = (props: LinksProps) => {
  return (
    <div className="card mb-4">
      <div className="card-header">Links</div>
      <div className="card-body">
        <dl>
          <dt>Origin</dt>
          <dd>
            <a
              className="text-decoration-none"
              target="_blank"
              rel="noreferrer"
              href={`https://www.npmjs.com/package/${props.name}`}
            >
              npmjs.com/package/{props.name}
            </a>
          </dd>
          <dt>Bugs</dt>
          <dd>
            <a
              className="text-decoration-none"
              target="_blank"
              rel="noreferrer"
              href={props.bugs}
            >
              {props.bugs?.replace(/https?:\/\//gi, '')}
            </a>
          </dd>

          <dt>Homepage</dt>
          <dd>
            <a
              className="text-decoration-none"
              target="_blank"
              rel="noreferrer"
              href={props.homepage}
            >
              {props.homepage?.replace(/https?:\/\//gi, '')}
            </a>
          </dd>

          <dt>Repository</dt>
          <dd>
            <a
              className="text-decoration-none"
              target="_blank"
              rel="noreferrer"
              href={props.repositoryUrl
                ?.replace('git+', '')
                .replace('.git', '')}
            >
              {props.repositoryUrl
                ?.replace(/git\+https?:\/\//gi, '')
                .replace('.git', '')}
            </a>
          </dd>
        </dl>
      </div>
    </div>
  )
}

export default Links
