import { ascending, max, min, quantileSorted } from 'd3-array'
import { Axis, axisBottom, axisLeft } from 'd3-axis'
import {
  NumberValue,
  ScaleBand,
  scaleBand,
  ScaleLinear,
  scaleLinear,
} from 'd3-scale'
import { select, Selection } from 'd3-selection'
import { Box } from './BoxPlotComponent'
import { calc, calcHeight } from './utils'

interface Margin {
  left: number
  right: number
  top: number
  bottom: number
}

interface Properties {
  margin: Margin
  target: SVGSVGElement
  color: string
}

interface Parameters {
  closedIssuesCount: number[]
  commentFrequency: number[]
  commitFrequency: number[]
  contributorCount: number[]
  createdSince: number[]
  criticalityScore: number[]
  dependentsCount: number[]
  orgCount: number[]
  recentReleasesCount: number[]
  updatedIssuesCount: number[]
  updatedSince: number[]
  watchersCount: number[]
  [key: string]: number[]
}

const labelMap = {
  closedIssuesCount: 'Closed Issues Count',
  commentFrequency: 'Comment Frequency',
  commitFrequency: 'Commit Frequency',
  contributorCount: 'Contributor Count',
  createdSince: 'Created Since',
  criticalityScore: 'Criticality Score',
  dependentsCount: 'Dependents Count',
  orgCount: 'Org Count',
  recentReleasesCount: 'Recent Releases Count',
  updatedIssuesCount: 'Updated Issues Count',
  updatedSince: 'Updated Since',
  watchersCount: 'Watchers Count',
}

const reducer = (previous: Parameters, current: Box) => {
  previous.closedIssuesCount.push(current.closedIssuesCount)
  previous.commentFrequency.push(current.commentFrequency)
  previous.commitFrequency.push(current.commitFrequency)
  previous.contributorCount.push(current.contributorCount)
  previous.createdSince.push(current.createdSince)
  previous.criticalityScore.push(current.criticalityScore)
  previous.dependentsCount.push(current.dependentsCount)
  previous.orgCount.push(current.orgCount)
  previous.recentReleasesCount.push(current.recentReleasesCount)
  previous.updatedIssuesCount.push(current.updatedIssuesCount)
  previous.updatedSince.push(current.updatedSince)
  previous.watchersCount.push(current.watchersCount)
  return previous
}

const initial: Parameters = {
  closedIssuesCount: [],
  commentFrequency: [],
  commitFrequency: [],
  contributorCount: [],
  createdSince: [],
  criticalityScore: [],
  dependentsCount: [],
  orgCount: [],
  recentReleasesCount: [],
  updatedIssuesCount: [],
  updatedSince: [],
  watchersCount: [],
}

// create a deep clone of initial
// it is important to create a new array as value and not to use the reference
// otherwise pushed items in the array will never leave
const deep = (inital: Parameters): Parameters => {
  const a = Object.entries(inital).map(([key, values]) => [key, [...values]])
  return Object.fromEntries(a)
}

// todo: use max values from criticality score

interface Stats {
  key: string
  q1: number
  q2: number
  q3: number
  r0: number
  r1: number
}

const mapper = ([key, values]: [string, number[]]): Stats => {
  // normalize all values to [0,1]
  const normalizer = scaleLinear().domain([0, max(values) as number])

  // sort all values to make quantile calculation faster
  const sorted = values.map(normalizer).sort(ascending)

  const q1 = quantileSorted(sorted, 0.25) as number
  const q2 = quantileSorted(sorted, 0.5) as number
  const q3 = quantileSorted(sorted, 0.75) as number
  const iqr = q3 - q1

  // ensure lower whisker is not < 0
  const r0 = Math.max(min(sorted) as number, q1 - iqr * 1.5)

  // ensure upper whisker is not > 1
  const r1 = Math.min(max(sorted) as number, q3 + iqr * 1.5)

  return { key, q1, q2, q3, r0, r1 }
}

// const skeleton: Box[] = [
//   {
//     closedIssuesCount: Math.random(),
//     commentFrequency: Math.random(),
//     commitFrequency: Math.random(),
//     contributorCount: Math.random(),
//     createdSince: Math.random(),
//     criticalityScore: Math.random(),
//     dependentsCount: Math.random(),
//     orgCount: Math.random(),
//     recentReleasesCount: Math.random(),
//     updatedIssuesCount: Math.random(),
//     updatedSince: Math.random(),
//     watchersCount: Math.random(),
//   },
// ]

// const skeleton: Box[] = [
//   {
//     closedIssuesCount: 0,
//     commentFrequency: 0,
//     commitFrequency: 0,
//     contributorCount: 0,
//     createdSince: 0,
//     criticalityScore: 0,
//     dependentsCount: 0,
//     orgCount: 0,
//     recentReleasesCount: 0,
//     updatedIssuesCount: 0,
//     updatedSince: 0,
//     watchersCount: 0,
//   },
// ]

const skeleton: Box[] = [
  {
    closedIssuesCount: 1,
    commentFrequency: 1,
    commitFrequency: 1,
    contributorCount: 1,
    createdSince: 1,
    criticalityScore: 1,
    dependentsCount: 1,
    orgCount: 1,
    recentReleasesCount: 1,
    updatedIssuesCount: 1,
    updatedSince: 1,
    watchersCount: 1,
  },
]

// https://www.storytellingwithdata.com/blog/what-is-a-boxplot
// https://observablehq.com/@d3/box-plot
export default class BoxPlot {
  private config: Properties
  private w: number
  private h: number
  private chart: Selection<SVGGElement, unknown, null, undefined>
  private xScale: ScaleBand<string>
  private yScale: ScaleLinear<number, number, never>
  private xAxis: Axis<string>
  private yAxis: Axis<NumberValue>
  private xAxisGroup: Selection<SVGGElement, unknown, null, undefined>
  private yAxisGroup: Selection<SVGGElement, unknown, null, undefined>
  private boxGroup: Selection<SVGGElement, Stats, SVGGElement, unknown>

  public constructor(config: Properties) {
    this.config = config

    const width = calc(config.target.parentNode as HTMLElement)
    const height = calcHeight(this.config.target.parentNode as HTMLElement)

    this.w = width - config.margin.left - config.margin.right
    this.h = height - config.margin.top - config.margin.bottom

    this.chart = select(config.target)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr(
        'transform',
        `translate(${config.margin.left}, ${config.margin.top})`
      )

    const grouped = skeleton.reduce(reducer, deep(initial))
    const data = Object.entries(grouped).map(mapper)

    this.xScale = scaleBand()
      .domain(data.map((d) => d.key))
      .range([0, this.w])
      .padding(0.25)

    this.yScale = scaleLinear().domain([0, 1]).range([this.h, 0])

    this.xAxis = axisBottom(this.xScale).tickFormat(
      (d) => labelMap[d as keyof typeof labelMap]
    )

    this.yAxis = axisLeft(this.yScale).ticks(5)

    // add x axis at the bottom
    this.xAxisGroup = this.chart
      .append('g')
      .attr('transform', `translate(0, ${this.h})`)
      .call(this.xAxis)

    // rotate text
    this.xAxisGroup
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('transform', 'rotate(-45)')

    // add y axis at the left
    this.yAxisGroup = this.chart.append('g').call(this.yAxis)

    // store in group!!
    this.boxGroup = this.chart
      .selectAll<SVGGElement, Stats>('g.box')
      .data(data, (d) => d.key)
      .join('g')
      .attr('class', 'box')

    // long horizontal line
    this.boxGroup
      .append('line')
      .attr(
        'x1',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth() / 2
      )
      .attr(
        'x2',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth() / 2
      )
      .attr('y1', (d) => this.yScale(d.r0))
      .attr('y2', (d) => this.yScale(d.r1))
      .attr('stroke', 'currentColor')
      .attr('class', 'horizontal')

    // box
    this.boxGroup
      .append('rect')
      .attr('x', (d) => this.xScale(d.key) as number)
      .attr('y', (d) => this.yScale(d.q3))
      .attr('height', (d) => this.yScale(d.q1) - this.yScale(d.q3))
      .attr('width', () => this.xScale.bandwidth())
      .attr('stroke', 'currentColor')
      .style('fill', this.config.color)
      .attr('class', 'rect')

    // bottom whisker
    this.boxGroup
      .append('line')
      .attr('x1', (d) => this.xScale(d.key) as number)
      .attr(
        'x2',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth()
      )
      .attr('y1', (d) => this.yScale(d.r0))
      .attr('y2', (d) => this.yScale(d.r0))
      .attr('stroke', 'currentColor')
      .attr('class', 'bottom')

    // middle whisker
    this.boxGroup
      .append('line')
      .attr('x1', (d) => this.xScale(d.key) as number)
      .attr(
        'x2',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth()
      )
      .attr('y1', (d) => this.yScale(d.q2))
      .attr('y2', (d) => this.yScale(d.q2))
      .attr('stroke', 'currentColor')
      .attr('class', 'middle')

    // top whisker
    this.boxGroup
      .append('line')
      .attr('x1', (d) => this.xScale(d.key) as number)
      .attr(
        'x2',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth()
      )
      .attr('y1', (d) => this.yScale(d.r1))
      .attr('y2', (d) => this.yScale(d.r1))
      .attr('stroke', 'currentColor')
      .attr('class', 'top')
  }

  // update the chart with real data
  public update(boxes: Box[]): void {
    // convert data
    const grouped = boxes.reduce(reducer, deep(initial))
    const data = Object.entries(grouped).map(mapper)

    // x scale
    this.xScale.domain(data.map((d) => d.key))

    // x axis
    this.xAxis = axisBottom(this.xScale).tickFormat(
      (d) => labelMap[d as keyof typeof labelMap]
    )

    this.boxGroup.data(data, (d) => d.key)

    // long horizontal line
    this.boxGroup
      .select('line.horizontal')
      .attr('y1', (d) => this.yScale(d.r0))
      .attr('y2', (d) => this.yScale(d.r1))

    // box
    this.boxGroup
      .select('rect.rect')
      .attr('y', (d) => this.yScale(d.q3))
      .attr('height', (d) => this.yScale(d.q1) - this.yScale(d.q3))

    // bottom whisker
    this.boxGroup
      .select('line.bottom')
      .attr('y1', (d) => this.yScale(d.r0))
      .attr('y2', (d) => this.yScale(d.r0))

    // middle whisker
    this.boxGroup
      .select('line.middle')
      .attr('y1', (d) => this.yScale(d.q2))
      .attr('y2', (d) => this.yScale(d.q2))

    // top whisker
    this.boxGroup
      .select('line.top')
      .attr('y1', (d) => this.yScale(d.r1))
      .attr('y2', (d) => this.yScale(d.r1))
  }

  public resize(): void {
    // calculate new svg width
    const width = calc(this.config.target.parentNode as HTMLElement)
    const height = calcHeight(this.config.target.parentNode as HTMLElement)

    // calculate new plot size
    this.w = width - this.config.margin.left - this.config.margin.right
    this.h = height - this.config.margin.top - this.config.margin.bottom

    // svg element
    select(this.config.target).attr('width', width).attr('height', height)

    // x scale
    this.xScale.range([0, this.w])

    // y scale
    this.yScale.range([this.h, 0])

    // x axis
    this.xAxisGroup
      .attr('transform', `translate(0, ${this.h})`)
      .call(this.xAxis)

    // y axis
    this.yAxisGroup.call(this.yAxis)

    // long horizontal line
    this.boxGroup
      .select('line.horizontal')
      .attr(
        'x1',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth() / 2
      )
      .attr(
        'x2',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth() / 2
      )
      .attr('y1', (d) => this.yScale(d.r0))
      .attr('y2', (d) => this.yScale(d.r1))

    // box
    this.boxGroup
      .select('rect.rect')
      .attr('x', (d) => this.xScale(d.key) as number)
      .attr('y', (d) => this.yScale(d.q3))
      .attr('height', (d) => this.yScale(d.q1) - this.yScale(d.q3))
      .attr('width', () => this.xScale.bandwidth())

    // bottom whisker
    this.boxGroup
      .select('line.bottom')
      .attr('x1', (d) => this.xScale(d.key) as number)
      .attr(
        'x2',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth()
      )
      .attr('y1', (d) => this.yScale(d.r0))
      .attr('y2', (d) => this.yScale(d.r0))

    // middle whisker
    this.boxGroup
      .select('line.middle')
      .attr('x1', (d) => this.xScale(d.key) as number)
      .attr(
        'x2',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth()
      )
      .attr('y1', (d) => this.yScale(d.q2))
      .attr('y2', (d) => this.yScale(d.q2))

    // top whisker
    this.boxGroup
      .select('line.top')
      .attr('x1', (d) => this.xScale(d.key) as number)
      .attr(
        'x2',
        (d) => (this.xScale(d.key) as number) + this.xScale.bandwidth()
      )
      .attr('y1', (d) => this.yScale(d.r1))
      .attr('y2', (d) => this.yScale(d.r1))
  }
}
