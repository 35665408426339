import React, { FunctionComponent } from 'react'

interface Props {
  includeUl: boolean
  cols: number[]
}

const ListGroupPlaceholder: FunctionComponent<Props> = (props: Props) => {
  if (props.includeUl) {
    return (
      <ul className="list-group list-group-flush placeholder-glow">
        <ListItems cols={props.cols} />
      </ul>
    )
  }
  return <ListItems cols={props.cols} />
}

interface ListItemsProps {
  cols: number[]
}

const ListItems: FunctionComponent<ListItemsProps> = (
  props: ListItemsProps
) => {
  return (
    <React.Fragment>
      {props.cols.map((c, index) => {
        return (
          <li key={index} className="list-group-item">
            <span className={`placeholder col-${c}`}></span>
          </li>
        )
      })}
    </React.Fragment>
  )
}

export default ListGroupPlaceholder
