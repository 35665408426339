import { Tooltip } from 'bootstrap'
import { useEffect } from 'react'

export default function useTooltip(): void {
  useEffect(() => {
    // make sure you use the `selector` feature to enable tooltip for dynamically added elements
    const tooltip = new Tooltip(document.body, {
      selector: '[data-bs-toggle="tooltip"]',
      trigger: 'hover',
    })

    return () => {
      tooltip.dispose()
    }
  }, [])
}
