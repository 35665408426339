import React, { useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { ConfigContext } from '../Config'
import Pagination from '../Pagination'
import { Page } from '../types'
import ListGroupPlaceholder from './ListGroupPlaceholder'
import { useMapLocationToApi } from './utils'

const Dependents: React.FunctionComponent = function Dependents() {
  const configCtx = useContext(ConfigContext)
  const [loading, setLoading] = useState(true)
  const [dependents, setDependents] = useState<Page<string>>()

  const [searchParams] = useSearchParams()
  const name = searchParams.get('name')
  const version = searchParams.get('version')

  const query = useMapLocationToApi()

  const retrieve = async (query: string, api: string) => {
    const response = await fetch(`${api}/api/versions/dependents?${query}`)
    const data = await response.json()
    setDependents(data)
    setLoading(false)
  }

  useEffect(() => {
    retrieve(query, configCtx.cache)
  }, [query, configCtx.cache])

  return (
    <React.Fragment>
      <div className="card mb-4 placeholder-glow">
        <div className="card-header">Dependents</div>

        {loading ? (
          <ListGroupPlaceholder cols={[2, 3, 2, 1, 2]} includeUl={true} />
        ) : dependents?.empty ? (
          <div className="card-body">
            No packages depend on{' '}
            <code>
              {name}@{version}
            </code>
            .
          </div>
        ) : (
          <ul className="list-group list-group-flush">
            {dependents?.content.map((d) => {
              return (
                <li key={d} className="list-group-item">
                  <Link
                    className="text-decoration-none"
                    to={`/packages?name=${d}`}
                  >
                    {d}
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <Pagination loading={loading} page={dependents} />
    </React.Fragment>
  )
}

export default Dependents
