export enum Direction {
  asc = 'asc',
  desc = 'desc',
}

const isDirection = (s: string): s is Direction => {
  return Object.values(Direction).includes(s as Direction)
}

class Order {
  direction: Direction
  property: string

  static from(param: string | null): Order | null {
    if (param === null) {
      return null
    }

    const parts = param.split(',')
    const property = parts[0]
    if (!property) {
      throw new Error('missing property')
    }
    const direction = parts[1]
    if (!direction) {
      throw new Error('missing direction')
    }
    if (isDirection(direction)) {
      return new Order(direction, property)
    }
    throw new Error('invalid direction')
  }

  // public static final Direction DEFAULT_DIRECTION = Direction.ASC;
  static by(property: string): Order {
    return new Order(Direction.asc, property)
  }

  constructor(direction: Direction, property: string) {
    this.direction = direction
    this.property = property
  }

  reverse() {
    this.direction =
      this.direction === Direction.asc ? Direction.desc : Direction.asc
  }

  toString(): string {
    return `${this.property},${this.direction}`
  }
}

export default Order
