import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ConfigContext } from '../Config'

const formatter = new Intl.NumberFormat()

interface Summary {
  name: string
  version: string
  dependents: number
  maintainers: number
  keywords: number
  dependencies: number
  versions: number
}

interface Props {
  name: string | null
  version: string | null
}

const Tabs: React.FunctionComponent<Props> = function Tabs(props: Props) {
  const configCtx = useContext(ConfigContext)
  const [summary, setSummary] = useState<Summary>()

  const paramsApi = new URLSearchParams()
  const paramsBrowser = new URLSearchParams()

  if (props.name) {
    paramsApi.set('name', props.name)
    paramsBrowser.set('name', props.name)
  }

  if (props.version) {
    paramsApi.set('version', props.version)
    paramsBrowser.set('version', props.version)
  } else {
    paramsApi.set('tag', 'latest')
  }

  const searchApi = paramsApi.toString()
  const searchBrowser = paramsBrowser.toString()

  const fetchVersion = async (searchApi: string, api: string) => {
    try {
      const response = await fetch(`${api}/api/versions/summary?${searchApi}`)
      const result = await response.json()
      setSummary(result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchVersion(searchApi, configCtx.cache)
  }, [searchApi, configCtx.cache])

  return (
    <ul
      className="nav nav-tabs border-0 flex-nowrap nav-tabs-scroll-snap px-2 px-md-4 sbomx-background sbomx-border"
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <li className="nav-item">
        <NavLink className="nav-link" to={`.?${searchBrowser}`} end>
          Overview
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link d-flex align-items-center"
          to={`versions?${searchBrowser}`}
        >
          Versions
          <span className="badge bg-light text-dark ms-2">
            {formatter.format(summary?.versions ?? 0)}
          </span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link d-flex align-items-center"
          to={`maintainers?${searchBrowser}`}
        >
          Maintainers
          <span className="badge bg-light text-dark ms-2">
            {formatter.format(summary?.maintainers ?? 0)}
          </span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link d-flex align-items-center"
          to={`keywords?${searchBrowser}`}
        >
          Keywords
          <span className="badge bg-light text-dark ms-2">
            {formatter.format(summary?.keywords ?? 0)}
          </span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link d-flex align-items-center"
          to={`dependencies?${searchBrowser}`}
        >
          Dependencies
          <span className="badge bg-light text-dark ms-2">
            {formatter.format(summary?.dependencies ?? 0)}
          </span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link d-flex align-items-center"
          to={`dependents?${searchBrowser}`}
        >
          Dependents
          <span className="badge bg-light text-dark ms-2">
            {formatter.format(summary?.dependents ?? 0)}
          </span>
        </NavLink>
      </li>
    </ul>
  )
}

export default Tabs
