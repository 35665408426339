import { FunctionComponent } from 'react'
import { useSearchParams } from 'react-router-dom'
import KeywordComponent from './KeywordComponent'
import KeywordListComponent from './KeywordListComponent'

const KeywordWrapper: FunctionComponent = () => {
  const [params] = useSearchParams()
  const value = params.get('value')

  if (value !== null) {
    return <KeywordComponent />
  }

  return <KeywordListComponent />
}

export default KeywordWrapper
