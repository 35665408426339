import { Code, CPU, Plug } from '../Icons'
import styles from './standards.module.scss'

const Standards: React.FunctionComponent = function Standards() {
  return (
    <div className="container px-4 py-5">
      <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div className="col">
          <div className="d-flex align-items-center mb-1">
            <div className={`${styles['icon']}`}>
              <CPU width="24px" height="24px" />
            </div>
            <h2 className="mb-0 ms-2">Open Standards</h2>
          </div>
          <div>
            <p className={`${styles['text']}`}>
              We adapt to your workflow. sbomx simply works with your existing
              code and processes. We injest information from various programming
              languages and frameworks.
            </p>
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center mb-1">
            <div className={`${styles['icon']}`}>
              <Code width="24px" height="24px" />
            </div>
            <h2 className="mb-0 ms-2">Well-Known Formats</h2>
          </div>
          <div>
            <p className={`${styles['text']}`}>
              We support the industry standard file formats like Excel, CSV,
              PDF, SPDX, CycloneDX, and SWID. All the information is ready for
              further post-processing.
            </p>
          </div>
        </div>
        <div className={`col`}>
          <div className="d-flex align-items-center mb-1">
            <div className={`${styles['icon']}`}>
              <Plug width="24px" height="24px" />
            </div>
            <h2 className="mb-0 ms-2">Seamless Integration</h2>
          </div>
          <p className={`${styles['text']}`}>
            sbomx seamlessly integrates with existing tools and solutions.
            Download your information in one of the well-known formats or
            directly use our API.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Standards
