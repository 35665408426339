import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

const Footer: FunctionComponent = () => {
  return (
    <footer className="sbomx-footer bg-dark py-5 px-4">
      <div className="row">
        <div className="col-md">
          <div className="mb-2">
            <p className="mb-0">sbomx</p>
            <p className="mb-0">Software Bill of Materials X</p>
            <p>
              <a
                className="link-secondary text-decoration-none"
                href="https://twitter.com/sbom_x"
                target="_blank"
                rel="noreferrer"
              >
                @sbom_x
              </a>
            </p>
          </div>
          <small className="d-block">
            <p className="mb-0">© 2020-2022</p>
            <p>Mirco Zeiss</p>
          </small>
        </div>
        <div className="col-md">
          <h5>Features</h5>
          <ul className="list-unstyled">
            <li className="mb-1">
              <Link
                className="link-secondary text-decoration-none"
                to="/criticality-score"
              >
                Criticality Score
              </Link>
            </li>
            <li className="mb-1">
              <Link
                className="link-secondary text-decoration-none"
                to="/security-scorecards"
              >
                Security Scorecards
              </Link>
            </li>
            <li className="mb-1">
              <Link
                className="link-secondary text-decoration-none"
                to="/cvss-calculator"
              >
                CVSS Calculator
              </Link>
            </li>
            <li className="mb-1">
              <Link
                className="link-secondary text-decoration-none"
                to="/roadmap"
              >
                Roadmap
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md">
          <h5>About</h5>
          <ul className="list-unstyled text-small">
            <li className="mb-1">
              <Link
                className="link-secondary text-decoration-none"
                to="/privacy"
              >
                Privacy
              </Link>
            </li>
            <li className="mb-1">
              <Link className="link-secondary text-decoration-none" to="/terms">
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
